var data = {
  "permission_by_superuser": "no",
  "record_count": "many_times",
  "param_group": {
    "param_order": [
      "general",
      "settings"
    ],
    "parameters": {
      "general": {
        "wdm16_id": "1118",
        "translation": {
          "en": "General",
          "tr": "Genel"
        },
        "value": "general",
        "label": "Genel"
      },
      "settings": {
        "translation": {
          "en": "Ayarlar",
          "tr": "Ayarlar"
        },
        "label": "Ayarlar",
        "value": "settings",
        "show": true
      }
    }
  },
  "favorite_show_mode": "yes",
  "module": [],
  "general": {
    "param_order": [
      "model_name",
      "model_info",
      "status",
      "wdm3_name",
      "wdm3_key",
      "last_update_time_of_project_cases",
      "case_rows_count",
      "case_count_analyze_applied",
      "are_cases_filtered",
      "total_analyze_count",
      "total_analyze_operation_count",
      "analyze_type",
      "mean_type",
      "cross_column_type",
      "multi_dimension_analyze",
      "column_selection_type",
      "analyze_column_list",
      "selected_columns",
      "row_parameter_list",
      "selected_row_parameters",
      "column_parameter_list",
      "selected_column_parameters",
      "descriptive_stats_list",
      "descriptive_stats",
      "usage_of_comparison_tests",
      "categorical_to_nominal",
      "selected_categorical_to_nominal",
      "categorical_to_categorical",
      "selected_categorical_to_categorical",
      "nominal_to_nominal",
      "selected_nominal_to_nominal",
      "auto_combination_cross_analysis",
      "sample_count_control",
      "min_sample_count",
      "group_count_control",
      "min_group_count",
      "one_or_multi_independent_one_dependent_categorical",
      "one_or_multi_independent_one_dependent_categorical_selected",
      "one_or_multi_independent_one_dependent_numeric",
      "one_or_multi_independent_one_dependent_numeric_selected",
      "before_after"
    ],
    "parameters": {
      "column_selection_type": {
        "name": {
          "wdm16_id": "3453",
          "translation": {
            "en": "Kolon Seçim Şekli",
            "tr": "Kolon Seçim Şekli"
          },
          "value": "column_selection_type",
          "label": "Kolon Seçim Tipi"
        },
        "show": "yes",
        "required": "yes",
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [
            "=",
            "<>"
          ],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "type": "select",
        "options": [
          {
            "wdm16_id": "3454",
            "translation": {
              "en": "manuel kolon seçimi",
              "tr": "manuel kolon seçimi"
            },
            "value": "manuel_column_selection",
            "label": "manuel kolon seçimi"
          },
          {
            "wdm16_id": "3455",
            "translation": {
              "en": "tüm kolonlar",
              "tr": "tüm kolonlar"
            },
            "value": "all_columns",
            "label": "tüm kolonlar"
          },
          {
            "wdm16_id": "3456",
            "translation": {
              "en": "tüm numeric kolonlar",
              "tr": "tüm numeric kolonlar"
            },
            "value": "all_numeric_columns",
            "label": "tüm sayısal kolonlar"
          },
          {
            "wdm16_id": "3457",
            "translation": {
              "en": "tüm kategorik kolonlar",
              "tr": "tüm kategorik kolonlar"
            },
            "value": "all_categorical_columns",
            "label": "tüm kategorik kolonlar"
          }
        ],
        "if": {
          "friend_type": "any_one_of_friend_match",
          "friend": {
            "cross_column_type": {
              "operation": {
                "or_options": [
                  "auto"
                ]
              },
              "parent_param": "yes",
              "name": {
                "wdm16_id": "18284",
                "translation": {
                  "en": "Karşılaştırma şekli",
                  "tr": "Karşılaştırma şekli"
                },
                "value": "cross_column_type",
                "label": "Karşılaştırma Tipi"
              },
              "index_list": [
                "general",
                "cross_column_type"
              ]
            },
            "analyze_type": {
              "operation": {
                "or_options": [
                  "frequency",
                  "mean",
                  "median",
                  "detailed_descriptive_stats",
                  "outliers_zindex",
                  "outliers_interquartile_range",
                  "max",
                  "min",
                  "standart_deviation",
                  "skewness",
                  "kolmogorov_smirnov",
                  "kurtosis",
                  "shapiro_wilk",
                  "cronbach_alpha"
                ]
              },
              "parent_param": "yes",
              "index_list": [
                "general",
                "analyze_type"
              ],
              "name": {
                "translation": {
                  "en": "Analiz Tipi",
                  "tr": "Analiz Tipi"
                },
                "value": "analyze_type",
                "label": "Analiz Tipi"
              }
            }
          }
        }
      },
      "usage_of_comparison_tests": {
        "required": "yes",
        "name": {
          "wdm16_id": "3538",
          "translation": {
            "en": "Karşılaştırmalı İstatistiksel Testler Kullanımı",
            "tr": "Karşılaştırmalı İstatistiksel Testler Kullanımı"
          },
          "value": "usage_of_comparison_tests",
          "label": "Karşılaştırmalı Test Kullanımı"
        },
        "show": "yes",
        "object_type": "object",
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [
            "=",
            "<>"
          ],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "type": "select",
        "options": [
          {
            "wdm16_id": "809",
            "translation": {
              "en": "evet",
              "tr": "evet"
            },
            "value": "yes",
            "label": "evet"
          },
          {
            "wdm16_id": "1327",
            "translation": {
              "en": "hayır",
              "tr": "hayır"
            },
            "value": "no",
            "label": "hayır"
          }
        ],
        "if": {
          "friend_type": "all_friend_match",
          "friend": {
            "analyze_type": {
              "operation": {
                "or_options": [
                  "cross_columns"
                ]
              },
              "parent_param": "yes",
              "index_list": [
                "general",
                "analyze_type"
              ],
              "name": {
                "translation": {
                  "en": "Analiz Tipi",
                  "tr": "Analiz Tipi"
                },
                "value": "analyze_type",
                "label": "Analiz Tipi"
              }
            }
          }
        }
      },
      "one_or_multi_independent_one_dependent_categorical": {
        "name": {
          "wdm16_id": "3556",
          "translation": {
            "en": "Tek ya da Çoklu Girdili (Bağımsız Değişken), Tek Hedef (Bağımlı Değişken) Kategorik",
            "tr": "Tek ya da Çoklu Girdili (Bağımsız Değişken), Tek Hedef (Bağımlı Değişken) Kategorik"
          },
          "value": "one_or_multi_independent_one_dependent_categorical",
          "label": "Bir ya da Çok Bağımsız (kategorik veya sayısal), Bir Bağımlı Değişken (Kategorik)"
        },
        "show": "yes",
        "object_type": "object",
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [
            "=",
            "<>"
          ],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "object_param": {
          "translation": {
            "en": "Tek ya da Çoklu Girdili (Bağımsız Değişken), Tek Hedef (Bağımlı Değişken) Kategorik Seçilen Testler",
            "tr": "Tek ya da Çoklu Girdili (Bağımsız Değişken), Tek Hedef (Bağımlı Değişken) Kategorik Seçilen Testler"
          },
          "value": "one_or_multi_independent_one_dependent_categorical_selected",
          "label": "Tek ya da Çoklu Girdili (Bağımsız Değişken), Tek Hedef (Bağımlı Değişken) Kategorik Seçilen Testler"
        },
        "op_if": "1",
        "type": "object_list",
        "options": [
          {
            "wdm16_id": "3557",
            "translation": {
              "en": "Lojistik Regresyon",
              "tr": "Lojistik Regresyon"
            },
            "label": "Lojistik Regresyon",
            "value": "logistic_regression",
            "if": {
              "friend": {
                "cross_column_type": {
                  "operation": {
                    "or_options": [
                      "categorical_categorical",
                      "numeric_categorical",
                      "categorical_and_or_numeric_multi_categorical"
                    ]
                  },
                  "parent_param": "yes",
                  "name": {
                    "wdm16_id": "18284",
                    "translation": {
                      "en": "Karşılaştırma şekli",
                      "tr": "Karşılaştırma şekli"
                    },
                    "value": "cross_column_type",
                    "label": "Karşılaştırma Tipi"
                  },
                  "index_list": [
                    "general",
                    "cross_column_type"
                  ]
                }
              },
              "friend_if_type": "any_one_of_friend_match"
            }
          }
        ],
        "if": {
          "friend_type": "all_friend_match",
          "friend": {
            "usage_of_comparison_tests": {
              "operation": {
                "or_options": [
                  "yes"
                ]
              },
              "parent_param": "yes",
              "name": {
                "wdm16_id": "3538",
                "translation": {
                  "en": "Karşılaştırmalı İstatistiksel Testler Kullanımı",
                  "tr": "Karşılaştırmalı İstatistiksel Testler Kullanımı"
                },
                "value": "usage_of_comparison_tests",
                "label": "Karşılaştırmalı Test Kullanımı"
              },
              "index_list": [
                "general",
                "usage_of_comparison_tests"
              ]
            },
            "analyze_type": {
              "operation": {
                "or_options": [
                  "cross_columns"
                ]
              },
              "parent_param": "yes",
              "index_list": [
                "general",
                "analyze_type"
              ],
              "name": {
                "translation": {
                  "en": "Analiz Tipi",
                  "tr": "Analiz Tipi"
                },
                "value": "analyze_type",
                "label": "Analiz Tipi"
              }
            }
          }
        }
      },
      "sample_count_control": {
        "name": {
          "wdm16_id": "3552",
          "translation": {
            "en": "Örnek Sayı Kontrolü",
            "tr": "Örnek Sayı Kontrolü"
          },
          "value": "sample_count_control",
          "label": "Örnek Sayı Kontrolü"
        },
        "show": "yes",
        "required": "yes",
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [
            "=",
            "<>"
          ],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "type": "select",
        "options": [
          {
            "wdm16_id": "809",
            "translation": {
              "en": "evet",
              "tr": "evet"
            },
            "value": "yes",
            "label": "evet"
          },
          {
            "wdm16_id": "1327",
            "translation": {
              "en": "hayır",
              "tr": "hayır"
            },
            "value": "no",
            "label": "hayır"
          }
        ],
        "if": {
          "friend": {
            "auto_combination_cross_analysis": {
              "operation": {
                "or_options": [
                  "yes"
                ]
              },
              "parent_param": "yes",
              "index_list": [
                "general",
                "auto_combination_cross_analysis"
              ],
              "name": {
                "translation": {
                  "en": "Otomatik Karşılaştırmalı Kombinasyon Analizleri",
                  "tr": "Otomatik Karşılaştırmalı Kombinasyon Analizleri"
                },
                "value": "auto_combination_cross_analysis",
                "label": "Otomatik Karşılaştırmalı Kombinasyon Analizleri"
              }
            }
          }
        }
      },
      "analyze_column_list": {
        "name": {
          "wdm16_id": "3458",
          "translation": {
            "en": "Analiz Edilen Kolonlar",
            "tr": "Analiz Edilen Kolonlar"
          },
          "value": "analyze_column_list",
          "label": "Kolonlar (değişkenler)"
        },
        "show": "yes",
        "object_type": "object",
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [
            "=",
            "<>"
          ],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "object_param": {
          "translation": {
            "en": "Seçilen Kolonlar",
            "tr": "Seçilen Kolonlar"
          },
          "value": "selected_columns",
          "label": "Seçilen Kolonlar"
        },
        "selectbox_type": "listbox",
        "type": "object_list",
        "if": {
          "friend_type": "all_friend_match",
          "friend": {
            "column_selection_type": {
              "operation": {
                "or_options": [
                  "manuel_column_selection"
                ]
              },
              "parent_param": "yes",
              "index_list": [
                "general",
                "column_selection_type"
              ],
              "name": {
                "translation": {
                  "en": "Kolon Seçim Şekli",
                  "tr": "Kolon Seçim Şekli"
                },
                "value": "column_selection_type",
                "label": "Kolon Seçim Şekli"
              }
            }
          }
        }
      },
      "row_parameter_list": {
        "name": {
          "wdm16_id": "18293",
          "translation": {
            "en": "Satır Parametre Kolonları",
            "tr": "Satır Parametre Kolonları"
          },
          "value": "row_parameter_list",
          "label": "Bağımsız Değişkenler"
        },
        "show": "yes",
        "object_type": "object",
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [
            "=",
            "<>"
          ],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "object_param": {
          "translation": {
            "en": "Satır Parametre Kolonları",
            "tr": "Satır Parametre Kolonları"
          },
          "value": "selected_row_parameters",
          "label": "Satır Parametre Kolonları"
        },
        "selectbox_type": "listbox",
        "type": "object_list",
        "if": {
          "friend_type": "all_friend_match",
          "friend": {
            "cross_column_type": {
              "operation": {
                "or_options": [
                  "categorical_categorical",
                  "categorical_numeric",
                  "numeric_numeric",
                  "numeric_categorical",
                  "categorical_and_or_numeric_multi_categorical",
                  "categorical_and_or_numeric_multi_numeric",
                  "categorical_and_categorical_or_numeric"
                ]
              },
              "parent_param": "yes",
              "name": {
                "wdm16_id": "18284",
                "translation": {
                  "en": "Karşılaştırma şekli",
                  "tr": "Karşılaştırma şekli"
                },
                "value": "cross_column_type",
                "label": "Karşılaştırma Tipi"
              },
              "index_list": [
                "general",
                "cross_column_type"
              ]
            }
          }
        }
      },
      "auto_combination_cross_analysis": {
        "info": "Kombinasyon üretildiğinde karşılaştırmalarda \"kategorik & numeric\" ve \"kategorik & kategorik\" kombinasyonları analiz edilmektedir. Karşılaştırmalı analizlerde seçilmiş olan testlerden herhangi birinin p değeri anlamlı tespit edilirse kayıt yapılmaktadır. Otomatik analizlerde milyonlarca kombinasyonlar üretilebildiğinden dolayı kayıt yapılmamaktadır. Belirlenen ikili grup kombinasyonlarını manuel olarak yaparak kayıt işlemini gerçekleştirebilirsiniz. ",
        "name": {
          "wdm16_id": "3551",
          "translation": {
            "en": "Otomatik Karşılaştırmalı Kombinasyon Analizleri",
            "tr": "Otomatik Karşılaştırmalı Kombinasyon Analizleri"
          },
          "value": "auto_combination_cross_analysis",
          "label": "Otomatik Kombinasyon Analizleri"
        },
        "show": "yes",
        "required": "yes",
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [
            "=",
            "<>"
          ],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "type": "select",
        "options": [
          {
            "wdm16_id": "809",
            "translation": {
              "en": "evet",
              "tr": "evet"
            },
            "value": "yes",
            "label": "evet"
          },
          {
            "wdm16_id": "1327",
            "translation": {
              "en": "hayır",
              "tr": "hayır"
            },
            "value": "no",
            "label": "hayır"
          }
        ],
        "if": {
          "friend": {
            "usage_of_comparison_tests": {
              "operation": {
                "or_options": [
                  "yes"
                ]
              },
              "parent_param": "yes",
              "index_list": [
                "general",
                "usage_of_comparison_tests"
              ],
              "name": {
                "translation": {
                  "en": "Karşılaştırmalı İstatistiksel Testler Kullanımı",
                  "tr": "Karşılaştırmalı İstatistiksel Testler Kullanımı"
                },
                "value": "usage_of_comparison_tests",
                "label": "Karşılaştırmalı İstatistiksel Testler Kullanımı"
              }
            },
            "analyze_type": {
              "operation": {
                "or_options": [
                  "cross_columns",
                  "detailed_descriptive_stats"
                ]
              },
              "parent_param": "yes",
              "index_list": [
                "general",
                "analyze_type"
              ],
              "name": {
                "translation": {
                  "en": "Analiz Tipi",
                  "tr": "Analiz Tipi"
                },
                "value": "analyze_type",
                "label": "Analiz Tipi"
              }
            }
          }
        }
      },
      "total_analyze_count": {
        "info": "İstatistiksel modele ait tüm analizlerden sadece kaydedilmeye uygun olanlara ait adedi bu parametrede analiz sonrasında görülmektedir.",
        "name": {
          "wdm16_id": "3435",
          "translation": {
            "en": "Toplam Analiz Adedi",
            "tr": "Toplam Analiz Adedi"
          },
          "value": "total_analyze_count",
          "label": "Kayıtlı Analiz Adedi"
        },
        "show": "no",
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "disabled": 1,
        "type": "plain_text"
      },
      "wdm3_key": {
        "info": "Bu istatistiksel modelin hangi Wanalyzer projesi ile ilişkili olarak yapıldığını göstermek için Wanalyzer projesinin id sini sistem otomatik olarak bu alana yazmaktadır.",
        "name": {
          "wdm16_id": "3430",
          "translation": {
            "en": "Proje KEY",
            "tr": "Proje KEY"
          },
          "value": "wdm3_key",
          "label": "Proje ID"
        },
        "show": "no",
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "disabled": 1,
        "type": "text"
      },
      "min_sample_count": {
        "name": {
          "wdm16_id": "3553",
          "translation": {
            "en": "Minimum Örnek Sayısı",
            "tr": "Minimum Örnek Sayısı"
          },
          "value": "min_sample_count",
          "label": "Minimum Örnek Sayısı"
        },
        "show": "yes",
        "required": "yes",
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [
            "=",
            "<>",
            ">",
            "<",
            ">=",
            "<="
          ],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "type": "integer",
        "if": {
          "friend": {
            "sample_count_control": {
              "operation": {
                "or_options": [
                  "yes"
                ]
              },
              "parent_param": "yes",
              "index_list": [
                "general",
                "sample_count_control"
              ],
              "name": {
                "translation": {
                  "en": "Örnek Sayı Kontrolü",
                  "tr": "Örnek Sayı Kontrolü"
                },
                "value": "sample_count_control",
                "label": "Örnek Sayı Kontrolü"
              }
            },
            "auto_combination_cross_analysis": {
              "operation": {
                "or_options": [
                  "yes"
                ]
              },
              "parent_param": "yes",
              "index_list": [
                "general",
                "auto_combination_cross_analysis"
              ],
              "name": {
                "translation": {
                  "en": "Otomatik Karşılaştırmalı Kombinasyon Analizleri",
                  "tr": "Otomatik Karşılaştırmalı Kombinasyon Analizleri"
                },
                "value": "auto_combination_cross_analysis",
                "label": "Otomatik Karşılaştırmalı Kombinasyon Analizleri"
              }
            }
          }
        }
      },
      "min_group_count": {
        "name": {
          "wdm16_id": "3555",
          "translation": {
            "en": "Minimum Grup Örnek Sayısı",
            "tr": "Minimum Grup Örnek Sayısı"
          },
          "value": "min_group_count",
          "label": "Minimum Grup Örnek Sayısı"
        },
        "show": "yes",
        "required": "yes",
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [
            "=",
            "<>",
            ">",
            "<",
            ">=",
            "<="
          ],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "type": "integer",
        "if": {
          "friend": {
            "group_count_control": {
              "operation": {
                "or_options": [
                  "yes"
                ]
              },
              "parent_param": "yes",
              "index_list": [
                "general",
                "group_count_control"
              ],
              "name": {
                "translation": {
                  "en": "Grup Örnek Sayısı Kontrolü",
                  "tr": "Grup Örnek Sayısı Kontrolü"
                },
                "value": "group_count_control",
                "label": "Grup Örnek Sayısı Kontrolü"
              }
            },
            "auto_combination_cross_analysis": {
              "operation": {
                "or_options": [
                  "yes"
                ]
              },
              "parent_param": "yes",
              "index_list": [
                "general",
                "auto_combination_cross_analysis"
              ],
              "name": {
                "translation": {
                  "en": "Otomatik Karşılaştırmalı Kombinasyon Analizleri",
                  "tr": "Otomatik Karşılaştırmalı Kombinasyon Analizleri"
                },
                "value": "auto_combination_cross_analysis",
                "label": "Otomatik Karşılaştırmalı Kombinasyon Analizleri"
              }
            }
          }
        }
      },
      "analyze_type": {
        "required": "yes",
        "name": {
          "wdm16_id": "3440",
          "translation": {
            "en": "Analiz Tipi",
            "tr": "Analiz Tipi"
          },
          "value": "analyze_type",
          "label": "Analiz Metodu"
        },
        "show": "yes",
        "object_type": "object",
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [
            "=",
            "<>"
          ],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "naming": "1",
        "type": "select",
        "options": [
          {
            "wdm16_id": "3524",
            "translation": {
              "en": "ortalama",
              "tr": "ortalama"
            },
            "value": "mean",
            "label": "ortalama"
          },
          {
            "wdm16_id": "4536",
            "translation": {
              "en": "sıklık",
              "tr": "sıklık"
            },
            "value": "frequency",
            "label": "sıklık"
          },
          {
            "wdm16_id": "3444",
            "translation": {
              "en": "karşılaştırma",
              "tr": "karşılaştırma"
            },
            "value": "cross_columns",
            "label": "karşılaştırma"
          },
          {
            "wdm16_id": "3443",
            "translation": {
              "en": "detaylı tanımlayıcı istatistikler",
              "tr": "detaylı tanımlayıcı istatistikler"
            },
            "value": "detailed_descriptive_stats",
            "label": "detaylı tanımlayıcı istatistikler"
          },
          {
            "wdm16_id": "3446",
            "translation": {
              "en": "medyan",
              "tr": "medyan"
            },
            "value": "median",
            "label": "medyan"
          },
          {
            "wdm16_id": "3516",
            "translation": {
              "en": "maksimum",
              "tr": "maksimum"
            },
            "value": "max",
            "label": "maksimum"
          },
          {
            "wdm16_id": "3515",
            "translation": {
              "en": "minimum",
              "tr": "minimum"
            },
            "value": "min",
            "label": "minimum"
          },
          {
            "wdm16_id": "3449",
            "translation": {
              "en": "standart sapma ",
              "tr": "standart sapma "
            },
            "value": "standart_deviation",
            "label": "standart sapma "
          },
          {
            "wdm16_id": "3452",
            "translation": {
              "en": "önce & sonra karşılaştırma",
              "tr": "önce & sonra karşılaştırma"
            },
            "value": "before_after_comparison",
            "label": "önce & sonra karşılaştırma"
          },
          {
            "wdm16_id": "3531",
            "translation": {
              "en": "veri dağılımı (eğim, skewness)",
              "tr": "veri dağılımı (eğim, skewness)"
            },
            "value": "skewness",
            "label": "skewness (veri dağılımı, çarpıklık)"
          },
          {
            "translation": {
              "en": "kurtosis (veri dağılımı, basıklık)",
              "tr": "kurtosis (veri dağılımı, basıklık)"
            },
            "value": "kurtosis",
            "label": "kurtosis (veri dağılımı, basıklık)"
          },
          {
            "wdm16_id": "3534",
            "translation": {
              "en": "aykırı değer saptama (outliers) z-index",
              "tr": "aykırı değer saptama (outliers) z-index"
            },
            "value": "outliers_zindex",
            "label": "z-index (aykırı değer)"
          },
          {
            "wdm16_id": "3535",
            "translation": {
              "en": "aykırı değer saptama (outliers, interquartile range)",
              "tr": "aykırı değer saptama (outliers, interquartile range)"
            },
            "value": "outliers_interquartile_range",
            "label": "çeyrekler açıklığı (aykırı değer, interquartile range)"
          },
          {
            "translation": {
              "en": "shapiro wilk",
              "tr": "shapiro wilk"
            },
            "value": "shapiro_wilk",
            "label": "shapiro wilk"
          },
          {
            "wdm16_id": "3451",
            "translation": {
              "en": "veri dağılımı (kolmogorov smirnov)",
              "tr": "veri dağılımı (kolmogorov smirnov)"
            },
            "value": "kolmogorov_smirnov",
            "label": "kolmogorov smirnov (normal dağılım)"
          },
          {
            "translation": {
              "en": "cronbach alpha (anket iç tutarlılığını hesaplama)",
              "tr": "cronbach alpha (anket iç tutarlılığını hesaplama)"
            },
            "value": "cronbach_alpha",
            "label": "cronbach alpha (anket iç tutarlılığını hesaplama)"
          }
        ]
      },
      "selected_column_parameters": {
        "object_type": "object",
        "name": {
          "wdm16_id": "18296",
          "translation": {
            "en": "Seçili Kolon Parametreleri",
            "tr": "Seçili Kolon Parametreleri"
          },
          "value": "selected_column_parameters",
          "label": "Seçili Bağımlı Değişkenler"
        },
        "parameters": {
          "column_column_param_type": {
            "required": "yes",
            "name": {
              "wdm16_id": "11370",
              "translation": {
                "en": "Kolon Parametre Tipi",
                "tr": "Kolon Parametre Tipi"
              },
              "value": "column_column_param_type",
              "label": "Yeni Veri Kullanım Şekli"
            },
            "show": "yes",
            "object_type": "object",
            "rule": {
              "buck": "wisdom",
              "time_of_value": "any_value",
              "data_type": "old_recorded_data",
              "operators": [
                "=",
                "<>"
              ],
              "val_list": [],
              "record_type": "many",
              "data_types": [
                "requesting_data",
                "old_recorded_data"
              ],
              "significant": "true",
              "requesting_operation": "wisdom_data_save",
              "date": "1",
              "type": {
                "translation": {
                  "en": "İstatistik",
                  "tr": "İstatistik"
                },
                "value": "wdm72",
                "label": "İstatistik"
              },
              "op": "="
            },
            "type": "select",
            "options": [
              {
                "wdm16_id": "3464",
                "translation": {
                  "en": "kategorik",
                  "tr": "kategorik"
                },
                "value": "categorical",
                "label": "kategorik"
              },
              {
                "wdm16_id": "3509",
                "translation": {
                  "en": "numerik",
                  "tr": "numerik"
                },
                "value": "numeric",
                "label": "numerik"
              }
            ],
            "if": {
              "friend": {
                "column_param_type_usage": {
                  "operation": {
                    "or_options": [
                      "selected"
                    ]
                  },
                  "parent_param": "yes",
                  "index_list": [
                    "general",
                    "selected_column_parameters",
                    "column_param_type_usage"
                  ],
                  "name": {
                    "translation": {
                      "en": "Kolon Parametre Tipi Kullanım Şekli",
                      "tr": "Kolon Parametre Tipi Kullanım Şekli"
                    },
                    "value": "column_param_type_usage",
                    "label": "Kolon Parametre Tipi Kullanım Şekli"
                  }
                }
              }
            }
          },
          "column_option_group_type": {
            "info": "Hücrelerde zaten tek değer varsa hem numerik hem de kategorik veriler için standart modun seçili olması yeterli olacaktır. Numerik verilerde birden fazla değer bir hücrede var ise o zaman min, max, mean, gibi bir seçenek seçilmesi gerekmektedir.",
            "required": "yes",
            "name": {
              "wdm16_id": "3467",
              "translation": {
                "en": "Kolon Seçenek Grup Şekli",
                "tr": "Kolon Seçenek Grup Şekli"
              },
              "value": "column_option_group_type",
              "label": "Hücredeki Veri Listesine Yaklaşım"
            },
            "show": "yes",
            "object_type": "object",
            "rule": {
              "buck": "wisdom",
              "time_of_value": "any_value",
              "data_type": "old_recorded_data",
              "operators": [
                "=",
                "<>"
              ],
              "val_list": [],
              "record_type": "many",
              "data_types": [
                "requesting_data",
                "old_recorded_data"
              ],
              "significant": "true",
              "requesting_operation": "wisdom_data_save",
              "date": "1",
              "type": {
                "translation": {
                  "en": "İstatistik",
                  "tr": "İstatistik"
                },
                "value": "wdm72",
                "label": "İstatistik"
              },
              "op": "="
            },
            "op_if": "1",
            "type": "select",
            "options": [
              {
                "wdm16_id": "3512",
                "translation": {
                  "en": "standart mod",
                  "tr": "standart mod"
                },
                "value": "standard_mode",
                "label": "standart mod (hücre içerisindeki görünüm modunda)"
              },
              {
                "wdm16_id": "3513",
                "translation": {
                  "en": "Her bir seçenek ayrı ayrı",
                  "tr": "Her bir seçenek ayrı ayrı"
                },
                "if": {
                  "friend": {
                    "column_column_param_type": {
                      "operation": {
                        "or_options": [
                          "categorical"
                        ]
                      },
                      "parent_param": "yes",
                      "name": {
                        "wdm16_id": "11370",
                        "translation": {
                          "en": "Kolon Parametre Tipi",
                          "tr": "Kolon Parametre Tipi"
                        },
                        "value": "column_column_param_type",
                        "label": "Yeni Veri Kullanım Şekli"
                      },
                      "index_list": [
                        "general",
                        "selected_column_parameters",
                        "column_column_param_type"
                      ]
                    }
                  },
                  "friend_if_type": "any_one_of_friend_match"
                },
                "value": "every_option_itself",
                "label": "Her bir seçenek ayrı ayrı"
              },
              {
                "wdm16_id": "3514",
                "translation": {
                  "en": "tüm detaylı kombinasyonlar",
                  "tr": "tüm detaylı kombinasyonlar"
                },
                "if": {
                  "friend": {
                    "column_column_param_type": {
                      "operation": {
                        "or_options": [
                          "categorical"
                        ]
                      },
                      "parent_param": "yes",
                      "index_list": [
                        "general",
                        "selected_column_parameters",
                        "column_column_param_type"
                      ],
                      "name": {
                        "translation": {
                          "en": "Kolon Parametre Tipi",
                          "tr": "Kolon Parametre Tipi"
                        },
                        "value": "column_column_param_type",
                        "label": "Kolon Parametre Tipi"
                      }
                    }
                  },
                  "friend_if_type": "any_one_of_friend_match"
                },
                "value": "create_all_combinations",
                "label": "tüm detaylı kombinasyonlar"
              },
              {
                "wdm16_id": "3515",
                "translation": {
                  "en": "min",
                  "tr": "min"
                },
                "label": "minimum",
                "value": "min",
                "if": {
                  "friend": {
                    "column_column_param_type": {
                      "operation": {
                        "or_options": [
                          "numeric"
                        ]
                      },
                      "parent_param": "yes",
                      "name": {
                        "wdm16_id": "11370",
                        "translation": {
                          "en": "Kolon Parametre Tipi",
                          "tr": "Kolon Parametre Tipi"
                        },
                        "value": "column_column_param_type",
                        "label": "Yeni Veri Kullanım Şekli"
                      },
                      "index_list": [
                        "general",
                        "selected_column_parameters",
                        "column_column_param_type"
                      ]
                    }
                  },
                  "friend_if_type": "any_one_of_friend_match"
                }
              },
              {
                "wdm16_id": "3516",
                "translation": {
                  "en": "maksimum",
                  "tr": "maksimum"
                },
                "label": "maksimum",
                "value": "max",
                "if": {
                  "friend": {
                    "column_column_param_type": {
                      "operation": {
                        "or_options": [
                          "numeric"
                        ]
                      },
                      "parent_param": "yes",
                      "name": {
                        "wdm16_id": "11370",
                        "translation": {
                          "en": "Kolon Parametre Tipi",
                          "tr": "Kolon Parametre Tipi"
                        },
                        "value": "column_column_param_type",
                        "label": "Yeni Veri Kullanım Şekli"
                      },
                      "index_list": [
                        "general",
                        "selected_column_parameters",
                        "column_column_param_type"
                      ]
                    }
                  },
                  "friend_if_type": "any_one_of_friend_match"
                }
              },
              {
                "wdm16_id": "3517",
                "translation": {
                  "en": "tarihi en küçük olan",
                  "tr": "tarihi en küçük olan"
                },
                "label": "tarihi en küçük olan",
                "value": "date_min",
                "if": {
                  "friend": {}
                }
              },
              {
                "wdm16_id": "3518",
                "translation": {
                  "en": "tarihi en büyük olan",
                  "tr": "tarihi en büyük olan"
                },
                "value": "date_max",
                "label": "tarihi en büyük olan"
              },
              {
                "wdm16_id": "3519",
                "translation": {
                  "en": "verilerin ortalaması",
                  "tr": "verilerin ortalaması"
                },
                "label": "verilerin ortalaması",
                "value": "mean",
                "if": {
                  "friend": {
                    "column_column_param_type": {
                      "operation": {
                        "or_options": [
                          "numeric"
                        ]
                      },
                      "parent_param": "yes",
                      "name": {
                        "wdm16_id": "11370",
                        "translation": {
                          "en": "Kolon Parametre Tipi",
                          "tr": "Kolon Parametre Tipi"
                        },
                        "value": "column_column_param_type",
                        "label": "Yeni Veri Kullanım Şekli"
                      },
                      "index_list": [
                        "general",
                        "selected_column_parameters",
                        "column_column_param_type"
                      ]
                    }
                  },
                  "friend_if_type": "any_one_of_friend_match"
                }
              },
              {
                "wdm16_id": "3520",
                "translation": {
                  "en": "verilerin ortancası (medyan)",
                  "tr": "verilerin ortancası (medyan)"
                },
                "label": "verilerin ortancası (medyan)",
                "value": "median",
                "if": {
                  "friend": {
                    "column_column_param_type": {
                      "operation": {
                        "or_options": [
                          "numeric"
                        ]
                      },
                      "parent_param": "yes",
                      "name": {
                        "wdm16_id": "11370",
                        "translation": {
                          "en": "Kolon Parametre Tipi",
                          "tr": "Kolon Parametre Tipi"
                        },
                        "value": "column_column_param_type",
                        "label": "Yeni Veri Kullanım Şekli"
                      },
                      "index_list": [
                        "general",
                        "selected_column_parameters",
                        "column_column_param_type"
                      ]
                    }
                  },
                  "friend_if_type": "any_one_of_friend_match"
                }
              },
              {
                "wdm16_id": "3521",
                "translation": {
                  "en": "en güncel tarihi al",
                  "tr": "en güncel tarihi al"
                },
                "if": {
                  "friend": {
                    "column_column_param_type": {
                      "operation": {
                        "or_options": [
                          "date"
                        ]
                      },
                      "parent_param": "yes",
                      "index_list": [
                        "general",
                        "selected_column_parameters",
                        "column_column_param_type"
                      ],
                      "name": {
                        "translation": {
                          "en": "Kolon Parametre Tipi",
                          "tr": "Kolon Parametre Tipi"
                        },
                        "value": "column_column_param_type",
                        "label": "Kolon Parametre Tipi"
                      }
                    }
                  }
                },
                "value": "get_max_date",
                "label": "en güncel tarihi al"
              },
              {
                "wdm16_id": "3522",
                "translation": {
                  "en": "en eski tarihi al",
                  "tr": "en eski tarihi al"
                },
                "if": {
                  "friend": {
                    "column_column_param_type": {
                      "operation": {
                        "or_options": [
                          "date"
                        ]
                      },
                      "parent_param": "yes",
                      "index_list": [
                        "general",
                        "selected_column_parameters",
                        "column_column_param_type"
                      ],
                      "name": {
                        "translation": {
                          "en": "Kolon Parametre Tipi",
                          "tr": "Kolon Parametre Tipi"
                        },
                        "value": "column_column_param_type",
                        "label": "Kolon Parametre Tipi"
                      }
                    }
                  }
                },
                "value": "get_min_date",
                "label": "en eski tarihi al"
              }
            ],
            "if": {
              "friend_type": "all_friend_match",
              "friend": {
                "column_column_param_type": {
                  "operation": {},
                  "parent_param": "yes",
                  "name": {
                    "wdm16_id": "11370",
                    "translation": {
                      "en": "Kolon Parametre Tipi",
                      "tr": "Kolon Parametre Tipi"
                    },
                    "value": "column_column_param_type",
                    "label": "Yeni Veri Kullanım Şekli"
                  },
                  "index_list": [
                    "general",
                    "selected_column_parameters",
                    "column_column_param_type"
                  ]
                }
              }
            }
          },
          "column_param_type_usage": {
            "name": {
              "wdm16_id": "17645",
              "translation": {
                "en": "Kolon Parametre Tipi Kullanım Şekli",
                "tr": "Kolon Parametre Tipi Kullanım Şekli"
              },
              "value": "column_param_type_usage",
              "label": "Veri Tipi Kullanımı"
            },
            "show": "yes",
            "default": {
              "wdm16_id": "3461",
              "translation": {
                "en": "Otomatik",
                "tr": "Otomatik"
              },
              "value": "auto",
              "label": "Otomatik"
            },
            "required": "yes",
            "rule": {
              "buck": "wisdom",
              "time_of_value": "any_value",
              "data_type": "old_recorded_data",
              "operators": [
                "=",
                "<>"
              ],
              "val_list": [],
              "record_type": "many",
              "data_types": [
                "requesting_data",
                "old_recorded_data"
              ],
              "significant": "true",
              "requesting_operation": "wisdom_data_save",
              "date": "1",
              "type": {
                "translation": {
                  "en": "İstatistik",
                  "tr": "İstatistik"
                },
                "value": "wdm72",
                "label": "İstatistik"
              },
              "op": "="
            },
            "type": "select",
            "options": [
              {
                "wdm16_id": "3461",
                "translation": {
                  "en": "Otomatik",
                  "tr": "Otomatik"
                },
                "value": "auto",
                "label": "Otomatik"
              },
              {
                "wdm16_id": "3507",
                "translation": {
                  "en": " Seçilen Parametre Tipinde ",
                  "tr": " Seçilen Parametre Tipinde "
                },
                "value": "selected",
                "label": " Seçilen Parametre Tipinde "
              }
            ]
          },
          "is_weight_column_for_mean_dependent": {
            "type": "select",
            "options": [
              {
                "translation": {
                  "en": "evet",
                  "tr": "evet"
                },
                "value": "yes",
                "label": "evet"
              },
              {
                "translation": {
                  "en": "hayır",
                  "tr": "hayır"
                },
                "value": "no",
                "label": "hayır"
              }
            ],
            "name": {
              "translation": {
                "en": "Ortalama Ağırlık Kolonu Mu ?",
                "tr": "Ortalama Ağırlık Kolonu Mu ?"
              },
              "value": "is_weight_column_for_mean_dependent",
              "label": "Ortalama Ağırlık Kolonu Mu ?"
            },
            "show": "yes"
          }
        },
        "show": "yes",
        "mother_param": "column_parameter_list",
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "type": "object_list_options",
        "param_order": [
          "column_param_type_usage",
          "column_column_param_type",
          "column_option_group_type",
          "is_weight_column_for_mean_dependent"
        ],
        "if": {
          "friend_type": "all_friend_match",
          "friend": {
            "cross_column_type": {
              "operation": {
                "or_options": [
                  "categorical_categorical",
                  "categorical_numeric",
                  "numeric_numeric",
                  "numeric_categorical",
                  "categorical_and_or_numeric_multi_categorical",
                  "categorical_and_or_numeric_multi_numeric",
                  "categorical_and_categorical_or_numeric"
                ]
              },
              "parent_param": "yes",
              "name": {
                "wdm16_id": "18284",
                "translation": {
                  "en": "Karşılaştırma şekli",
                  "tr": "Karşılaştırma şekli"
                },
                "value": "cross_column_type",
                "label": "Karşılaştırma şekli"
              },
              "index_list": [
                "general",
                "cross_column_type"
              ]
            }
          }
        }
      },
      "wdm3_name": {
        "info": "Bu istatistiksel modelin hangi Wanalyzer projesi ile ilişkili olarak yapıldığını göstermek için Wanalyzer projesinin adını sistem otomati olarak bu alana yazmaktadır.",
        "name": {
          "wdm16_id": "3429",
          "translation": {
            "en": "Proje Adı",
            "tr": "Proje Adı"
          },
          "value": "wdm3_name",
          "label": "Proje Adı"
        },
        "show": "no",
        "object_type": "object",
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "type": "plain_text"
      },
      "status": {
        "info": "İstatistiksel modelin analizinin devam edip etmediğini göstermektedir.",
        "name": {
          "wdm16_id": "1646",
          "translation": {
            "en": "Status",
            "tr": "Status"
          },
          "value": "status",
          "label": "Durum"
        },
        "show": "no",
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [
            "=",
            "<>"
          ],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "disabled": 1,
        "type": "select",
        "options": [
          {
            "wdm16_id": "3428",
            "translation": {
              "en": "tamamlandı",
              "tr": "tamamlandı"
            },
            "value": "completed",
            "label": "tamamlandı"
          },
          {
            "translation": {
              "en": "devam ediyor",
              "tr": "devam ediyor"
            },
            "value": "continuing",
            "label": "devam ediyor"
          }
        ]
      },
      "cross_column_type": {
        "name": {
          "wdm16_id": "18284",
          "translation": {
            "en": "Karşılaştırma şekli",
            "tr": "Karşılaştırma şekli"
          },
          "value": "cross_column_type",
          "label": "Karşılaştırma Tipi"
        },
        "show": "yes",
        "required": "yes",
        "type": "select",
        "options": [
          {
            "wdm16_id": "3439",
            "translation": {
              "en": "otomatik",
              "tr": "otomatik"
            },
            "value": "auto",
            "label": "otomatik"
          },
          {
            "wdm16_id": "18285",
            "translation": {
              "en": "kategorik & kategorik",
              "tr": "kategorik & kategorik"
            },
            "value": "categorical_categorical",
            "label": "kategorik & kategorik"
          },
          {
            "wdm16_id": "18289",
            "translation": {
              "en": "kategorik & numerik",
              "tr": "kategorik & numerik"
            },
            "value": "categorical_numeric",
            "label": "kategorik & sayısal"
          },
          {
            "wdm16_id": "18290",
            "translation": {
              "en": "numerik & numerik",
              "tr": "numerik & numerik"
            },
            "value": "numeric_numeric",
            "label": "sayısal & sayısal"
          },
          {
            "wdm16_id": "18291",
            "translation": {
              "en": "numerik & kategorik",
              "tr": "numerik & kategorik"
            },
            "value": "numeric_categorical",
            "label": "sayısal & kategorik"
          },
          {
            "translation": {
              "en": "kategorik ve/veya sayısal (çoklu) & kategorik",
              "tr": "kategorik ve/veya sayısal (çoklu) & kategorik"
            },
            "value": "categorical_and_or_numeric_multi_categorical",
            "label": "kategorik ve/veya sayısal (çoklu) & kategorik"
          },
          {
            "translation": {
              "en": "kategorik ve/veya sayısal (çoklu) & sayısal",
              "tr": "kategorik ve/veya sayısal (çoklu) & sayısal"
            },
            "value": "categorical_and_or_numeric_multi_numeric",
            "label": "kategorik ve/veya sayısal (çoklu) & sayısal"
          },
          {
            "translation": {
              "en": "kategorik & kategorik veya sayısal",
              "tr": "kategorik & kategorik veya sayısal"
            },
            "value": "categorical_and_categorical_or_numeric",
            "label": "kategorik & kategorik veya sayısal"
          }
        ],
        "if": {
          "friend_type": "any_one_of_friend_match",
          "friend": {
            "analyze_type": {
              "operation": {
                "or_options": [
                  "cross_columns"
                ]
              },
              "parent_param": "yes",
              "name": {
                "wdm16_id": "3440",
                "translation": {
                  "en": "Analiz Tipi",
                  "tr": "Analiz Tipi"
                },
                "value": "analyze_type",
                "label": "Analiz Tipi"
              },
              "index_list": [
                "general",
                "analyze_type"
              ]
            }
          }
        }
      },
      "selected_categorical_to_nominal": {
        "object_type": "object",
        "name": {
          "wdm16_id": "3541",
          "translation": {
            "en": "Seçilen kategorik & sayısal",
            "tr": "Seçilen kategorik & sayısal"
          },
          "value": "selected_categorical_to_nominal",
          "label": "Seçilen kategorik & sayısal İstatistik Testi"
        },
        "parameters": {
          "categorical_numeric_distribution_kolmogorov_smirnov": {
            "info": "p değeri 0.05'den büyük ise veriler normal dağılıyor olarak kabul edilmektedir.",
            "name": {
              "translation": {
                "en": "Kolmogorov Smirnov Veri Dağılımında Kullanılsın mı ?",
                "tr": "Kolmogorov Smirnov Veri Dağılımında Kullanılsın mı ?"
              },
              "value": "categorical_numeric_distribution_kolmogorov_smirnov",
              "label": "Kolmogorov Smirnov Veri Dağılımında Kullanılsın mı ?"
            },
            "show": "yes",
            "required": "yes",
            "type": "select",
            "options": [
              {
                "value": "yes",
                "label": "evet"
              },
              {
                "value": "no",
                "label": "hayır"
              }
            ],
            "if": {
              "friend_type": "all_friend_match",
              "friend": {
                "categorical_numeric_distribution_test_list_usage_type": {
                  "operation": {},
                  "parent_param": "yes",
                  "name": {
                    "translation": {
                      "en": "Veri Dağılım Testleri Kullanım Şekli",
                      "tr": "Veri Dağılım Testleri Kullanım Şekli"
                    },
                    "value": "categorical_numeric_distribution_test_list_usage_type",
                    "label": "Veri Dağılım Testleri Kullanım Şekli"
                  },
                  "index_list": [
                    "general",
                    "selected_categorical_to_nominal",
                    "categorical_numeric_distribution_test_list_usage_type"
                  ]
                },
                "categorical_numeric_control_type_before_test": {
                  "operation": {
                    "or_options": [
                      "only_total_samples_data_distribution",
                      "only_every_group_data_distribution",
                      "both_total_sample_size_and_data_distribution",
                      "total_sample_size_or_data_distribution",
                      "both_every_group_sample_size_and_data_distribution",
                      "every_group_sample_size_or_data_distribution"
                    ]
                  },
                  "parent_param": "yes",
                  "name": {
                    "translation": {
                      "en": "Test Öncesi Kontrol Tipi",
                      "tr": "Test Öncesi Kontrol Tipi"
                    },
                    "value": "categorical_numeric_control_type_before_test",
                    "label": "Test Öncesi Kontrol Tipi"
                  },
                  "index_list": [
                    "general",
                    "selected_categorical_to_nominal",
                    "categorical_numeric_control_type_before_test"
                  ]
                }
              }
            }
          },
          "categorical_numeric_distribution_skewness_range": {
            "info": "Veri dağılımının normal kabul edileceği skewness istatistiksel değer aralığını ifade etmektedir. örneğin -1.5 ile + 1.5 aralığında ise normal dağılıyor şeklinde kabul edilebilmektedir. Farklı çalışmalarda farklı aralıklar da ifade edilebilmektedir.",
            "name": {
              "translation": {
                "en": "Skewness Aralığı",
                "tr": "Skewness Aralığı"
              },
              "value": "categorical_numeric_distribution_skewness_range",
              "label": "Skewness Aralığı"
            },
            "show": "yes",
            "required": "yes",
            "type": "float",
            "if": {
              "friend_type": "any_one_of_friend_match",
              "friend": {
                "categorical_numeric_distribution_skewness": {
                  "operation": {
                    "or_options": [
                      "yes"
                    ]
                  },
                  "parent_param": "yes",
                  "name": {
                    "translation": {
                      "en": "Skewness (çarpıklık) Veri Dağılımında Kullanılsın mı ?",
                      "tr": "Skewness (çarpıklık) Veri Dağılımında Kullanılsın mı ?"
                    },
                    "value": "categorical_numeric_distribution_skewness",
                    "label": "Skewness (çarpıklık) Veri Dağılımında Kullanılsın mı ?"
                  },
                  "index_list": [
                    "general",
                    "selected_categorical_to_nominal",
                    "categorical_numeric_distribution_skewness"
                  ]
                }
              }
            }
          },
          "categorical_numeric_distribution_shapiro_wilk": {
            "info": "p değeri 0.05'den büyük ise veriler normal dağılıyor olarak kabul edilmektedir.",
            "name": {
              "translation": {
                "en": "Shapiro Wilk Veri Dağılımında Kullanılsın mı ?",
                "tr": "Shapiro Wilk Veri Dağılımında Kullanılsın mı ?"
              },
              "value": "categorical_numeric_distribution_shapiro_wilk",
              "label": "Shapiro Wilk Veri Dağılımında Kullanılsın mı ?"
            },
            "show": "yes",
            "required": "yes",
            "type": "select",
            "options": [
              {
                "value": "yes",
                "label": "evet"
              },
              {
                "value": "no",
                "label": "hayır"
              }
            ],
            "if": {
              "friend_type": "all_friend_match",
              "friend": {
                "categorical_numeric_distribution_test_list_usage_type": {
                  "operation": {},
                  "parent_param": "yes",
                  "name": {
                    "translation": {
                      "en": "Veri Dağılım Testleri Kullanım Şekli",
                      "tr": "Veri Dağılım Testleri Kullanım Şekli"
                    },
                    "value": "categorical_numeric_distribution_test_list_usage_type",
                    "label": "Veri Dağılım Testleri Kullanım Şekli"
                  },
                  "index_list": [
                    "general",
                    "selected_categorical_to_nominal",
                    "categorical_numeric_distribution_test_list_usage_type"
                  ]
                },
                "categorical_numeric_control_type_before_test": {
                  "operation": {
                    "or_options": [
                      "only_total_samples_data_distribution",
                      "only_every_group_data_distribution",
                      "both_total_sample_size_and_data_distribution",
                      "both_every_group_sample_size_and_data_distribution",
                      "total_sample_size_or_data_distribution",
                      "every_group_sample_size_or_data_distribution"
                    ]
                  },
                  "parent_param": "yes",
                  "name": {
                    "translation": {
                      "en": "Test Öncesi Kontrol Tipi",
                      "tr": "Test Öncesi Kontrol Tipi"
                    },
                    "value": "categorical_numeric_control_type_before_test",
                    "label": "Test Öncesi Kontrol Tipi"
                  },
                  "index_list": [
                    "general",
                    "selected_categorical_to_nominal",
                    "categorical_numeric_control_type_before_test"
                  ]
                }
              }
            }
          },
          "categorical_numeric_control_type_before_test": {
            "required": "yes",
            "type": "select",
            "options": [
              {
                "translation": {
                  "en": "kontrol yapılmasın",
                  "tr": "kontrol yapılmasın"
                },
                "value": "not_control",
                "label": "kontrol yapılmasın"
              },
              {
                "translation": {
                  "en": "sadece toplam örnek sayısı kontrol edilsin",
                  "tr": "sadece toplam örnek sayısı kontrol edilsin"
                },
                "value": "only_total_sample_size",
                "label": "sadece toplam örnek sayısı kontrol edilsin"
              },
              {
                "translation": {
                  "en": "sadece her bir gruba ait örnek sayısı kontrol edilsin",
                  "tr": "sadece her bir gruba ait örnek sayısı kontrol edilsin"
                },
                "value": "only_every_group_sample_size",
                "label": "sadece her bir gruba ait örnek sayısı kontrol edilsin"
              },
              {
                "translation": {
                  "en": "sadece tüm örneklere ait veri dağılımı",
                  "tr": "sadece tüm örneklere ait veri dağılımı"
                },
                "value": "only_total_samples_data_distribution",
                "label": "sadece tüm örneklere ait veri dağılımı"
              },
              {
                "translation": {
                  "en": "sadece her bir gruba ait veri dağılımı",
                  "tr": "sadece her bir gruba ait veri dağılımı"
                },
                "value": "only_every_group_data_distribution",
                "label": "sadece her bir gruba ait veri dağılımı"
              },
              {
                "translation": {
                  "en": "tüm örneklere ait hem örnek sayısı hem de veri dağılımı kontrol edilsin",
                  "tr": "tüm örneklere ait hem örnek sayısı hem de veri dağılımı kontrol edilsin"
                },
                "value": "both_total_sample_size_and_data_distribution",
                "label": "tüm örneklere ait hem örnek sayısı hem de veri dağılımı kontrol edilsin"
              },
              {
                "translation": {
                  "en": "her bir gruba ait hem örnek sayısı hem de veri dağılımı kontrol edilsin",
                  "tr": "her bir gruba ait hem örnek sayısı hem de veri dağılımı kontrol edilsin"
                },
                "value": "both_every_group_sample_size_and_data_distribution",
                "label": "her bir gruba ait hem örnek sayısı hem de veri dağılımı kontrol edilsin"
              },
              {
                "translation": {
                  "en": "tüm örneklere ait örnek sayısı veya veri dağılımı kontrol edilsin",
                  "tr": "tüm örneklere ait örnek sayısı veya veri dağılımı kontrol edilsin"
                },
                "value": "total_sample_size_or_data_distribution",
                "label": "tüm örneklere ait örnek sayısı veya veri dağılımı kontrol edilsin"
              },
              {
                "translation": {
                  "en": "her bir gruba ait örnek sayısı veya veri dağılımı kontrol edilsin",
                  "tr": "her bir gruba ait örnek sayısı veya veri dağılımı kontrol edilsin"
                },
                "value": "every_group_sample_size_or_data_distribution",
                "label": "her bir gruba ait örnek sayısı veya veri dağılımı kontrol edilsin"
              }
            ],
            "name": {
              "translation": {
                "en": "Test Öncesi Kontrol Tipi",
                "tr": "Test Öncesi Kontrol Tipi"
              },
              "value": "categorical_numeric_control_type_before_test",
              "label": "Test Öncesi Kontrol Tipi"
            },
            "show": "yes"
          },
          "categorical_numeric_distribution_kurtosis_range": {
            "info": "Veri dağılımının normal kabul edileceği kurtosis istatistiksel değer aralığını ifade etmektedir. örneğin -1.5 ile + 1.5 aralığında ise normal dağılıyor şeklinde kabul edilebilmektedir. Farklı çalışmalarda farklı aralıklar da ifade edilebilmektedir.",
            "name": {
              "translation": {
                "en": "Kurtosis Aralığı",
                "tr": "Kurtosis Aralığı"
              },
              "value": "categorical_numeric_distribution_kurtosis_range",
              "label": "Kurtosis Aralığı"
            },
            "show": "yes",
            "required": "yes",
            "type": "float",
            "if": {
              "friend_type": "any_one_of_friend_match",
              "friend": {
                "categorical_numeric_distribution_kurtosis": {
                  "operation": {
                    "or_options": [
                      "yes"
                    ]
                  },
                  "parent_param": "yes",
                  "name": {
                    "translation": {
                      "en": "Kurtosis (basıklık) Veri Dağılımında Kullanılsın mı ?",
                      "tr": "Kurtosis (basıklık) Veri Dağılımında Kullanılsın mı ?"
                    },
                    "value": "categorical_numeric_distribution_kurtosis",
                    "label": "Kurtosis (basıklık) Veri Dağılımında Kullanılsın mı ?"
                  },
                  "index_list": [
                    "general",
                    "selected_categorical_to_nominal",
                    "categorical_numeric_distribution_kurtosis"
                  ]
                }
              }
            }
          },
          "categorical_numeric_distribution_kurtosis": {
            "name": {
              "translation": {
                "en": "Kurtosis (basıklık) Veri Dağılımında Kullanılsın mı ?",
                "tr": "Kurtosis (basıklık) Veri Dağılımında Kullanılsın mı ?"
              },
              "value": "categorical_numeric_distribution_kurtosis",
              "label": "Kurtosis (basıklık) Veri Dağılımında Kullanılsın mı ?"
            },
            "show": "yes",
            "required": "yes",
            "type": "select",
            "options": [
              {
                "value": "yes",
                "label": "evet"
              },
              {
                "value": "no",
                "label": "hayır"
              }
            ],
            "if": {
              "friend_type": "all_friend_match",
              "friend": {
                "categorical_numeric_distribution_test_list_usage_type": {
                  "operation": {},
                  "parent_param": "yes",
                  "name": {
                    "translation": {
                      "en": "Veri Dağılım Testleri Kullanım Şekli",
                      "tr": "Veri Dağılım Testleri Kullanım Şekli"
                    },
                    "value": "categorical_numeric_distribution_test_list_usage_type",
                    "label": "Veri Dağılım Testleri Kullanım Şekli"
                  },
                  "index_list": [
                    "general",
                    "selected_categorical_to_nominal",
                    "categorical_numeric_distribution_test_list_usage_type"
                  ]
                },
                "categorical_numeric_control_type_before_test": {
                  "operation": {
                    "or_options": [
                      "only_total_samples_data_distribution",
                      "only_every_group_data_distribution",
                      "both_total_sample_size_and_data_distribution",
                      "both_every_group_sample_size_and_data_distribution",
                      "total_sample_size_or_data_distribution",
                      "every_group_sample_size_or_data_distribution"
                    ]
                  },
                  "parent_param": "yes",
                  "name": {
                    "translation": {
                      "en": "Test Öncesi Kontrol Tipi",
                      "tr": "Test Öncesi Kontrol Tipi"
                    },
                    "value": "categorical_numeric_control_type_before_test",
                    "label": "Test Öncesi Kontrol Tipi"
                  },
                  "index_list": [
                    "general",
                    "selected_categorical_to_nominal",
                    "categorical_numeric_control_type_before_test"
                  ]
                }
              }
            }
          },
          "categorical_numeric_distribution_skewness": {
            "name": {
              "translation": {
                "en": "Skewness (çarpıklık) Veri Dağılımında Kullanılsın mı ?",
                "tr": "Skewness (çarpıklık) Veri Dağılımında Kullanılsın mı ?"
              },
              "value": "categorical_numeric_distribution_skewness",
              "label": "Skewness (çarpıklık) Veri Dağılımında Kullanılsın mı ?"
            },
            "show": "yes",
            "required": "yes",
            "type": "select",
            "options": [
              {
                "translation": {
                  "en": "evet",
                  "tr": "evet"
                },
                "value": "yes",
                "label": "evet"
              },
              {
                "translation": {
                  "en": "hayır",
                  "tr": "hayır"
                },
                "value": "no",
                "label": "hayır"
              }
            ],
            "if": {
              "friend_type": "all_friend_match",
              "friend": {
                "categorical_numeric_distribution_test_list_usage_type": {
                  "operation": {},
                  "parent_param": "yes",
                  "name": {
                    "translation": {
                      "en": "Veri Dağılım Testleri Kullanım Şekli",
                      "tr": "Veri Dağılım Testleri Kullanım Şekli"
                    },
                    "value": "categorical_numeric_distribution_test_list_usage_type",
                    "label": "Veri Dağılım Testleri Kullanım Şekli"
                  },
                  "index_list": [
                    "general",
                    "selected_categorical_to_nominal",
                    "categorical_numeric_distribution_test_list_usage_type"
                  ]
                },
                "categorical_numeric_control_type_before_test": {
                  "operation": {
                    "or_options": [
                      "only_total_samples_data_distribution",
                      "both_total_sample_size_and_data_distribution",
                      "only_every_group_data_distribution",
                      "both_every_group_sample_size_and_data_distribution",
                      "total_sample_size_or_data_distribution",
                      "every_group_sample_size_or_data_distribution"
                    ]
                  },
                  "parent_param": "yes",
                  "name": {
                    "translation": {
                      "en": "Test Öncesi Kontrol Tipi",
                      "tr": "Test Öncesi Kontrol Tipi"
                    },
                    "value": "categorical_numeric_control_type_before_test",
                    "label": "Test Öncesi Kontrol Tipi"
                  },
                  "index_list": [
                    "general",
                    "selected_categorical_to_nominal",
                    "categorical_numeric_control_type_before_test"
                  ]
                }
              }
            }
          },
          "categorical_numeric_total_sample_size_control_type": {
            "name": {
              "translation": {
                "en": "Toplam Örnek Sayısı Kontrol Tipi",
                "tr": "Toplam Örnek Sayısı Kontrol Tipi"
              },
              "value": "categorical_numeric_total_sample_size_control_type",
              "label": "Toplam Örnek Sayısı Kontrol Tipi"
            },
            "show": "yes",
            "required": "yes",
            "type": "select",
            "options": [
              {
                "translation": {
                  "en": "küçüktür",
                  "tr": "küçüktür"
                },
                "value": "smaller",
                "label": "küçüktür"
              },
              {
                "translation": {
                  "en": "küçük eşittir",
                  "tr": "küçük eşittir"
                },
                "value": "smaller_and_equal",
                "label": "küçük eşittir"
              },
              {
                "translation": {
                  "en": "büyüktür",
                  "tr": "büyüktür"
                },
                "value": "bigger",
                "label": "büyüktür"
              },
              {
                "translation": {
                  "en": "büyük eşittir",
                  "tr": "büyük eşittir"
                },
                "value": "bigger_and_equal",
                "label": "büyük eşittir"
              }
            ],
            "if": {
              "friend_type": "any_one_of_friend_match",
              "friend": {
                "categorical_numeric_control_type_before_test": {
                  "operation": {
                    "or_options": [
                      "only_total_sample_size",
                      "both_total_sample_size_and_data_distribution",
                      "total_sample_size_or_data_distribution"
                    ]
                  },
                  "parent_param": "yes",
                  "name": {
                    "translation": {
                      "en": "Test Öncesi Kontrol Tipi",
                      "tr": "Test Öncesi Kontrol Tipi"
                    },
                    "value": "categorical_numeric_control_type_before_test",
                    "label": "Test Öncesi Kontrol Tipi"
                  },
                  "index_list": [
                    "general",
                    "selected_categorical_to_nominal",
                    "categorical_numeric_control_type_before_test"
                  ]
                }
              }
            }
          },
          "categorical_numeric_total_sample_size_value": {
            "show": "yes",
            "required": "yes",
            "type": "integer",
            "name": {
              "translation": {
                "en": "Toplam Örnek Sayısı Değeri",
                "tr": "Toplam Örnek Sayısı Değeri"
              },
              "value": "categorical_numeric_total_sample_size_value",
              "label": "Toplam Örnek Sayısı Değeri"
            },
            "if": {
              "friend_type": "all_friend_match",
              "friend": {
                "categorical_numeric_control_type_before_test": {
                  "operation": {
                    "or_options": [
                      "only_total_sample_size",
                      "both_total_sample_size_and_data_distribution",
                      "total_sample_size_or_data_distribution"
                    ]
                  },
                  "parent_param": "yes",
                  "name": {
                    "translation": {
                      "en": "Test Öncesi Kontrol Tipi",
                      "tr": "Test Öncesi Kontrol Tipi"
                    },
                    "value": "categorical_numeric_control_type_before_test",
                    "label": "Test Öncesi Kontrol Tipi"
                  },
                  "index_list": [
                    "general",
                    "selected_categorical_to_nominal",
                    "categorical_numeric_control_type_before_test"
                  ]
                },
                "categorical_numeric_total_sample_size_control_type": {
                  "operation": {
                    "or_options": [
                      "smaller",
                      "bigger",
                      "smaller_and_equal",
                      "bigger_and_equal"
                    ]
                  },
                  "parent_param": "yes",
                  "name": {
                    "translation": {
                      "en": "Toplam Örnek Sayısı Kontrol Tipi",
                      "tr": "Toplam Örnek Sayısı Kontrol Tipi"
                    },
                    "value": "categorical_numeric_total_sample_size_control_type",
                    "label": "Toplam Örnek Sayısı Kontrol Tipi"
                  },
                  "index_list": [
                    "general",
                    "selected_categorical_to_nominal",
                    "categorical_numeric_total_sample_size_control_type"
                  ]
                }
              }
            }
          },
          "categorical_numeric_group_sample_size_value": {
            "show": "yes",
            "required": "yes",
            "type": "integer",
            "name": {
              "translation": {
                "en": "Grup Örnek Sayısı Değeri",
                "tr": "Grup Örnek Sayısı Değeri"
              },
              "value": "categorical_numeric_group_sample_size_value",
              "label": "Grup Örnek Sayısı Değeri"
            },
            "if": {
              "friend_type": "all_friend_match",
              "friend": {
                "categorical_numeric_group_sample_size_control_type": {
                  "operation": {
                    "or_options": [
                      "smaller",
                      "smaller_and_equal",
                      "bigger",
                      "bigger_and_equal"
                    ]
                  },
                  "parent_param": "yes",
                  "name": {
                    "translation": {
                      "en": "Grup Sayısı Kontrol Tipi",
                      "tr": "Grup Sayısı Kontrol Tipi"
                    },
                    "value": "categorical_numeric_group_sample_size_control_type",
                    "label": "Grup Sayısı Kontrol Tipi"
                  },
                  "index_list": [
                    "general",
                    "selected_categorical_to_nominal",
                    "categorical_numeric_group_sample_size_control_type"
                  ]
                },
                "categorical_numeric_control_type_before_test": {
                  "operation": {
                    "or_options": [
                      "only_every_group_sample_size",
                      "both_every_group_sample_size_and_data_distribution",
                      "every_group_sample_size_or_data_distribution"
                    ]
                  },
                  "parent_param": "yes",
                  "name": {
                    "translation": {
                      "en": "Test Öncesi Kontrol Tipi",
                      "tr": "Test Öncesi Kontrol Tipi"
                    },
                    "value": "categorical_numeric_control_type_before_test",
                    "label": "Test Öncesi Kontrol Tipi"
                  },
                  "index_list": [
                    "general",
                    "selected_categorical_to_nominal",
                    "categorical_numeric_control_type_before_test"
                  ]
                }
              }
            }
          },
          "categorical_numeric_distribution_test_list_usage_type": {
            "name": {
              "translation": {
                "en": "Veri Dağılım Testleri Kullanım Şekli",
                "tr": "Veri Dağılım Testleri Kullanım Şekli"
              },
              "value": "categorical_numeric_distribution_test_list_usage_type",
              "label": "Veri Dağılım Testleri Kullanım Şekli"
            },
            "show": "yes",
            "required": "yes",
            "type": "select",
            "options": [
              {
                "translation": {
                  "en": "herhangi bir test ile normal dağılıyorsa yeterli",
                  "tr": "herhangi bir test ile normal dağılıyorsa yeterli"
                },
                "value": "one_of_them",
                "label": "herhangi bir test ile normal dağılıyorsa yeterli"
              },
              {
                "translation": {
                  "en": "tüm seçili testler normal dağılıyorsa kullan",
                  "tr": "tüm seçili testler normal dağılıyorsa kullan"
                },
                "value": "all_of_them",
                "label": "tüm seçili testler normal dağılıyorsa kullan"
              },
              {
                "translation": {
                  "en": "tüm seçili testler normal dağılmıyorsa kullan",
                  "tr": "tüm seçili testler normal dağılmıyorsa kullan"
                },
                "value": "all_of_them_not_normally_distributed",
                "label": "tüm seçili testler normal dağılmıyorsa kullan"
              },
              {
                "translation": {
                  "en": "testlerden birisi normal dağılmıyorsa kullan",
                  "tr": "testlerden birisi normal dağılmıyorsa kullan"
                },
                "value": "one_of_them_not_normally_distributed",
                "label": "testlerden birisi normal dağılmıyorsa kullan"
              }
            ],
            "if": {
              "friend_type": "any_one_of_friend_match",
              "friend": {
                "categorical_numeric_control_type_before_test": {
                  "operation": {
                    "or_options": [
                      "only_total_samples_data_distribution",
                      "only_every_group_data_distribution",
                      "both_total_sample_size_and_data_distribution",
                      "both_every_group_sample_size_and_data_distribution",
                      "total_sample_size_or_data_distribution",
                      "every_group_sample_size_or_data_distribution"
                    ]
                  },
                  "parent_param": "yes",
                  "name": {
                    "translation": {
                      "en": "Test Öncesi Kontrol Tipi",
                      "tr": "Test Öncesi Kontrol Tipi"
                    },
                    "value": "categorical_numeric_control_type_before_test",
                    "label": "Test Öncesi Kontrol Tipi"
                  },
                  "index_list": [
                    "general",
                    "selected_categorical_to_nominal",
                    "categorical_numeric_control_type_before_test"
                  ]
                }
              }
            }
          },
          "categorical_numeric_group_sample_size_control_type": {
            "name": {
              "translation": {
                "en": "Grup Sayısı Kontrol Tipi",
                "tr": "Grup Sayısı Kontrol Tipi"
              },
              "value": "categorical_numeric_group_sample_size_control_type",
              "label": "Grup Sayısı Kontrol Tipi"
            },
            "show": "yes",
            "required": "yes",
            "type": "select",
            "options": [
              {
                "value": "smaller",
                "label": "küçüktür"
              },
              {
                "value": "smaller_and_equal",
                "label": "küçük eşittir"
              },
              {
                "value": "bigger",
                "label": "büyüktür"
              },
              {
                "value": "bigger_and_equal",
                "label": "büyük eşittir"
              }
            ],
            "if": {
              "friend_type": "any_one_of_friend_match",
              "friend": {
                "categorical_numeric_control_type_before_test": {
                  "operation": {
                    "or_options": [
                      "only_every_group_sample_size",
                      "both_every_group_sample_size_and_data_distribution",
                      "every_group_sample_size_or_data_distribution"
                    ]
                  },
                  "parent_param": "yes",
                  "name": {
                    "translation": {
                      "en": "Test Öncesi Kontrol Tipi",
                      "tr": "Test Öncesi Kontrol Tipi"
                    },
                    "value": "categorical_numeric_control_type_before_test",
                    "label": "Test Öncesi Kontrol Tipi"
                  },
                  "index_list": [
                    "general",
                    "selected_categorical_to_nominal",
                    "categorical_numeric_control_type_before_test"
                  ]
                }
              }
            }
          }
        },
        "show": "yes",
        "mother_param": "categorical_to_nominal",
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "required": "yes",
        "naming": "1",
        "type": "object_list_options",
        "param_order": [
          "categorical_numeric_control_type_before_test",
          "categorical_numeric_total_sample_size_control_type",
          "categorical_numeric_total_sample_size_value",
          "categorical_numeric_group_sample_size_control_type",
          "categorical_numeric_group_sample_size_value",
          "categorical_numeric_distribution_test_list_usage_type",
          "categorical_numeric_distribution_skewness",
          "categorical_numeric_distribution_skewness_range",
          "categorical_numeric_distribution_kurtosis",
          "categorical_numeric_distribution_kurtosis_range",
          "categorical_numeric_distribution_shapiro_wilk",
          "categorical_numeric_distribution_kolmogorov_smirnov"
        ],
        "if": {
          "friend_type": "all_friend_match",
          "friend": {
            "usage_of_comparison_tests": {
              "operation": {
                "or_options": [
                  "yes"
                ]
              },
              "parent_param": "yes",
              "name": {
                "wdm16_id": "3538",
                "translation": {
                  "en": "Karşılaştırmalı İstatistiksel Testler Kullanımı",
                  "tr": "Karşılaştırmalı İstatistiksel Testler Kullanımı"
                },
                "value": "usage_of_comparison_tests",
                "label": "Karşılaştırmalı İstatistiksel Testler Kullanımı"
              },
              "index_list": [
                "general",
                "usage_of_comparison_tests"
              ]
            }
          }
        }
      },
      "last_update_time_of_project_cases": {
        "info": "Proje vakaları (satırları) ne zaman güncellendiği bu parametreye sistem tarafından istatistiksel modelin analizi sırasında eklenmektedir. Böylece en son hangi güncelleme sonrası istatistiksel modelin çalıştırıldığı bilinmektedir.",
        "type": "plain_text",
        "name": {
          "wdm16_id": "3431",
          "translation": {
            "en": "Proje Vakaları Son Güncelleme Tarihi",
            "tr": "Proje Vakaları Son Güncelleme Tarihi"
          },
          "value": "last_update_time_of_project_cases",
          "label": "Analiz uygulandığında dataset güncelleme tarihi"
        },
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "show": "no"
      },
      "mean_type": {
        "if": {
          "friend_type": "any_one_of_friend_match",
          "friend": {
            "analyze_type": {
              "operation": {
                "or_options": [
                  "mean"
                ]
              },
              "parent_param": "yes",
              "name": {
                "wdm16_id": "3440",
                "translation": {
                  "en": "Analiz Tipi",
                  "tr": "Analiz Tipi"
                },
                "value": "analyze_type",
                "label": "Analiz Metodu"
              },
              "index_list": [
                "general",
                "analyze_type"
              ]
            }
          }
        },
        "type": "select",
        "options": [
          {
            "translation": {
              "en": "aritmetik",
              "tr": "aritmetik"
            },
            "value": "arithmetic",
            "label": "aritmetik"
          },
          {
            "translation": {
              "en": "ağırlıklı",
              "tr": "ağırlıklı"
            },
            "value": "weighted",
            "label": "ağırlıklı"
          }
        ],
        "name": {
          "translation": {
            "en": "Ortalama Tipi",
            "tr": "Ortalama Tipi"
          },
          "value": "mean_type",
          "label": "Ortalama Tipi"
        },
        "show": "yes"
      },
      "before_after": {
        "required": "yes",
        "name": {
          "wdm16_id": "3570",
          "translation": {
            "en": "Önce & Sonra",
            "tr": "Önce & Sonra"
          },
          "value": "before_after",
          "label": "Önce & Sonra"
        },
        "parameters": {
          "before_after_timeline_mode": {
            "name": {
              "wdm16_id": "3479",
              "translation": {
                "en": "Zaman Çizelgesi Modu",
                "tr": "Zaman Çizelgesi Modu"
              },
              "value": "before_after_timeline_mode",
              "label": "Zaman Çizelgesi Modu"
            },
            "show": "no",
            "required": "yes",
            "rule": {
              "buck": "wisdom",
              "time_of_value": "any_value",
              "data_type": "old_recorded_data",
              "operators": [
                "=",
                "<>"
              ],
              "val_list": [],
              "record_type": "many",
              "data_types": [
                "requesting_data",
                "old_recorded_data"
              ],
              "significant": "true",
              "requesting_operation": "wisdom_data_save",
              "date": "1",
              "type": {
                "translation": {
                  "en": "İstatistik",
                  "tr": "İstatistik"
                },
                "value": "wdm72",
                "label": "İstatistik"
              },
              "op": "="
            },
            "disabled": 1,
            "type": "select",
            "options": [
              {
                "wdm16_id": "809",
                "translation": {
                  "en": "evet",
                  "tr": "evet"
                },
                "value": "yes",
                "label": "evet"
              },
              {
                "wdm16_id": "1327",
                "translation": {
                  "en": "hayır",
                  "tr": "hayır"
                },
                "value": "no",
                "label": "hayır"
              }
            ]
          },
          "before_after_will_free_days_be_defined": {
            "name": {
              "wdm16_id": "3481",
              "translation": {
                "en": "Boş Günler Tanımlansın mı?",
                "tr": "Boş Günler Tanımlansın mı?"
              },
              "value": "before_after_will_free_days_be_defined",
              "label": "Boş Günler Tanımlansın mı?"
            },
            "show": "no",
            "required": "yes",
            "rule": {
              "buck": "wisdom",
              "time_of_value": "any_value",
              "data_type": "old_recorded_data",
              "operators": [
                "=",
                "<>"
              ],
              "val_list": [],
              "record_type": "many",
              "data_types": [
                "requesting_data",
                "old_recorded_data"
              ],
              "significant": "true",
              "requesting_operation": "wisdom_data_save",
              "date": "1",
              "type": {
                "translation": {
                  "en": "İstatistik",
                  "tr": "İstatistik"
                },
                "value": "wdm72",
                "label": "İstatistik"
              },
              "op": "="
            },
            "disabled": 1,
            "type": "select",
            "options": [
              {
                "wdm16_id": "809",
                "translation": {
                  "en": "evet",
                  "tr": "evet"
                },
                "value": "yes",
                "label": "evet"
              },
              {
                "wdm16_id": "1327",
                "translation": {
                  "en": "hayır",
                  "tr": "hayır"
                },
                "value": "no",
                "label": "hayır"
              }
            ],
            "if": {
              "friend": {
                "before_after_timeline_mode": {
                  "operation": {
                    "or_options": [
                      "yes"
                    ]
                  },
                  "parent_param": "yes",
                  "index_list": [
                    "general",
                    "before_after",
                    "before_after_timeline_mode"
                  ],
                  "name": {
                    "translation": {
                      "en": "Zaman Çizelgesi Modu",
                      "tr": "Zaman Çizelgesi Modu"
                    },
                    "value": "before_after_timeline_mode",
                    "label": "Zaman Çizelgesi Modu"
                  }
                }
              }
            }
          },
          "first_column_value_selection": {
            "name": {
              "wdm16_id": "18298",
              "translation": {
                "en": "Birinci Kolon Değer Seçimi",
                "tr": "Birinci Kolon Değer Seçimi"
              },
              "value": "first_column_value_selection",
              "label": "Birinci Değişken Değer Seçimi"
            },
            "show": "yes",
            "required": "yes",
            "rule": {
              "buck": "wisdom",
              "time_of_value": "any_value",
              "data_type": "old_recorded_data",
              "operators": [
                "=",
                "<>"
              ],
              "val_list": [],
              "record_type": "many",
              "data_types": [
                "requesting_data",
                "old_recorded_data"
              ],
              "significant": "true",
              "requesting_operation": "wisdom_data_save",
              "date": "1",
              "type": {
                "translation": {
                  "en": "İstatistik",
                  "tr": "İstatistik"
                },
                "value": "wdm72",
                "label": "İstatistik"
              },
              "op": "="
            },
            "type": "select",
            "options": [
              {
                "translation": {
                  "en": "standart mod",
                  "tr": "standart mod"
                },
                "value": "standard_mode",
                "label": "standart mod"
              },
              {
                "wdm16_id": "3581",
                "translation": {
                  "en": "minimum değer",
                  "tr": "minimum değer"
                },
                "value": "min",
                "label": "minimum değer"
              },
              {
                "wdm16_id": "3582",
                "translation": {
                  "en": "maksimum değer",
                  "tr": "maksimum değer"
                },
                "value": "max",
                "label": "maksimum değer"
              },
              {
                "wdm16_id": "3583",
                "translation": {
                  "en": "tarihi en eski olan",
                  "tr": "tarihi en eski olan"
                },
                "value": "date_min",
                "label": "tarihi en eski olan"
              },
              {
                "wdm16_id": "3576",
                "translation": {
                  "en": "tarihi en yeni olan",
                  "tr": "tarihi en yeni olan"
                },
                "value": "date_max",
                "label": "tarihi en yeni olan"
              },
              {
                "wdm16_id": "3524",
                "translation": {
                  "en": "ortalama",
                  "tr": "ortalama"
                },
                "value": "mean",
                "label": "ortalama"
              },
              {
                "wdm16_id": "3525",
                "translation": {
                  "en": "ortanca (medyan)",
                  "tr": "ortanca (medyan)"
                },
                "value": "median",
                "label": "ortanca (medyan)"
              }
            ]
          },
          "before_after_statistical_test": {
            "name": {
              "wdm16_id": "3587",
              "translation": {
                "en": "Analiz Modeli",
                "tr": "Analiz Modeli"
              },
              "value": "before_after_statistical_test",
              "label": "Analiz Modeli"
            },
            "show": "yes",
            "object_type": "object",
            "rule": {
              "buck": "wisdom",
              "time_of_value": "any_value",
              "data_type": "old_recorded_data",
              "operators": [
                "=",
                "<>"
              ],
              "val_list": [],
              "record_type": "many",
              "data_types": [
                "requesting_data",
                "old_recorded_data"
              ],
              "significant": "true",
              "requesting_operation": "wisdom_data_save",
              "date": "1",
              "type": {
                "translation": {
                  "en": "İstatistik",
                  "tr": "İstatistik"
                },
                "value": "wdm72",
                "label": "İstatistik"
              },
              "op": "="
            },
            "object_param": {
              "translation": {
                "en": "Seçilen Analiz Modeli",
                "tr": "Seçilen Analiz Modeli"
              },
              "value": "selected_before_after_statistical_test",
              "label": "Seçilen Analiz Modeli"
            },
            "type": "object_list",
            "options": [
              {
                "wdm16_id": "3588",
                "translation": {
                  "en": "bağımlı gruplar t testi (paired samples)",
                  "tr": "bağımlı gruplar t testi (paired samples)"
                },
                "value": "ttest_relation_paired_samples",
                "label": "bağımlı gruplar t testi (paired samples)"
              }
            ]
          },
          "selected_before_after_statistical_test": {
            "mother_param": "before_after_statistical_test",
            "name": {
              "wdm16_id": "3589",
              "translation": {
                "en": "Seçilen Analiz Modeli",
                "tr": "Seçilen Analiz Modeli"
              },
              "value": "selected_before_after_statistical_test",
              "label": "Seçilen Analiz Modeli"
            },
            "parameters": {},
            "show": "yes",
            "object_type": "object",
            "rule": {
              "buck": "wisdom",
              "time_of_value": "any_value",
              "data_type": "old_recorded_data",
              "operators": [],
              "val_list": [],
              "record_type": "many",
              "data_types": [
                "requesting_data",
                "old_recorded_data"
              ],
              "significant": "true",
              "requesting_operation": "wisdom_data_save",
              "date": "1",
              "type": {
                "translation": {
                  "en": "İstatistik",
                  "tr": "İstatistik"
                },
                "value": "wdm72",
                "label": "İstatistik"
              },
              "op": "="
            },
            "required": "yes",
            "type": "object_list_options",
            "param_order": []
          },
          "before_after_timeline_mode_day_interval": {
            "name": {
              "wdm16_id": "3480",
              "translation": {
                "en": "Zaman Çizelgesi Gün Aralığı",
                "tr": "Zaman Çizelgesi Gün Aralığı"
              },
              "value": "before_after_timeline_mode_day_interval",
              "label": "Zaman Çizelgesi Gün Aralığı"
            },
            "show": "no",
            "required": "yes",
            "rule": {
              "buck": "wisdom",
              "time_of_value": "any_value",
              "data_type": "old_recorded_data",
              "operators": [
                "=",
                "<>",
                ">",
                "<",
                ">=",
                "<="
              ],
              "val_list": [],
              "record_type": "many",
              "data_types": [
                "requesting_data",
                "old_recorded_data"
              ],
              "significant": "true",
              "requesting_operation": "wisdom_data_save",
              "date": "1",
              "type": {
                "translation": {
                  "en": "İstatistik",
                  "tr": "İstatistik"
                },
                "value": "wdm72",
                "label": "İstatistik"
              },
              "op": "="
            },
            "disabled": 1,
            "type": "integer",
            "if": {
              "friend": {
                "before_after_timeline_mode": {
                  "operation": {
                    "or_options": [
                      "yes"
                    ]
                  },
                  "parent_param": "yes",
                  "index_list": [
                    "general",
                    "before_after",
                    "before_after_timeline_mode"
                  ],
                  "name": {
                    "translation": {
                      "en": "Zaman Çizelgesi Modu",
                      "tr": "Zaman Çizelgesi Modu"
                    },
                    "value": "before_after_timeline_mode",
                    "label": "Zaman Çizelgesi Modu"
                  }
                }
              }
            }
          },
          "second_column": {
            "name": {
              "wdm16_id": "18299",
              "translation": {
                "en": "İkinci Kolon",
                "tr": "İkinci Kolon"
              },
              "value": "second_column",
              "label": "İkinci Değişken"
            },
            "show": "yes",
            "required": "yes",
            "rule": {
              "buck": "wisdom",
              "time_of_value": "any_value",
              "data_type": "old_recorded_data",
              "operators": [
                "=",
                "<>"
              ],
              "val_list": [],
              "record_type": "many",
              "data_types": [
                "requesting_data",
                "old_recorded_data"
              ],
              "significant": "true",
              "requesting_operation": "wisdom_data_save",
              "date": "1",
              "type": {
                "translation": {
                  "en": "İstatistik",
                  "tr": "İstatistik"
                },
                "value": "wdm72",
                "label": "İstatistik"
              },
              "op": "="
            },
            "type": "select",
            "options": []
          },
          "second_column_value_selection": {
            "name": {
              "wdm16_id": "18300",
              "translation": {
                "en": "İkinci Kolon Değer Seçimi",
                "tr": "İkinci Kolon Değer Seçimi"
              },
              "value": "second_column_value_selection",
              "label": "İkinci Değişken Değer Seçimi"
            },
            "show": "yes",
            "required": "yes",
            "rule": {
              "buck": "wisdom",
              "time_of_value": "any_value",
              "data_type": "old_recorded_data",
              "operators": [
                "=",
                "<>"
              ],
              "val_list": [],
              "record_type": "many",
              "data_types": [
                "requesting_data",
                "old_recorded_data"
              ],
              "significant": "true",
              "requesting_operation": "wisdom_data_save",
              "date": "1",
              "type": {
                "translation": {
                  "en": "İstatistik",
                  "tr": "İstatistik"
                },
                "value": "wdm72",
                "label": "İstatistik"
              },
              "op": "="
            },
            "type": "select",
            "options": [
              {
                "translation": {
                  "en": "standart mod",
                  "tr": "standart mod"
                },
                "value": "standard_mode",
                "label": "standart mod"
              },
              {
                "wdm16_id": "3581",
                "translation": {
                  "en": "minimum değer",
                  "tr": "minimum değer"
                },
                "value": "min",
                "label": "minimum değer"
              },
              {
                "wdm16_id": "3582",
                "translation": {
                  "en": "maksimum değer",
                  "tr": "maksimum değer"
                },
                "value": "max",
                "label": "maksimum değer"
              },
              {
                "wdm16_id": "3583",
                "translation": {
                  "en": "tarihi en eski olan",
                  "tr": "tarihi en eski olan"
                },
                "value": "date_min",
                "label": "tarihi en eski olan"
              },
              {
                "wdm16_id": "3576",
                "translation": {
                  "en": "tarihi en yeni olan",
                  "tr": "tarihi en yeni olan"
                },
                "value": "date_max",
                "label": "tarihi en yeni olan"
              },
              {
                "wdm16_id": "3524",
                "translation": {
                  "en": "ortalama",
                  "tr": "ortalama"
                },
                "value": "mean",
                "label": "ortalama"
              },
              {
                "wdm16_id": "3525",
                "translation": {
                  "en": "ortanca (medyan)",
                  "tr": "ortanca (medyan)"
                },
                "value": "median",
                "label": "ortanca (medyan)"
              }
            ]
          },
          "first_column": {
            "object_type": "object",
            "name": {
              "wdm16_id": "18297",
              "translation": {
                "en": "Birinci Kolon",
                "tr": "Birinci Kolon"
              },
              "value": "first_column",
              "label": "Birinci Değişken"
            },
            "show": "yes",
            "required": "yes",
            "rule": {
              "buck": "wisdom",
              "time_of_value": "any_value",
              "data_type": "old_recorded_data",
              "operators": [
                "=",
                "<>"
              ],
              "val_list": [],
              "record_type": "many",
              "data_types": [
                "requesting_data",
                "old_recorded_data"
              ],
              "significant": "true",
              "requesting_operation": "wisdom_data_save",
              "date": "1",
              "type": {
                "translation": {
                  "en": "İstatistik",
                  "tr": "İstatistik"
                },
                "value": "wdm72",
                "label": "İstatistik"
              },
              "op": "="
            },
            "type": "select"
          }
        },
        "show": "yes",
        "object_type": "object",
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "type": "list_parameter",
        "param_order": [
          "first_column",
          "first_column_value_selection",
          "second_column",
          "second_column_value_selection",
          "before_after_timeline_mode",
          "before_after_timeline_mode_day_interval",
          "before_after_will_free_days_be_defined",
          "before_after_statistical_test",
          "selected_before_after_statistical_test"
        ],
        "if": {
          "friend": {
            "analyze_type": {
              "operation": {
                "or_options": [
                  "before_after_comparison"
                ]
              },
              "parent_param": "yes",
              "index_list": [
                "general",
                "analyze_type"
              ],
              "name": {
                "translation": {
                  "en": "Analiz Tipi",
                  "tr": "Analiz Tipi"
                },
                "value": "analyze_type",
                "label": "Analiz Tipi"
              }
            }
          }
        }
      },
      "selected_categorical_to_categorical": {
        "object_type": "object",
        "name": {
          "wdm16_id": "3545",
          "translation": {
            "en": "Seçilen Kategorik & Kategorik Testler",
            "tr": "Seçilen Kategorik & Kategorik Testler"
          },
          "value": "selected_categorical_to_categorical",
          "label": "Seçilen Kategorik & Kategorik  İstatistik Testi"
        },
        "parameters": {},
        "show": "yes",
        "mother_param": "categorical_to_categorical",
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "required": "yes",
        "naming": "1",
        "type": "object_list_options",
        "param_order": [],
        "if": {
          "friend_type": "all_friend_match",
          "friend": {
            "usage_of_comparison_tests": {
              "operation": {
                "or_options": [
                  "yes"
                ]
              },
              "parent_param": "yes",
              "name": {
                "wdm16_id": "3538",
                "translation": {
                  "en": "Karşılaştırmalı İstatistiksel Testler Kullanımı",
                  "tr": "Karşılaştırmalı İstatistiksel Testler Kullanımı"
                },
                "value": "usage_of_comparison_tests",
                "label": "Karşılaştırmalı İstatistiksel Testler Kullanımı"
              },
              "index_list": [
                "general",
                "usage_of_comparison_tests"
              ]
            }
          }
        }
      },
      "nominal_to_nominal": {
        "name": {
          "wdm16_id": "3546",
          "translation": {
            "en": "Sayısal & Sayısal",
            "tr": "Sayısal & Sayısal"
          },
          "value": "nominal_to_nominal",
          "label": "Sayısal & Sayısal İstatistik Testi"
        },
        "show": "yes",
        "object_type": "object",
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [
            "=",
            "<>"
          ],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "object_param": {
          "translation": {
            "en": "Seçilen sayısal & sayısal testler",
            "tr": "Seçilen sayısal & sayısal testler"
          },
          "value": "selected_nominal_to_nominal",
          "label": "Seçilen sayısal & sayısal testler"
        },
        "selectbox_type": "listbox",
        "type": "object_list",
        "options": [
          {
            "wdm16_id": "3548",
            "translation": {
              "en": "pearson korelasyon",
              "tr": "pearson korelasyon"
            },
            "value": "pearson_correlation",
            "label": "pearson korelasyon"
          },
          {
            "translation": {
              "en": "spearman",
              "tr": "spearman"
            },
            "value": "spearman",
            "label": "spearman"
          }
        ],
        "if": {
          "friend_type": "all_friend_match",
          "friend": {
            "usage_of_comparison_tests": {
              "operation": {
                "or_options": [
                  "yes"
                ]
              },
              "parent_param": "yes",
              "name": {
                "wdm16_id": "3538",
                "translation": {
                  "en": "Karşılaştırmalı İstatistiksel Testler Kullanımı",
                  "tr": "Karşılaştırmalı İstatistiksel Testler Kullanımı"
                },
                "value": "usage_of_comparison_tests",
                "label": "Karşılaştırmalı İstatistiksel Testler Kullanımı"
              },
              "index_list": [
                "general",
                "usage_of_comparison_tests"
              ]
            }
          }
        }
      },
      "descriptive_stats": {
        "mother_param": "descriptive_stats_list",
        "name": {
          "wdm16_id": "3537",
          "translation": {
            "en": "Tanımlayıcı Testler",
            "tr": "Tanımlayıcı Testler"
          },
          "value": "descriptive_stats",
          "label": "Tanımlayıcı Testler"
        },
        "parameters": {},
        "show": "yes",
        "object_type": "object",
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "required": "yes",
        "type": "object_list_options",
        "param_order": [],
        "if": {
          "friend": {
            "analyze_type": {
              "operation": {
                "or_options": [
                  "detailed_descriptive_stats"
                ]
              },
              "parent_param": "yes",
              "index_list": [
                "general",
                "analyze_type"
              ],
              "name": {
                "translation": {
                  "en": "Analiz Tipi",
                  "tr": "Analiz Tipi"
                },
                "value": "analyze_type",
                "label": "Analiz Tipi"
              }
            }
          }
        }
      },
      "multi_dimension_analyze": {
        "show": "yes",
        "type": "select",
        "options": [
          {
            "translation": {
              "en": "evet",
              "tr": "evet"
            },
            "value": "yes",
            "label": "evet"
          },
          {
            "translation": {
              "en": "hayır",
              "tr": "hayır"
            },
            "value": "no",
            "label": "hayır"
          }
        ],
        "name": {
          "translation": {
            "en": "Çoklu Boyutlu Karşılaştırma",
            "tr": "Çoklu Boyutlu Karşılaştırma"
          },
          "value": "multi_dimension_analyze",
          "label": "Çok Boyutlu Karşılaştırma"
        },
        "if": {
          "friend": {
            "cross_column_type": {
              "operation": {
                "or_options": [
                  "categorical_categorical",
                  "categorical_numeric",
                  "categorical_and_or_numeric_multi_categorical",
                  "categorical_and_or_numeric_multi_numeric",
                  "categorical_and_categorical_or_numeric"
                ]
              },
              "parent_param": "yes",
              "index_list": [
                "general",
                "cross_column_type"
              ],
              "name": {
                "wdm16_id": "18284",
                "translation": {
                  "en": "Karşılaştırma şekli",
                  "tr": "Karşılaştırma şekli"
                },
                "value": "cross_column_type",
                "label": "Karşılaştırma Tipi"
              }
            }
          }
        }
      },
      "selected_nominal_to_nominal": {
        "object_type": "object",
        "name": {
          "wdm16_id": "3550",
          "translation": {
            "en": "Seçilen sayısal & sayısal testler",
            "tr": "Seçilen sayısal & sayısal testler"
          },
          "value": "selected_nominal_to_nominal",
          "label": "Seçilen sayısal & sayısal İstatistik Testi"
        },
        "parameters": {
          "numeric_numeric_distribution_shapiro_wilk": {
            "show": "yes",
            "type": "select",
            "options": [
              {
                "value": "yes",
                "label": "evet"
              },
              {
                "value": "no",
                "label": "hayır"
              }
            ],
            "name": {
              "translation": {
                "en": "Shapiro Wilk Veri Dağılımında Kullanılsın mı ?",
                "tr": "Shapiro Wilk Veri Dağılımında Kullanılsın mı ?"
              },
              "value": "numeric_numeric_distribution_shapiro_wilk",
              "label": "Shapiro Wilk Veri Dağılımında Kullanılsın mı ?"
            },
            "if": {
              "friend_type": "all_friend_match",
              "friend": {
                "numeric_numeric_control_type_before_test": {
                  "operation": {
                    "or_options": [
                      "only_total_samples_data_distribution",
                      "both_total_sample_size_and_data_distribution",
                      "total_sample_size_or_data_distribution"
                    ]
                  },
                  "parent_param": "yes",
                  "name": {
                    "translation": {
                      "en": "Test Öncesi Kontrol Tipi",
                      "tr": "Test Öncesi Kontrol Tipi"
                    },
                    "value": "numeric_numeric_control_type_before_test",
                    "label": "Test Öncesi Kontrol Tipi"
                  },
                  "index_list": [
                    "general",
                    "selected_nominal_to_nominal",
                    "numeric_numeric_control_type_before_test"
                  ]
                }
              }
            }
          },
          "numeric_numeric_total_sample_size_control_type": {
            "if": {
              "friend_type": "any_one_of_friend_match",
              "friend": {
                "numeric_numeric_control_type_before_test": {
                  "operation": {
                    "or_options": [
                      "only_total_sample_size",
                      "both_total_sample_size_and_data_distribution",
                      "total_sample_size_or_data_distribution"
                    ]
                  },
                  "parent_param": "yes",
                  "name": {
                    "translation": {
                      "en": "Test Öncesi Kontrol Tipi",
                      "tr": "Test Öncesi Kontrol Tipi"
                    },
                    "value": "numeric_numeric_control_type_before_test",
                    "label": "Test Öncesi Kontrol Tipi"
                  },
                  "index_list": [
                    "general",
                    "selected_nominal_to_nominal",
                    "numeric_numeric_control_type_before_test"
                  ]
                }
              }
            },
            "type": "select",
            "options": [
              {
                "value": "smaller",
                "label": "küçüktür"
              },
              {
                "value": "smaller_and_equal",
                "label": "küçük eşittir"
              },
              {
                "value": "bigger",
                "label": "büyüktür"
              },
              {
                "value": "bigger_and_equal",
                "label": "büyük eşittir"
              }
            ],
            "name": {
              "translation": {
                "en": "Toplam Örnek Sayısı Kontrol Tipi",
                "tr": "Toplam Örnek Sayısı Kontrol Tipi"
              },
              "value": "numeric_numeric_total_sample_size_control_type",
              "label": "Toplam Örnek Sayısı Kontrol Tipi"
            },
            "show": "yes"
          },
          "numeric_numeric_distribution_test_list_usage_type": {
            "show": "yes",
            "type": "select",
            "options": [
              {
                "value": "one_of_them",
                "label": "herhangi bir test ile normal dağılıyorsa yeterli"
              },
              {
                "value": "all_of_them",
                "label": "tüm seçili testler normal dağılıyorsa kullan"
              },
              {
                "value": "all_of_them_not_normally_distributed",
                "label": "tüm seçili testler normal dağılmıyorsa kullan"
              }
            ],
            "name": {
              "translation": {
                "en": "Veri Dağılım Testleri Kullanım Şekli",
                "tr": "Veri Dağılım Testleri Kullanım Şekli"
              },
              "value": "numeric_numeric_distribution_test_list_usage_type",
              "label": "Veri Dağılım Testleri Kullanım Şekli"
            },
            "if": {
              "friend_type": "all_friend_match",
              "friend": {
                "numeric_numeric_control_type_before_test": {
                  "operation": {
                    "or_options": [
                      "only_total_samples_data_distribution",
                      "both_total_sample_size_and_data_distribution",
                      "total_sample_size_or_data_distribution"
                    ]
                  },
                  "parent_param": "yes",
                  "name": {
                    "translation": {
                      "en": "Test Öncesi Kontrol Tipi",
                      "tr": "Test Öncesi Kontrol Tipi"
                    },
                    "value": "numeric_numeric_control_type_before_test",
                    "label": "Test Öncesi Kontrol Tipi"
                  },
                  "index_list": [
                    "general",
                    "selected_nominal_to_nominal",
                    "numeric_numeric_control_type_before_test"
                  ]
                }
              }
            }
          },
          "numeric_numeric_distribution_skewness": {
            "show": "yes",
            "type": "select",
            "options": [
              {
                "value": "yes",
                "label": "evet"
              },
              {
                "value": "no",
                "label": "hayır"
              }
            ],
            "name": {
              "translation": {
                "en": "Skewness (çarpıklık) Veri Dağılımında Kullanılsın mı?",
                "tr": "Skewness (çarpıklık) Veri Dağılımında Kullanılsın mı?"
              },
              "value": "numeric_numeric_distribution_skewness",
              "label": "Skewness (çarpıklık) Veri Dağılımında Kullanılsın mı?"
            },
            "if": {
              "friend_type": "all_friend_match",
              "friend": {
                "numeric_numeric_control_type_before_test": {
                  "operation": {
                    "or_options": [
                      "only_total_samples_data_distribution",
                      "both_total_sample_size_and_data_distribution",
                      "total_sample_size_or_data_distribution"
                    ]
                  },
                  "parent_param": "yes",
                  "name": {
                    "translation": {
                      "en": "Test Öncesi Kontrol Tipi",
                      "tr": "Test Öncesi Kontrol Tipi"
                    },
                    "value": "numeric_numeric_control_type_before_test",
                    "label": "Test Öncesi Kontrol Tipi"
                  },
                  "index_list": [
                    "general",
                    "selected_nominal_to_nominal",
                    "numeric_numeric_control_type_before_test"
                  ]
                }
              }
            }
          },
          "numeric_numeric_distribution_kurtosis_range": {
            "show": "yes",
            "type": "float",
            "name": {
              "translation": {
                "en": "Kurtosis Aralığı",
                "tr": "Kurtosis Aralığı"
              },
              "value": "numeric_numeric_distribution_kurtosis_range",
              "label": "Kurtosis Aralığı"
            },
            "if": {
              "friend_type": "all_friend_match",
              "friend": {
                "numeric_numeric_distribution_kurtosis": {
                  "operation": {
                    "or_options": [
                      "yes"
                    ]
                  },
                  "parent_param": "yes",
                  "name": {
                    "translation": {
                      "en": "Kurtosis (basıklık) Veri Dağılımında Kullanılsın mı ?",
                      "tr": "Kurtosis (basıklık) Veri Dağılımında Kullanılsın mı ?"
                    },
                    "value": "numeric_numeric_distribution_kurtosis",
                    "label": "Kurtosis (basıklık) Veri Dağılımında Kullanılsın mı ?"
                  },
                  "index_list": [
                    "general",
                    "selected_nominal_to_nominal",
                    "numeric_numeric_distribution_kurtosis"
                  ]
                }
              }
            }
          },
          "numeric_numeric_control_type_before_test": {
            "type": "select",
            "options": [
              {
                "value": "not_control",
                "label": "kontrol yapılmasın"
              },
              {
                "value": "only_total_sample_size",
                "label": "sadece toplam örnek sayısı kontrol edilsin"
              },
              {
                "value": "only_total_samples_data_distribution",
                "label": "sadece tüm örneklere ait veri dağılımı"
              },
              {
                "value": "both_total_sample_size_and_data_distribution",
                "label": "tüm örneklere ait hem örnek sayısı hem de veri dağılımı kontrol edilsin"
              },
              {
                "value": "total_sample_size_or_data_distribution",
                "label": "tüm örneklere ait örnek sayısı veya veri dağılımı kontrol edilsin"
              }
            ],
            "name": {
              "translation": {
                "en": "Test Öncesi Kontrol Tipi",
                "tr": "Test Öncesi Kontrol Tipi"
              },
              "value": "numeric_numeric_control_type_before_test",
              "label": "Test Öncesi Kontrol Tipi"
            },
            "show": "yes"
          },
          "numeric_numeric_total_sample_size_value": {
            "show": "yes",
            "type": "integer",
            "name": {
              "translation": {
                "en": "Toplam Örnek Sayısı Değeri",
                "tr": "Toplam Örnek Sayısı Değeri"
              },
              "value": "numeric_numeric_total_sample_size_value",
              "label": "Toplam Örnek Sayısı Değeri"
            },
            "if": {
              "friend_type": "all_friend_match",
              "friend": {
                "numeric_numeric_control_type_before_test": {
                  "operation": {
                    "or_options": [
                      "only_total_sample_size",
                      "both_total_sample_size_and_data_distribution",
                      "total_sample_size_or_data_distribution"
                    ]
                  },
                  "parent_param": "yes",
                  "name": {
                    "translation": {
                      "en": "Test Öncesi Kontrol Tipi",
                      "tr": "Test Öncesi Kontrol Tipi"
                    },
                    "value": "numeric_numeric_control_type_before_test",
                    "label": "Test Öncesi Kontrol Tipi"
                  },
                  "index_list": [
                    "general",
                    "selected_nominal_to_nominal",
                    "numeric_numeric_control_type_before_test"
                  ]
                },
                "numeric_numeric_total_sample_size_control_type": {
                  "operation": {
                    "or_options": [
                      "smaller",
                      "smaller_and_equal",
                      "bigger",
                      "bigger_and_equal"
                    ]
                  },
                  "parent_param": "yes",
                  "name": {
                    "translation": {
                      "en": "Toplam Örnek Sayısı Kontrol Tipi",
                      "tr": "Toplam Örnek Sayısı Kontrol Tipi"
                    },
                    "value": "numeric_numeric_total_sample_size_control_type",
                    "label": "Toplam Örnek Sayısı Kontrol Tipi"
                  },
                  "index_list": [
                    "general",
                    "selected_nominal_to_nominal",
                    "numeric_numeric_total_sample_size_control_type"
                  ]
                }
              }
            }
          },
          "numeric_numeric_distribution_skewness_range": {
            "show": "yes",
            "type": "float",
            "name": {
              "translation": {
                "en": "Skewness Aralığı",
                "tr": "Skewness Aralığı"
              },
              "value": "numeric_numeric_distribution_skewness_range",
              "label": "Skewness Aralığı"
            },
            "if": {
              "friend_type": "all_friend_match",
              "friend": {
                "numeric_numeric_distribution_skewness": {
                  "operation": {
                    "or_options": [
                      "yes"
                    ]
                  },
                  "parent_param": "yes",
                  "name": {
                    "translation": {
                      "en": "Skewness (çarpıklık) Veri Dağılımında Kullanılsın mı?",
                      "tr": "Skewness (çarpıklık) Veri Dağılımında Kullanılsın mı?"
                    },
                    "value": "numeric_numeric_distribution_skewness",
                    "label": "Skewness (çarpıklık) Veri Dağılımında Kullanılsın mı?"
                  },
                  "index_list": [
                    "general",
                    "selected_nominal_to_nominal",
                    "numeric_numeric_distribution_skewness"
                  ]
                }
              }
            }
          },
          "numeric_numeric_distribution_kolmogorov_smirnov": {
            "show": "yes",
            "type": "select",
            "options": [
              {
                "value": "yes",
                "label": "evet"
              },
              {
                "value": "no",
                "label": "hayır"
              }
            ],
            "name": {
              "translation": {
                "en": "Kolmogorov Smirnov Veri Dağılımında Kullanılsın mı ?",
                "tr": "Kolmogorov Smirnov Veri Dağılımında Kullanılsın mı ?"
              },
              "value": "numeric_numeric_distribution_kolmogorov_smirnov",
              "label": "Kolmogorov Smirnov Veri Dağılımında Kullanılsın mı ?"
            },
            "if": {
              "friend_type": "all_friend_match",
              "friend": {
                "numeric_numeric_control_type_before_test": {
                  "operation": {
                    "or_options": [
                      "only_total_samples_data_distribution",
                      "both_total_sample_size_and_data_distribution",
                      "total_sample_size_or_data_distribution"
                    ]
                  },
                  "parent_param": "yes",
                  "name": {
                    "translation": {
                      "en": "Test Öncesi Kontrol Tipi",
                      "tr": "Test Öncesi Kontrol Tipi"
                    },
                    "value": "numeric_numeric_control_type_before_test",
                    "label": "Test Öncesi Kontrol Tipi"
                  },
                  "index_list": [
                    "general",
                    "selected_nominal_to_nominal",
                    "numeric_numeric_control_type_before_test"
                  ]
                }
              }
            }
          },
          "numeric_numeric_distribution_kurtosis": {
            "show": "yes",
            "type": "select",
            "options": [
              {
                "value": "yes",
                "label": "evet"
              },
              {
                "value": "no",
                "label": "hayır"
              }
            ],
            "name": {
              "translation": {
                "en": "Kurtosis (basıklık) Veri Dağılımında Kullanılsın mı ?",
                "tr": "Kurtosis (basıklık) Veri Dağılımında Kullanılsın mı ?"
              },
              "value": "numeric_numeric_distribution_kurtosis",
              "label": "Kurtosis (basıklık) Veri Dağılımında Kullanılsın mı ?"
            },
            "if": {
              "friend_type": "all_friend_match",
              "friend": {
                "numeric_numeric_control_type_before_test": {
                  "operation": {
                    "or_options": [
                      "only_total_samples_data_distribution",
                      "both_total_sample_size_and_data_distribution",
                      "total_sample_size_or_data_distribution"
                    ]
                  },
                  "parent_param": "yes",
                  "name": {
                    "translation": {
                      "en": "Test Öncesi Kontrol Tipi",
                      "tr": "Test Öncesi Kontrol Tipi"
                    },
                    "value": "numeric_numeric_control_type_before_test",
                    "label": "Test Öncesi Kontrol Tipi"
                  },
                  "index_list": [
                    "general",
                    "selected_nominal_to_nominal",
                    "numeric_numeric_control_type_before_test"
                  ]
                }
              }
            }
          }
        },
        "show": "yes",
        "mother_param": "nominal_to_nominal",
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "required": "yes",
        "naming": "1",
        "type": "object_list_options",
        "param_order": [
          "numeric_numeric_control_type_before_test",
          "numeric_numeric_total_sample_size_control_type",
          "numeric_numeric_total_sample_size_value",
          "numeric_numeric_distribution_test_list_usage_type",
          "numeric_numeric_distribution_skewness",
          "numeric_numeric_distribution_skewness_range",
          "numeric_numeric_distribution_kurtosis",
          "numeric_numeric_distribution_kurtosis_range",
          "numeric_numeric_distribution_shapiro_wilk",
          "numeric_numeric_distribution_kolmogorov_smirnov"
        ],
        "if": {
          "friend_type": "all_friend_match",
          "friend": {
            "usage_of_comparison_tests": {
              "operation": {
                "or_options": [
                  "yes"
                ]
              },
              "parent_param": "yes",
              "name": {
                "wdm16_id": "3538",
                "translation": {
                  "en": "Karşılaştırmalı İstatistiksel Testler Kullanımı",
                  "tr": "Karşılaştırmalı İstatistiksel Testler Kullanımı"
                },
                "value": "usage_of_comparison_tests",
                "label": "Karşılaştırmalı İstatistiksel Testler Kullanımı"
              },
              "index_list": [
                "general",
                "usage_of_comparison_tests"
              ]
            }
          }
        }
      },
      "column_parameter_list": {
        "name": {
          "wdm16_id": "18295",
          "translation": {
            "en": "Kolon Parametre Kolonları",
            "tr": "Kolon Parametre Kolonları"
          },
          "value": "column_parameter_list",
          "label": "Bağımlı değişkenler"
        },
        "show": "yes",
        "object_type": "object",
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [
            "=",
            "<>"
          ],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "object_param": {
          "translation": {
            "en": "Seçili Kolon Parametreleri",
            "tr": "Seçili Kolon Parametreleri"
          },
          "value": "selected_column_parameters",
          "label": "Seçili Kolon Parametreleri"
        },
        "selectbox_type": "listbox",
        "type": "object_list",
        "if": {
          "friend_type": "all_friend_match",
          "friend": {
            "cross_column_type": {
              "operation": {
                "or_options": [
                  "categorical_categorical",
                  "categorical_numeric",
                  "numeric_numeric",
                  "numeric_categorical",
                  "categorical_and_or_numeric_multi_categorical",
                  "categorical_and_or_numeric_multi_numeric",
                  "categorical_and_categorical_or_numeric"
                ]
              },
              "parent_param": "yes",
              "name": {
                "wdm16_id": "18284",
                "translation": {
                  "en": "Karşılaştırma şekli",
                  "tr": "Karşılaştırma şekli"
                },
                "value": "cross_column_type",
                "label": "Karşılaştırma Tipi"
              },
              "index_list": [
                "general",
                "cross_column_type"
              ]
            }
          }
        }
      },
      "are_cases_filtered": {
        "info": "Projeye algoritmik bir filtre oluşturulduğunda bu parametre evet olarak seçili olacaktır. Tüm vaka (örnek, satır) için analiz uygulandığında hayır olarak otomatik olarak seçili olacaktır.",
        "name": {
          "wdm16_id": "3434",
          "translation": {
            "en": "Filtre Uygulandı mı?",
            "tr": "Filtre Uygulandı mı?"
          },
          "value": "are_cases_filtered",
          "label": "Filtre Algoritması Uygulandı mı?"
        },
        "show": "no",
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [
            "=",
            "<>"
          ],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "disabled": 1,
        "type": "select",
        "options": [
          {
            "wdm16_id": "809",
            "translation": {
              "en": "evet",
              "tr": "evet"
            },
            "value": "yes",
            "label": "evet"
          },
          {
            "wdm16_id": "1327",
            "translation": {
              "en": "hayır",
              "tr": "hayır"
            },
            "value": "no",
            "label": "hayır"
          }
        ]
      },
      "case_count_analyze_applied": {
        "info": "İstatistiksel model analizi Wanalyzer projesinde oluşturulan bir algoritmik filtreyle uygulandığında bu sayı toplam örnek sayısından az olacaktır. Herhangi bir filtre uygulanmadıysa toplam örnek sayısı kadar örneğe analiz uygulanmış olacaktır.",
        "name": {
          "wdm16_id": "3433",
          "translation": {
            "en": "Analiz Uygulanan Örnek Sayısı",
            "tr": "Analiz Uygulanan Örnek Sayısı"
          },
          "value": "case_count_analyze_applied",
          "label": "Analiz Uygulanan Satır Sayısı"
        },
        "show": "no",
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [
            "=",
            "<>",
            ">",
            "<",
            ">=",
            "<="
          ],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "disabled": 1,
        "type": "integer"
      },
      "descriptive_stats_list": {
        "name": {
          "wdm16_id": "3523",
          "translation": {
            "en": "Tanımlayıcı Test Listesi",
            "tr": "Tanımlayıcı Test Listesi"
          },
          "value": "descriptive_stats_list",
          "label": "Tanımlayıcı Test Listesi"
        },
        "show": "yes",
        "object_type": "object",
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [
            "=",
            "<>"
          ],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "object_param": {
          "translation": {
            "en": "Tanımlayıcı Testler",
            "tr": "Tanımlayıcı Testler"
          },
          "value": "descriptive_stats",
          "label": "Tanımlayıcı Testler"
        },
        "type": "object_list",
        "options": [
          {
            "wdm16_id": "3524",
            "translation": {
              "en": "ortalama",
              "tr": "ortalama"
            },
            "value": "mean",
            "label": "ortalama"
          },
          {
            "wdm16_id": "3525",
            "translation": {
              "en": "ortanca (medyan)",
              "tr": "ortanca (medyan)"
            },
            "value": "median",
            "label": "ortanca (medyan)"
          },
          {
            "wdm16_id": "3526",
            "translation": {
              "en": "standart sapma",
              "tr": "standart sapma"
            },
            "value": "standart_deviation",
            "label": "standart sapma"
          },
          {
            "wdm16_id": "3527",
            "translation": {
              "en": "varyans",
              "tr": "varyans"
            },
            "value": "variance",
            "label": "varyans"
          },
          {
            "wdm16_id": "3515",
            "translation": {
              "en": "minimum",
              "tr": "minimum"
            },
            "value": "min",
            "label": "minimum"
          },
          {
            "wdm16_id": "3516",
            "translation": {
              "en": "maksimum",
              "tr": "maksimum"
            },
            "value": "max",
            "label": "maksimum"
          },
          {
            "wdm16_id": "3532",
            "translation": {
              "en": "veriler toplamı",
              "tr": "veriler toplamı"
            },
            "value": "sum",
            "label": "veriler toplamı"
          },
          {
            "wdm16_id": "4536",
            "translation": {
              "en": "sıklık",
              "tr": "sıklık"
            },
            "value": "frequency",
            "label": "sıklık"
          },
          {
            "wdm16_id": "3531",
            "translation": {
              "en": "veri dağılımı (eğim, skewness)",
              "tr": "veri dağılımı (eğim, skewness)"
            },
            "value": "skewness",
            "label": "veri dağılımı (eğim, skewness)"
          },
          {
            "wdm16_id": "3530",
            "translation": {
              "en": "veri dağılımı (basıklık, kurtosis)",
              "tr": "veri dağılımı (basıklık, kurtosis)"
            },
            "value": "kurtosis",
            "label": "veri dağılımı (basıklık, kurtosis)"
          },
          {
            "wdm16_id": "3534",
            "translation": {
              "en": "aykırı değer saptama (outliers) z-index",
              "tr": "aykırı değer saptama (outliers) z-index"
            },
            "value": "outliers_zindex",
            "label": "aykırı değer saptama (outliers) z-index"
          },
          {
            "wdm16_id": "3535",
            "translation": {
              "en": "aykırı değer saptama (outliers, interquartile range)",
              "tr": "aykırı değer saptama (outliers, interquartile range)"
            },
            "value": "outliers_interquartile_range",
            "label": "aykırı değer saptama (outliers, interquartile range)"
          },
          {
            "wdm16_id": "3536",
            "translation": {
              "en": "veri dağılımı (kolmogorov smirnov) ",
              "tr": "veri dağılımı (kolmogorov smirnov) "
            },
            "value": "kolmogorov_smirnov",
            "label": "normal dağılım kontrolü (kolmogorov smirnov) "
          },
          {
            "translation": {
              "en": "shapiro wilk (normal dağılım kontrolü)",
              "tr": "shapiro wilk (normal dağılım kontrolü)"
            },
            "value": "shapiro_wilk",
            "label": "shapiro wilk (normal dağılım kontrolü)"
          }
        ],
        "if": {
          "friend": {
            "analyze_type": {
              "operation": {
                "or_options": [
                  "detailed_descriptive_stats"
                ]
              },
              "parent_param": "yes",
              "index_list": [
                "general",
                "analyze_type"
              ],
              "name": {
                "translation": {
                  "en": "Analiz Tipi",
                  "tr": "Analiz Tipi"
                },
                "value": "analyze_type",
                "label": "Analiz Tipi"
              }
            }
          }
        }
      },
      "one_or_multi_independent_one_dependent_numeric_selected": {
        "object_type": "object",
        "name": {
          "wdm16_id": "3566",
          "translation": {
            "en": "Tek ya da Çoklu Girdili (Bağımsız Değişken), Tek Hedef (Bağımlı Değişken) Sayısal Seçilen Testler",
            "tr": "Tek ya da Çoklu Girdili (Bağımsız Değişken), Tek Hedef (Bağımlı Değişken) Sayısal Seçilen Testler"
          },
          "value": "one_or_multi_independent_one_dependent_numeric_selected",
          "label": "Bir ya da Çok Bağımsız (kategorik veya sayısal), Bir Bağımlı Değişken (sayısal) seçilenler"
        },
        "parameters": {
          "oomiodn_variable_selection": {
            "required": "yes",
            "name": {
              "wdm16_id": "3559",
              "translation": {
                "en": "Değişken Seçimi",
                "tr": "Değişken Seçimi"
              },
              "value": "oomiodn_variable_selection",
              "label": "Bağımsız Değişken Stratejisi"
            },
            "show": "yes",
            "object_type": "object",
            "rule": {
              "buck": "wisdom",
              "time_of_value": "any_value",
              "data_type": "old_recorded_data",
              "operators": [],
              "val_list": [],
              "record_type": "many",
              "data_types": [
                "requesting_data",
                "old_recorded_data"
              ],
              "significant": "true",
              "requesting_operation": "wisdom_data_save",
              "date": "1",
              "type": {
                "translation": {
                  "en": "İstatistik",
                  "tr": "İstatistik"
                },
                "value": "wdm72",
                "label": "İstatistik"
              },
              "op": "="
            },
            "type": "checkbox",
            "options": [
              {
                "wdm16_id": "3560",
                "translation": {
                  "en": "her değişkeni birer birer analiz",
                  "tr": "her değişkeni birer birer analiz"
                },
                "value": "0",
                "label": "her değişkeni birer birer analiz"
              },
              {
                "wdm16_id": "3561",
                "translation": {
                  "en": "tüm değişkenleri hedef değişken ile toplu analiz yap",
                  "tr": "tüm değişkenleri hedef değişken ile toplu analiz yap"
                },
                "value": "1",
                "label": "tüm değişkenleri hedef değişken ile toplu analiz yap"
              },
              {
                "wdm16_id": "3562",
                "translation": {
                  "en": "girdi değişkenlerini tek tek analiz yaptıktan sonra p değeri anlamlı olanları toplu analiz olarak da yap",
                  "tr": "girdi değişkenlerini tek tek analiz yaptıktan sonra p değeri anlamlı olanları toplu analiz olarak da yap"
                },
                "value": "2",
                "label": "girdi değişkenlerini tek tek analiz yaptıktan sonra p değeri anlamlı olanları toplu analiz olarak da yap"
              }
            ]
          }
        },
        "show": "yes",
        "mother_param": "one_or_multi_independent_one_dependent_numeric",
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "type": "object_list_options",
        "param_order": [
          "oomiodn_variable_selection"
        ],
        "if": {
          "friend_type": "all_friend_match",
          "friend": {
            "usage_of_comparison_tests": {
              "operation": {
                "or_options": [
                  "yes"
                ]
              },
              "parent_param": "yes",
              "name": {
                "wdm16_id": "3538",
                "translation": {
                  "en": "Karşılaştırmalı İstatistiksel Testler Kullanımı",
                  "tr": "Karşılaştırmalı İstatistiksel Testler Kullanımı"
                },
                "value": "usage_of_comparison_tests",
                "label": "Karşılaştırmalı Test Kullanımı"
              },
              "index_list": [
                "general",
                "usage_of_comparison_tests"
              ]
            },
            "analyze_type": {
              "operation": {
                "or_options": [
                  "cross_columns"
                ]
              },
              "parent_param": "yes",
              "index_list": [
                "general",
                "analyze_type"
              ],
              "name": {
                "translation": {
                  "en": "Analiz Tipi",
                  "tr": "Analiz Tipi"
                },
                "value": "analyze_type",
                "label": "Analiz Tipi"
              }
            }
          }
        }
      },
      "model_info": {
        "info": "Modelin amacını kısaca açıklayan bir açıklama yazılmalıdır.",
        "type": "textarea",
        "name": {
          "wdm16_id": "17814",
          "translation": {
            "en": "Model Açıklaması",
            "tr": "Model Açıklaması"
          },
          "value": "model_info",
          "label": "Model Açıklaması"
        },
        "show": "yes"
      },
      "selected_row_parameters": {
        "object_type": "object",
        "name": {
          "wdm16_id": "18294",
          "translation": {
            "en": "Satır Parametre Kolonları",
            "tr": "Satır Parametre Kolonları"
          },
          "value": "selected_row_parameters",
          "label": "Seçili Bağımsız Değişkenler"
        },
        "parameters": {
          "multi_dimension_order": {
            "if": {
              "friend": {
                "multi_dimension_analyze": {
                  "operation": {
                    "or_options": [
                      "yes"
                    ]
                  },
                  "index_list": [
                    "general",
                    "multi_dimension_analyze"
                  ],
                  "name": {
                    "translation": {
                      "en": "Çoklu Boyutlu Karşılaştırma",
                      "tr": "Çoklu Boyutlu Karşılaştırma"
                    },
                    "value": "multi_dimension_analyze",
                    "label": "Çok Boyutlu Karşılaştırma"
                  }
                }
              }
            },
            "type": "integer",
            "name": {
              "translation": {
                "en": "Çoklu Boyut Sıra",
                "tr": "Çoklu Boyut Sıra"
              },
              "value": "multi_dimension_order",
              "label": "Çoklu Boyut Sıra"
            },
            "show": "yes"
          },
          "row_param_type_usage": {
            "name": {
              "wdm16_id": "17645",
              "translation": {
                "en": "Satır Parametre Tipi Kullanım Şekli",
                "tr": "Satır Parametre Tipi Kullanım Şekli"
              },
              "value": "row_param_type_usage",
              "label": "Veri Tipi Kullanımı"
            },
            "show": "yes",
            "default": {
              "wdm16_id": "3461",
              "translation": {
                "en": "Otomatik",
                "tr": "Otomatik"
              },
              "value": "auto",
              "label": "Otomatik"
            },
            "required": "yes",
            "rule": {
              "buck": "wisdom",
              "time_of_value": "any_value",
              "data_type": "old_recorded_data",
              "operators": [
                "=",
                "<>"
              ],
              "val_list": [],
              "record_type": "many",
              "data_types": [
                "requesting_data",
                "old_recorded_data"
              ],
              "significant": "true",
              "requesting_operation": "wisdom_data_save",
              "date": "1",
              "type": {
                "translation": {
                  "en": "İstatistik",
                  "tr": "İstatistik"
                },
                "value": "wdm72",
                "label": "İstatistik"
              },
              "op": "="
            },
            "type": "select",
            "options": [
              {
                "wdm16_id": "3461",
                "translation": {
                  "en": "Otomatik",
                  "tr": "Otomatik"
                },
                "value": "auto",
                "label": "Otomatik"
              },
              {
                "wdm16_id": "3486",
                "translation": {
                  "en": "Seçilen Parametre Tipinde",
                  "tr": "Seçilen Parametre Tipinde"
                },
                "value": "selected",
                "label": "Seçilen Parametre Tipinde"
              }
            ]
          },
          "row_option_group_type": {
            "info": "Hücrelerde zaten tek değer varsa hem numerik hem de kategorik veriler için standart modun seçili olması yeterli olacaktır. Numerik verilerde birden fazla değer bir hücrede var ise o zaman min, max, mean, gibi bir seçenek seçilmesi gerekmektedir.",
            "required": "yes",
            "name": {
              "wdm16_id": "3467",
              "translation": {
                "en": "Satır Seçenek Grup Şekli",
                "tr": "Satır Seçenek Grup Şekli"
              },
              "value": "row_option_group_type",
              "label": "Hücredeki Veri Listesine Yaklaşım"
            },
            "show": "yes",
            "object_type": "object",
            "rule": {
              "buck": "wisdom",
              "time_of_value": "any_value",
              "data_type": "old_recorded_data",
              "operators": [
                "=",
                "<>"
              ],
              "val_list": [],
              "record_type": "many",
              "data_types": [
                "requesting_data",
                "old_recorded_data"
              ],
              "significant": "true",
              "requesting_operation": "wisdom_data_save",
              "date": "1",
              "type": {
                "translation": {
                  "en": "İstatistik",
                  "tr": "İstatistik"
                },
                "value": "wdm72",
                "label": "İstatistik"
              },
              "op": "="
            },
            "op_if": "1",
            "type": "select",
            "options": [
              {
                "wdm16_id": "3512",
                "translation": {
                  "en": "standart mod",
                  "tr": "standart mod"
                },
                "value": "standard_mode",
                "label": "standart mod (hücre içerisindeki görünüm modunda)"
              },
              {
                "wdm16_id": "3513",
                "translation": {
                  "en": "Her bir seçenek ayrı ayrı",
                  "tr": "Her bir seçenek ayrı ayrı"
                },
                "if": {
                  "friend": {
                    "row_column_param_type": {
                      "operation": {
                        "or_options": [
                          "categorical"
                        ]
                      },
                      "parent_param": "yes",
                      "name": {
                        "wdm16_id": "11370",
                        "translation": {
                          "en": "Satır Parametre Tipi",
                          "tr": "Satır Parametre Tipi"
                        },
                        "value": "row_column_param_type",
                        "label": "Yeni Veri Kullanım Şekli"
                      },
                      "index_list": [
                        "general",
                        "selected_row_parameters",
                        "row_column_param_type"
                      ]
                    }
                  }
                },
                "value": "every_option_itself",
                "label": "Her bir seçenek ayrı ayrı"
              },
              {
                "wdm16_id": "3514",
                "translation": {
                  "en": "tüm detaylı kombinasyonlar",
                  "tr": "tüm detaylı kombinasyonlar"
                },
                "if": {
                  "friend": {
                    "row_column_param_type": {
                      "operation": {
                        "or_options": [
                          "categorical"
                        ]
                      },
                      "parent_param": "yes",
                      "index_list": [
                        "general",
                        "selected_row_parameters",
                        "row_column_param_type"
                      ],
                      "name": {
                        "translation": {
                          "en": "Satır Parametre Tipi",
                          "tr": "Satır Parametre Tipi"
                        },
                        "value": "row_column_param_type",
                        "label": "Satır Parametre Tipi"
                      }
                    }
                  }
                },
                "value": "create_all_combinations",
                "label": "tüm detaylı kombinasyonlar"
              },
              {
                "wdm16_id": "3515",
                "translation": {
                  "en": "min",
                  "tr": "min"
                },
                "label": "minimum",
                "value": "min",
                "if": {
                  "friend": {
                    "row_column_param_type": {
                      "operation": {
                        "or_options": [
                          "numeric"
                        ]
                      },
                      "parent_param": "yes",
                      "name": {
                        "wdm16_id": "11370",
                        "translation": {
                          "en": "Satır Parametre Tipi",
                          "tr": "Satır Parametre Tipi"
                        },
                        "value": "row_column_param_type",
                        "label": "Yeni Veri Kullanım Şekli"
                      },
                      "index_list": [
                        "general",
                        "selected_row_parameters",
                        "row_column_param_type"
                      ]
                    }
                  }
                }
              },
              {
                "wdm16_id": "3516",
                "translation": {
                  "en": "maksimum",
                  "tr": "maksimum"
                },
                "label": "maksimum",
                "value": "max",
                "if": {
                  "friend": {
                    "row_column_param_type": {
                      "operation": {
                        "or_options": [
                          "numeric"
                        ]
                      },
                      "parent_param": "yes",
                      "name": {
                        "wdm16_id": "11370",
                        "translation": {
                          "en": "Satır Parametre Tipi",
                          "tr": "Satır Parametre Tipi"
                        },
                        "value": "row_column_param_type",
                        "label": "Yeni Veri Kullanım Şekli"
                      },
                      "index_list": [
                        "general",
                        "selected_row_parameters",
                        "row_column_param_type"
                      ]
                    }
                  }
                }
              },
              {
                "wdm16_id": "3517",
                "translation": {
                  "en": "tarihi en küçük olan",
                  "tr": "tarihi en küçük olan"
                },
                "value": "date_min",
                "label": "tarihi en küçük olan"
              },
              {
                "wdm16_id": "3518",
                "translation": {
                  "en": "tarihi en büyük olan",
                  "tr": "tarihi en büyük olan"
                },
                "value": "date_max",
                "label": "tarihi en büyük olan"
              },
              {
                "wdm16_id": "3519",
                "translation": {
                  "en": "verilerin ortalaması",
                  "tr": "verilerin ortalaması"
                },
                "label": "verilerin ortalaması",
                "value": "mean",
                "if": {
                  "friend": {
                    "row_column_param_type": {
                      "operation": {
                        "or_options": [
                          "numeric"
                        ]
                      },
                      "parent_param": "yes",
                      "name": {
                        "wdm16_id": "11370",
                        "translation": {
                          "en": "Satır Parametre Tipi",
                          "tr": "Satır Parametre Tipi"
                        },
                        "value": "row_column_param_type",
                        "label": "Yeni Veri Kullanım Şekli"
                      },
                      "index_list": [
                        "general",
                        "selected_row_parameters",
                        "row_column_param_type"
                      ]
                    }
                  }
                }
              },
              {
                "wdm16_id": "3520",
                "translation": {
                  "en": "verilerin ortancası (medyan)",
                  "tr": "verilerin ortancası (medyan)"
                },
                "label": "verilerin ortancası (medyan)",
                "value": "median",
                "if": {
                  "friend": {
                    "row_column_param_type": {
                      "operation": {
                        "or_options": [
                          "numeric"
                        ]
                      },
                      "parent_param": "yes",
                      "name": {
                        "wdm16_id": "11370",
                        "translation": {
                          "en": "Satır Parametre Tipi",
                          "tr": "Satır Parametre Tipi"
                        },
                        "value": "row_column_param_type",
                        "label": "Yeni Veri Kullanım Şekli"
                      },
                      "index_list": [
                        "general",
                        "selected_row_parameters",
                        "row_column_param_type"
                      ]
                    }
                  }
                }
              },
              {
                "wdm16_id": "3521",
                "translation": {
                  "en": "en güncel tarihi al",
                  "tr": "en güncel tarihi al"
                },
                "if": {
                  "friend": {
                    "row_column_param_type": {
                      "operation": {
                        "or_options": [
                          "date"
                        ]
                      },
                      "parent_param": "yes",
                      "index_list": [
                        "general",
                        "selected_row_parameters",
                        "row_column_param_type"
                      ],
                      "name": {
                        "translation": {
                          "en": "Satır Parametre Tipi",
                          "tr": "Satır Parametre Tipi"
                        },
                        "value": "row_column_param_type",
                        "label": "Satır Parametre Tipi"
                      }
                    }
                  }
                },
                "value": "get_max_date",
                "label": "en güncel tarihi al"
              },
              {
                "wdm16_id": "3522",
                "translation": {
                  "en": "en eski tarihi al",
                  "tr": "en eski tarihi al"
                },
                "if": {
                  "friend": {
                    "row_column_param_type": {
                      "operation": {
                        "or_options": [
                          "date"
                        ]
                      },
                      "parent_param": "yes",
                      "index_list": [
                        "general",
                        "selected_row_parameters",
                        "row_column_param_type"
                      ],
                      "name": {
                        "translation": {
                          "en": "Satır Parametre Tipi",
                          "tr": "Satır Parametre Tipi"
                        },
                        "value": "row_column_param_type",
                        "label": "Satır Parametre Tipi"
                      }
                    }
                  }
                },
                "value": "get_min_date",
                "label": "en eski tarihi al"
              }
            ],
            "if": {
              "friend_type": "all_friend_match",
              "friend": {
                "row_column_param_type": {
                  "operation": {},
                  "parent_param": "yes",
                  "name": {
                    "wdm16_id": "11370",
                    "translation": {
                      "en": "Satır Parametre Tipi",
                      "tr": "Satır Parametre Tipi"
                    },
                    "value": "row_column_param_type",
                    "label": "Yeni Veri Kullanım Şekli"
                  },
                  "index_list": [
                    "general",
                    "selected_row_parameters",
                    "row_column_param_type"
                  ]
                }
              }
            }
          },
          "row_column_param_type": {
            "required": "yes",
            "name": {
              "wdm16_id": "11370",
              "translation": {
                "en": "Satır Parametre Tipi",
                "tr": "Satır Parametre Tipi"
              },
              "value": "row_column_param_type",
              "label": "Yeni Veri Kullanım Şekli"
            },
            "show": "yes",
            "object_type": "object",
            "rule": {
              "buck": "wisdom",
              "time_of_value": "any_value",
              "data_type": "old_recorded_data",
              "operators": [
                "=",
                "<>"
              ],
              "val_list": [],
              "record_type": "many",
              "data_types": [
                "requesting_data",
                "old_recorded_data"
              ],
              "significant": "true",
              "requesting_operation": "wisdom_data_save",
              "date": "1",
              "type": {
                "translation": {
                  "en": "İstatistik",
                  "tr": "İstatistik"
                },
                "value": "wdm72",
                "label": "İstatistik"
              },
              "op": "="
            },
            "type": "select",
            "options": [
              {
                "wdm16_id": "3464",
                "translation": {
                  "en": "kategorik",
                  "tr": "kategorik"
                },
                "value": "categorical",
                "label": "kategorik"
              },
              {
                "wdm16_id": "3509",
                "translation": {
                  "en": "numerik",
                  "tr": "numerik"
                },
                "value": "numeric",
                "label": "numerik"
              }
            ],
            "if": {
              "friend_type": "all_friend_match",
              "friend": {
                "row_param_type_usage": {
                  "operation": {
                    "or_options": [
                      "selected"
                    ]
                  },
                  "parent_param": "yes",
                  "index_list": [
                    "general",
                    "selected_row_parameters",
                    "row_param_type_usage"
                  ],
                  "name": {
                    "translation": {
                      "en": "Satır Parametre Tipi Kullanım Şekli",
                      "tr": "Satır Parametre Tipi Kullanım Şekli"
                    },
                    "value": "row_param_type_usage",
                    "label": "Satır Parametre Tipi Kullanım Şekli"
                  }
                }
              }
            }
          }
        },
        "show": "yes",
        "mother_param": "row_parameter_list",
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "type": "object_list_options",
        "param_order": [
          "row_param_type_usage",
          "row_column_param_type",
          "row_option_group_type",
          "multi_dimension_order"
        ],
        "if": {
          "friend_type": "all_friend_match",
          "friend": {
            "cross_column_type": {
              "operation": {
                "or_options": [
                  "categorical_categorical",
                  "categorical_numeric",
                  "numeric_numeric",
                  "numeric_categorical",
                  "categorical_and_or_numeric_multi_categorical",
                  "categorical_and_or_numeric_multi_numeric",
                  "categorical_and_categorical_or_numeric"
                ]
              },
              "parent_param": "yes",
              "name": {
                "wdm16_id": "18284",
                "translation": {
                  "en": "Karşılaştırma şekli",
                  "tr": "Karşılaştırma şekli"
                },
                "value": "cross_column_type",
                "label": "Karşılaştırma Tipi"
              },
              "index_list": [
                "general",
                "cross_column_type"
              ]
            }
          }
        }
      },
      "categorical_to_nominal": {
        "name": {
          "wdm16_id": "3539",
          "translation": {
            "en": "Kategorik & Sayısal",
            "tr": "Kategorik & Sayısal"
          },
          "value": "categorical_to_nominal",
          "label": "Kategorik & Sayısal İstatistik Testi"
        },
        "show": "yes",
        "object_type": "object",
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [
            "=",
            "<>"
          ],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "object_param": {
          "translation": {
            "en": "Seçilen kategorik & sayısal",
            "tr": "Seçilen kategorik & sayısal"
          },
          "value": "selected_categorical_to_nominal",
          "label": "Seçilen kategorik & sayısal"
        },
        "selectbox_type": "listbox",
        "naming": "1",
        "type": "object_list",
        "options": [
          {
            "wdm16_id": "3540",
            "translation": {
              "en": "Oneway ANOVA",
              "tr": "Oneway ANOVA"
            },
            "value": "oneway_anova",
            "label": "Oneway ANOVA"
          },
          {
            "translation": {
              "en": "t test",
              "tr": "t test"
            },
            "value": "t_test",
            "label": "t test"
          },
          {
            "translation": {
              "en": "Mann Whitney U test",
              "tr": "Mann Whitney U test"
            },
            "value": "mann_whitney_u",
            "label": "Mann Whitney U test"
          },
          {
            "translation": {
              "en": "Kruskal Wallis",
              "tr": "Kruskal Wallis"
            },
            "value": "kruskal_wallis",
            "label": "Kruskal Wallis"
          }
        ],
        "if": {
          "friend_type": "all_friend_match",
          "friend": {
            "usage_of_comparison_tests": {
              "operation": {
                "or_options": [
                  "yes"
                ]
              },
              "parent_param": "yes",
              "name": {
                "wdm16_id": "3538",
                "translation": {
                  "en": "Karşılaştırmalı İstatistiksel Testler Kullanımı",
                  "tr": "Karşılaştırmalı İstatistiksel Testler Kullanımı"
                },
                "value": "usage_of_comparison_tests",
                "label": "Karşılaştırmalı İstatistiksel Testler Kullanımı"
              },
              "index_list": [
                "general",
                "usage_of_comparison_tests"
              ]
            }
          }
        }
      },
      "group_count_control": {
        "name": {
          "wdm16_id": "3554",
          "translation": {
            "en": "Grup Örnek Sayısı Kontrolü",
            "tr": "Grup Örnek Sayısı Kontrolü"
          },
          "value": "group_count_control",
          "label": "Grup Örnek Sayısı Kontrolü"
        },
        "show": "yes",
        "required": "yes",
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [
            "=",
            "<>"
          ],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "type": "select",
        "options": [
          {
            "wdm16_id": "809",
            "translation": {
              "en": "evet",
              "tr": "evet"
            },
            "value": "yes",
            "label": "evet"
          },
          {
            "wdm16_id": "1327",
            "translation": {
              "en": "hayır",
              "tr": "hayır"
            },
            "value": "no",
            "label": "hayır"
          }
        ],
        "if": {
          "friend": {
            "auto_combination_cross_analysis": {
              "operation": {
                "or_options": [
                  "yes"
                ]
              },
              "parent_param": "yes",
              "index_list": [
                "general",
                "auto_combination_cross_analysis"
              ],
              "name": {
                "translation": {
                  "en": "Otomatik Karşılaştırmalı Kombinasyon Analizleri",
                  "tr": "Otomatik Karşılaştırmalı Kombinasyon Analizleri"
                },
                "value": "auto_combination_cross_analysis",
                "label": "Otomatik Karşılaştırmalı Kombinasyon Analizleri"
              }
            }
          }
        }
      },
      "one_or_multi_independent_one_dependent_numeric": {
        "name": {
          "wdm16_id": "3564",
          "translation": {
            "en": "Tek ya da Çoklu Girdili (Bağımsız Değişken), Tek Hedef (Bağımlı Değişken) Sayısal Seçilen Testler",
            "tr": "Tek ya da Çoklu Girdili (Bağımsız Değişken), Tek Hedef (Bağımlı Değişken) Sayısal Seçilen Testler"
          },
          "value": "one_or_multi_independent_one_dependent_numeric",
          "label": "Bir ya da Çok Bağımsız (kategorik veya sayısal), Bir Bağımlı Değişken (sayısal)"
        },
        "show": "yes",
        "object_type": "object",
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [
            "=",
            "<>"
          ],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "object_param": {
          "translation": {
            "en": "Tek ya da Çoklu Girdili (Bağımsız Değişken), Tek Hedef (Bağımlı Değişken) Sayısal Seçilen Testler",
            "tr": "Tek ya da Çoklu Girdili (Bağımsız Değişken), Tek Hedef (Bağımlı Değişken) Sayısal Seçilen Testler"
          },
          "value": "one_or_multi_independent_one_dependent_numeric_selected",
          "label": "Tek ya da Çoklu Girdili (Bağımsız Değişken), Tek Hedef (Bağımlı Değişken) Sayısal Seçilen Testler"
        },
        "op_if": "1",
        "type": "object_list",
        "options": [
          {
            "wdm16_id": "3565",
            "translation": {
              "en": "Linear Regresyon",
              "tr": "Linear Regresyon"
            },
            "label": "Linear Regresyon",
            "value": "linear_regression",
            "if": {
              "friend": {
                "cross_column_type": {
                  "operation": {
                    "or_options": [
                      "categorical_and_or_numeric_multi_numeric",
                      "numeric_numeric",
                      "categorical_numeric"
                    ]
                  },
                  "parent_param": "yes",
                  "name": {
                    "wdm16_id": "18284",
                    "translation": {
                      "en": "Karşılaştırma şekli",
                      "tr": "Karşılaştırma şekli"
                    },
                    "value": "cross_column_type",
                    "label": "Karşılaştırma Tipi"
                  },
                  "index_list": [
                    "general",
                    "cross_column_type"
                  ]
                }
              },
              "friend_if_type": "any_one_of_friend_match"
            }
          }
        ],
        "if": {
          "friend_type": "all_friend_match",
          "friend": {
            "usage_of_comparison_tests": {
              "operation": {
                "or_options": [
                  "yes"
                ]
              },
              "parent_param": "yes",
              "name": {
                "wdm16_id": "3538",
                "translation": {
                  "en": "Karşılaştırmalı İstatistiksel Testler Kullanımı",
                  "tr": "Karşılaştırmalı İstatistiksel Testler Kullanımı"
                },
                "value": "usage_of_comparison_tests",
                "label": "Karşılaştırmalı Test Kullanımı"
              },
              "index_list": [
                "general",
                "usage_of_comparison_tests"
              ]
            },
            "analyze_type": {
              "operation": {
                "or_options": [
                  "cross_columns"
                ]
              },
              "parent_param": "yes",
              "index_list": [
                "general",
                "analyze_type"
              ],
              "name": {
                "translation": {
                  "en": "Analiz Tipi",
                  "tr": "Analiz Tipi"
                },
                "value": "analyze_type",
                "label": "Analiz Tipi"
              }
            }
          }
        }
      },
      "selected_columns": {
        "object_type": "object",
        "name": {
          "wdm16_id": "18292",
          "translation": {
            "en": "Seçilen Kolonlar",
            "tr": "Seçilen Kolonlar"
          },
          "value": "selected_columns",
          "label": "Seçilen Kolonlar (değişkenler)"
        },
        "parameters": {
          "selected_column_timeline_mode": {
            "name": {
              "wdm16_id": "3479",
              "translation": {
                "en": "Zaman Çizelgesi Modu",
                "tr": "Zaman Çizelgesi Modu"
              },
              "value": "selected_column_timeline_mode",
              "label": "Zaman Çizelgesi Modu"
            },
            "show": "yes",
            "rule": {
              "buck": "wisdom",
              "time_of_value": "any_value",
              "data_type": "old_recorded_data",
              "operators": [
                "=",
                "<>"
              ],
              "val_list": [],
              "record_type": "many",
              "data_types": [
                "requesting_data",
                "old_recorded_data"
              ],
              "significant": "true",
              "requesting_operation": "wisdom_data_save",
              "date": "1",
              "type": {
                "translation": {
                  "en": "İstatistik",
                  "tr": "İstatistik"
                },
                "value": "wdm72",
                "label": "İstatistik"
              },
              "op": "="
            },
            "type": "select",
            "options": [
              {
                "wdm16_id": "809",
                "translation": {
                  "en": "evet",
                  "tr": "evet"
                },
                "value": "yes",
                "label": "evet"
              },
              {
                "wdm16_id": "1327",
                "translation": {
                  "en": "hayır",
                  "tr": "hayır"
                },
                "value": "no",
                "label": "hayır"
              }
            ],
            "if": {
              "friend_type": "all_friend_match",
              "friend": {
                "column_param_type": {
                  "operation": {
                    "or_options": [
                      "categorical"
                    ]
                  },
                  "parent_param": "yes",
                  "name": {
                    "wdm16_id": "3463",
                    "translation": {
                      "en": "Kolon Parametre Tipi",
                      "tr": "Kolon Parametre Tipi"
                    },
                    "value": "column_param_type",
                    "label": "Yeni Veri Kullanım Şekli"
                  },
                  "index_list": [
                    "general",
                    "selected_columns",
                    "column_param_type"
                  ]
                },
                "analyze_type": {
                  "operation": {
                    "or_options": [
                      "frequency"
                    ]
                  },
                  "index_list": [
                    "general",
                    "analyze_type"
                  ],
                  "name": {
                    "wdm16_id": "3440",
                    "translation": {
                      "en": "Analiz Tipi",
                      "tr": "Analiz Tipi"
                    },
                    "value": "analyze_type",
                    "label": "Analiz Metodu"
                  }
                }
              }
            }
          },
          "column_param_type": {
            "name": {
              "wdm16_id": "3463",
              "translation": {
                "en": "Kolon Parametre Tipi",
                "tr": "Kolon Parametre Tipi"
              },
              "value": "column_param_type",
              "label": "Yeni Veri Kullanım Şekli"
            },
            "show": "yes",
            "object_type": "object",
            "rule": {
              "buck": "wisdom",
              "time_of_value": "any_value",
              "data_type": "old_recorded_data",
              "operators": [
                "=",
                "<>"
              ],
              "val_list": [],
              "record_type": "many",
              "data_types": [
                "requesting_data",
                "old_recorded_data"
              ],
              "significant": "true",
              "requesting_operation": "wisdom_data_save",
              "date": "1",
              "type": {
                "translation": {
                  "en": "İstatistik",
                  "tr": "İstatistik"
                },
                "value": "wdm72",
                "label": "İstatistik"
              },
              "op": "="
            },
            "type": "select",
            "options": [
              {
                "wdm16_id": "3464",
                "translation": {
                  "en": "kategorik",
                  "tr": "kategorik"
                },
                "value": "categorical",
                "label": "kategorik"
              },
              {
                "wdm16_id": "3509",
                "translation": {
                  "en": "numerik",
                  "tr": "numerik"
                },
                "value": "numeric",
                "label": "sayısal"
              }
            ],
            "if": {
              "friend_type": "any_one_of_friend_match",
              "friend": {
                "param_type_usage": {
                  "operation": {
                    "or_options": [
                      "selected"
                    ]
                  },
                  "parent_param": "yes",
                  "name": {
                    "wdm16_id": "3460",
                    "translation": {
                      "en": "Parametre Tipi Kullanım Şekli",
                      "tr": "Parametre Tipi Kullanım Şekli"
                    },
                    "value": "param_type_usage",
                    "label": "Veri Tipi Kullanımı"
                  },
                  "index_list": [
                    "general",
                    "selected_columns",
                    "param_type_usage"
                  ]
                }
              }
            }
          },
          "will_free_days_be_defined": {
            "name": {
              "wdm16_id": "3481",
              "translation": {
                "en": "Boş Günler Tanımlansın mı?",
                "tr": "Boş Günler Tanımlansın mı?"
              },
              "value": "will_free_days_be_defined",
              "label": "Boş Günler Tanımlansın mı?"
            },
            "show": "no",
            "rule": {
              "buck": "wisdom",
              "time_of_value": "any_value",
              "data_type": "old_recorded_data",
              "operators": [
                "=",
                "<>"
              ],
              "val_list": [],
              "record_type": "many",
              "data_types": [
                "requesting_data",
                "old_recorded_data"
              ],
              "significant": "true",
              "requesting_operation": "wisdom_data_save",
              "date": "1",
              "type": {
                "translation": {
                  "en": "İstatistik",
                  "tr": "İstatistik"
                },
                "value": "wdm72",
                "label": "İstatistik"
              },
              "op": "="
            },
            "disabled": 1,
            "type": "select",
            "options": [
              {
                "wdm16_id": "809",
                "translation": {
                  "en": "evet",
                  "tr": "evet"
                },
                "value": "yes",
                "label": "evet"
              },
              {
                "wdm16_id": "1327",
                "translation": {
                  "en": "hayır",
                  "tr": "hayır"
                },
                "value": "no",
                "label": "hayır"
              }
            ],
            "if": {
              "friend_type": "all_friend_match",
              "friend": {
                "selected_column_timeline_mode": {
                  "operation": {
                    "or_options": [
                      "yes"
                    ]
                  },
                  "parent_param": "yes",
                  "index_list": [
                    "general",
                    "selected_columns",
                    "selected_column_timeline_mode"
                  ],
                  "name": {
                    "translation": {
                      "en": "Zaman Çizelgesi Modu",
                      "tr": "Zaman Çizelgesi Modu"
                    },
                    "value": "selected_column_timeline_mode",
                    "label": "Zaman Çizelgesi Modu"
                  }
                }
              }
            }
          },
          "is_weight_column_for_mean": {
            "if": {
              "friend_type": "all_friend_match",
              "friend": {
                "mean_type": {
                  "operation": {
                    "or_options": [
                      "weighted"
                    ]
                  },
                  "index_list": [
                    "general",
                    "mean_type"
                  ],
                  "name": {
                    "translation": {
                      "en": "Ortalama Tipi",
                      "tr": "Ortalama Tipi"
                    },
                    "value": "mean_type",
                    "label": "Ortalama Tipi"
                  }
                }
              }
            },
            "type": "select",
            "options": [
              {
                "translation": {
                  "en": "hayır",
                  "tr": "hayır"
                },
                "value": "no",
                "label": "hayır"
              },
              {
                "translation": {
                  "en": "evet",
                  "tr": "evet"
                },
                "value": "yes",
                "label": "evet"
              }
            ],
            "name": {
              "translation": {
                "en": "Ortalama Ağırlık Kolonu Mu ?",
                "tr": "Ortalama Ağırlık Kolonu Mu ?"
              },
              "value": "is_weight_column_for_mean",
              "label": "Ortalama Ağırlık Kolonu Mu ?"
            },
            "show": "yes"
          },
          "timeline_mode_day_interval": {
            "name": {
              "wdm16_id": "3480",
              "translation": {
                "en": "Zaman Çizelgesi Gün Aralığı",
                "tr": "Zaman Çizelgesi Gün Aralığı"
              },
              "value": "timeline_mode_day_interval",
              "label": "Zaman Çizelgesi Gün Aralığı"
            },
            "show": "no",
            "rule": {
              "buck": "wisdom",
              "time_of_value": "any_value",
              "data_type": "old_recorded_data",
              "operators": [
                "=",
                "<>",
                ">",
                "<",
                ">=",
                "<="
              ],
              "val_list": [],
              "record_type": "many",
              "data_types": [
                "requesting_data",
                "old_recorded_data"
              ],
              "significant": "true",
              "requesting_operation": "wisdom_data_save",
              "date": "1",
              "type": {
                "translation": {
                  "en": "İstatistik",
                  "tr": "İstatistik"
                },
                "value": "wdm72",
                "label": "İstatistik"
              },
              "op": "="
            },
            "disabled": 1,
            "type": "integer",
            "if": {
              "friend_type": "all_friend_match",
              "friend": {
                "selected_column_timeline_mode": {
                  "operation": {
                    "or_options": [
                      "yes"
                    ]
                  },
                  "parent_param": "yes",
                  "index_list": [
                    "general",
                    "selected_columns",
                    "selected_column_timeline_mode"
                  ],
                  "name": {
                    "translation": {
                      "en": "Zaman Çizelgesi Modu",
                      "tr": "Zaman Çizelgesi Modu"
                    },
                    "value": "selected_column_timeline_mode",
                    "label": "Zaman Çizelgesi Modu"
                  }
                }
              }
            }
          },
          "param_type_usage": {
            "name": {
              "wdm16_id": "3460",
              "translation": {
                "en": "Parametre Tipi Kullanım Şekli",
                "tr": "Parametre Tipi Kullanım Şekli"
              },
              "value": "param_type_usage",
              "label": "Veri Tipi Kullanımı"
            },
            "show": "yes",
            "default": {
              "wdm16_id": "3461",
              "translation": {
                "en": "Otomatik",
                "tr": "Otomatik"
              },
              "value": "auto",
              "label": "otomatik"
            },
            "rule": {
              "buck": "wisdom",
              "time_of_value": "any_value",
              "data_type": "old_recorded_data",
              "operators": [
                "=",
                "<>"
              ],
              "val_list": [],
              "record_type": "many",
              "data_types": [
                "requesting_data",
                "old_recorded_data"
              ],
              "significant": "true",
              "requesting_operation": "wisdom_data_save",
              "date": "1",
              "type": {
                "translation": {
                  "en": "İstatistik",
                  "tr": "İstatistik"
                },
                "value": "wdm72",
                "label": "İstatistik"
              },
              "op": "="
            },
            "type": "select",
            "options": [
              {
                "wdm16_id": "3461",
                "translation": {
                  "en": "Otomatik",
                  "tr": "Otomatik"
                },
                "value": "auto",
                "label": "otomatik"
              },
              {
                "wdm16_id": "3486",
                "translation": {
                  "en": "Seçilen Parametre Tipinde",
                  "tr": "Seçilen Parametre Tipinde"
                },
                "value": "selected",
                "label": "seçilen veri tipinde"
              }
            ]
          },
          "option_group_type": {
            "info": "Hücrelerde zaten tek değer varsa hem numerik hem de kategorik veriler için standart modun seçili olması yeterli olacaktır. Numerik verilerde birden fazla değer bir hücrede var ise o zaman min, max, mean, gibi bir seçenek seçilmesi gerekmektedir.\nNumerik değerlerde ortalamasını alma gibi durumlarda hücre içerisinde çoklu değer varsa default olarak ilgili testin kendisini (mean ise mean, median ise median.... şeklinde) alınır.\nHücrelerdeki en küçük değerlerin ortalaması merak ediliyorsa o zaman burada minimum seçilip test mean olarak seçilir. ",
            "name": {
              "wdm16_id": "3467",
              "translation": {
                "en": "Seçenek Grup Şekli",
                "tr": "Seçenek Grup Şekli"
              },
              "value": "option_group_type",
              "label": "Hücredeki Veri Listesine Yaklaşım"
            },
            "show": "yes",
            "object_type": "object",
            "rule": {
              "buck": "wisdom",
              "time_of_value": "any_value",
              "data_type": "old_recorded_data",
              "operators": [
                "=",
                "<>"
              ],
              "val_list": [],
              "record_type": "many",
              "data_types": [
                "requesting_data",
                "old_recorded_data"
              ],
              "significant": "true",
              "requesting_operation": "wisdom_data_save",
              "date": "1",
              "type": {
                "translation": {
                  "en": "İstatistik",
                  "tr": "İstatistik"
                },
                "value": "wdm72",
                "label": "İstatistik"
              },
              "op": "="
            },
            "op_if": "1",
            "type": "select",
            "options": [
              {
                "wdm16_id": "3512",
                "translation": {
                  "en": "standart mod",
                  "tr": "standart mod"
                },
                "if": {
                  "friend": {}
                },
                "value": "standard_mode",
                "label": "standart mod"
              },
              {
                "wdm16_id": "3513",
                "translation": {
                  "en": "Her bir seçenek ayrı ayrı",
                  "tr": "Her bir seçenek ayrı ayrı"
                },
                "if": {
                  "friend": {
                    "column_param_type": {
                      "operation": {
                        "or_options": [
                          "categorical"
                        ]
                      },
                      "parent_param": "yes",
                      "name": {
                        "wdm16_id": "3463",
                        "translation": {
                          "en": "Kolon Parametre Tipi",
                          "tr": "Kolon Parametre Tipi"
                        },
                        "value": "column_param_type",
                        "label": "Yeni Veri Kullanım Şekli"
                      },
                      "index_list": [
                        "general",
                        "selected_columns",
                        "column_param_type"
                      ]
                    }
                  },
                  "friend_if_type": "all_friend_match"
                },
                "value": "every_option_itself",
                "label": "her bir değer ayrı ayrı"
              },
              {
                "wdm16_id": "3514",
                "translation": {
                  "en": "tüm detaylı kombinasyonlar",
                  "tr": "tüm detaylı kombinasyonlar"
                },
                "if": {
                  "friend": {
                    "column_param_type": {
                      "operation": {
                        "or_options": [
                          "categorical"
                        ]
                      },
                      "parent_param": "yes",
                      "index_list": [
                        "general",
                        "selected_columns",
                        "column_param_type"
                      ],
                      "name": {
                        "translation": {
                          "en": "Kolon Parametre Tipi",
                          "tr": "Kolon Parametre Tipi"
                        },
                        "value": "column_param_type",
                        "label": "Kolon Parametre Tipi"
                      }
                    }
                  },
                  "friend_if_type": "all_friend_match"
                },
                "value": "create_all_combinations",
                "label": "tüm detaylı kombinasyonlar"
              },
              {
                "wdm16_id": "3515",
                "translation": {
                  "en": "min",
                  "tr": "min"
                },
                "if": {
                  "friend": {
                    "column_param_type": {
                      "operation": {
                        "or_options": [
                          "numeric"
                        ]
                      },
                      "parent_param": "yes",
                      "name": {
                        "wdm16_id": "3463",
                        "translation": {
                          "en": "Kolon Parametre Tipi",
                          "tr": "Kolon Parametre Tipi"
                        },
                        "value": "column_param_type",
                        "label": "Yeni Veri Kullanım Şekli"
                      },
                      "index_list": [
                        "general",
                        "selected_columns",
                        "column_param_type"
                      ]
                    }
                  }
                },
                "value": "min",
                "label": "minimum"
              },
              {
                "wdm16_id": "3516",
                "translation": {
                  "en": "maksimum",
                  "tr": "maksimum"
                },
                "if": {
                  "friend": {
                    "column_param_type": {
                      "operation": {
                        "or_options": [
                          "numeric"
                        ]
                      },
                      "parent_param": "yes",
                      "name": {
                        "wdm16_id": "3463",
                        "translation": {
                          "en": "Kolon Parametre Tipi",
                          "tr": "Kolon Parametre Tipi"
                        },
                        "value": "column_param_type",
                        "label": "Yeni Veri Kullanım Şekli"
                      },
                      "index_list": [
                        "general",
                        "selected_columns",
                        "column_param_type"
                      ]
                    }
                  }
                },
                "value": "max",
                "label": "maksimum"
              },
              {
                "wdm16_id": "3517",
                "translation": {
                  "en": "tarihi en küçük olan",
                  "tr": "tarihi en küçük olan"
                },
                "if": {
                  "friend": {}
                },
                "value": "date_min",
                "label": "tarihi en küçük olan değer"
              },
              {
                "wdm16_id": "3518",
                "translation": {
                  "en": "tarihi en büyük olan",
                  "tr": "tarihi en büyük olan"
                },
                "if": {
                  "friend": {}
                },
                "value": "date_max",
                "label": "tarihi en büyük olan değer"
              },
              {
                "wdm16_id": "3519",
                "translation": {
                  "en": "verilerin ortalaması",
                  "tr": "verilerin ortalaması"
                },
                "label": "verilerin ortalaması",
                "value": "mean",
                "if": {
                  "friend": {
                    "column_param_type": {
                      "operation": {
                        "or_options": [
                          "numeric"
                        ]
                      },
                      "parent_param": "yes",
                      "name": {
                        "wdm16_id": "3463",
                        "translation": {
                          "en": "Kolon Parametre Tipi",
                          "tr": "Kolon Parametre Tipi"
                        },
                        "value": "column_param_type",
                        "label": "Yeni Veri Kullanım Şekli"
                      },
                      "index_list": [
                        "general",
                        "selected_columns",
                        "column_param_type"
                      ]
                    }
                  }
                }
              },
              {
                "wdm16_id": "3520",
                "translation": {
                  "en": "verilerin ortancası (medyan)",
                  "tr": "verilerin ortancası (medyan)"
                },
                "label": "verilerin ortancası (medyan)",
                "value": "median",
                "if": {
                  "friend": {
                    "column_param_type": {
                      "operation": {
                        "or_options": [
                          "numeric"
                        ]
                      },
                      "parent_param": "yes",
                      "name": {
                        "wdm16_id": "3463",
                        "translation": {
                          "en": "Kolon Parametre Tipi",
                          "tr": "Kolon Parametre Tipi"
                        },
                        "value": "column_param_type",
                        "label": "Yeni Veri Kullanım Şekli"
                      },
                      "index_list": [
                        "general",
                        "selected_columns",
                        "column_param_type"
                      ]
                    }
                  }
                }
              },
              {
                "wdm16_id": "3521",
                "translation": {
                  "en": "en güncel tarihi al",
                  "tr": "en güncel tarihi al"
                },
                "if": {
                  "friend": {
                    "column_param_type": {
                      "operation": {
                        "or_options": [
                          "date"
                        ]
                      },
                      "parent_param": "yes",
                      "index_list": [
                        "general",
                        "selected_columns",
                        "column_param_type"
                      ],
                      "name": {
                        "translation": {
                          "en": "Kolon Parametre Tipi",
                          "tr": "Kolon Parametre Tipi"
                        },
                        "value": "column_param_type",
                        "label": "Kolon Parametre Tipi"
                      }
                    }
                  }
                },
                "value": "get_max_date",
                "label": "en güncel tarihi al"
              },
              {
                "wdm16_id": "3522",
                "translation": {
                  "en": "en eski tarihi al",
                  "tr": "en eski tarihi al"
                },
                "if": {
                  "friend": {
                    "column_param_type": {
                      "operation": {
                        "or_options": [
                          "date"
                        ]
                      },
                      "parent_param": "yes",
                      "index_list": [
                        "general",
                        "selected_columns",
                        "column_param_type"
                      ],
                      "name": {
                        "translation": {
                          "en": "Kolon Parametre Tipi",
                          "tr": "Kolon Parametre Tipi"
                        },
                        "value": "column_param_type",
                        "label": "Kolon Parametre Tipi"
                      }
                    }
                  }
                },
                "value": "get_min_date",
                "label": "en eski tarihi al"
              }
            ],
            "if": {
              "friend_type": "any_one_of_friend_match",
              "friend": {
                "column_param_type": {
                  "operation": {},
                  "parent_param": "yes",
                  "name": {
                    "wdm16_id": "3463",
                    "translation": {
                      "en": "Kolon Parametre Tipi",
                      "tr": "Kolon Parametre Tipi"
                    },
                    "value": "column_param_type",
                    "label": "Yeni Veri Kullanım Şekli"
                  },
                  "index_list": [
                    "general",
                    "selected_columns",
                    "column_param_type"
                  ]
                }
              }
            }
          }
        },
        "show": "yes",
        "mother_param": "analyze_column_list",
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "required": "yes",
        "type": "object_list_options",
        "param_order": [
          "param_type_usage",
          "column_param_type",
          "option_group_type",
          "selected_column_timeline_mode",
          "timeline_mode_day_interval",
          "will_free_days_be_defined",
          "is_weight_column_for_mean"
        ],
        "if": {
          "friend": {
            "column_selection_type": {
              "operation": {
                "or_options": [
                  "manuel_column_selection"
                ]
              },
              "parent_param": "yes",
              "index_list": [
                "general",
                "column_selection_type"
              ],
              "name": {
                "translation": {
                  "en": "Kolon Seçim Şekli",
                  "tr": "Kolon Seçim Şekli"
                },
                "value": "column_selection_type",
                "label": "Kolon Seçim Şekli"
              }
            }
          }
        }
      },
      "one_or_multi_independent_one_dependent_categorical_selected": {
        "mother_param": "one_or_multi_independent_one_dependent_categorical",
        "name": {
          "wdm16_id": "3558",
          "translation": {
            "en": "Tek ya da Çoklu Girdili (Bağımsız Değişken), Tek Hedef (Bağımlı Değişken) Kategorik Seçilen Testler",
            "tr": "Tek ya da Çoklu Girdili (Bağımsız Değişken), Tek Hedef (Bağımlı Değişken) Kategorik Seçilen Testler"
          },
          "value": "one_or_multi_independent_one_dependent_categorical_selected",
          "label": "Bir ya da Çok Bağımsız (kategorik veya sayısal), Bir Bağımlı Değişken (kategorik) seçilenler"
        },
        "parameters": {
          "oomiodc_variable_selection": {
            "required": "yes",
            "name": {
              "wdm16_id": "3559",
              "translation": {
                "en": "Değişken Seçimi",
                "tr": "Değişken Seçimi"
              },
              "value": "oomiodc_variable_selection",
              "label": "Bağımsız Değişken Stratejisi"
            },
            "show": "yes",
            "object_type": "object",
            "rule": {
              "buck": "wisdom",
              "time_of_value": "any_value",
              "data_type": "old_recorded_data",
              "operators": [],
              "val_list": [],
              "record_type": "many",
              "data_types": [
                "requesting_data",
                "old_recorded_data"
              ],
              "significant": "true",
              "requesting_operation": "wisdom_data_save",
              "date": "1",
              "type": {
                "translation": {
                  "en": "İstatistik",
                  "tr": "İstatistik"
                },
                "value": "wdm72",
                "label": "İstatistik"
              },
              "op": "="
            },
            "type": "checkbox",
            "options": [
              {
                "wdm16_id": "3560",
                "translation": {
                  "en": "her değişkeni birer birer analiz",
                  "tr": "her değişkeni birer birer analiz"
                },
                "value": "0",
                "label": "her değişkeni birer birer analiz"
              },
              {
                "wdm16_id": "3561",
                "translation": {
                  "en": "tüm değişkenleri hedef değişken ile toplu analiz yap",
                  "tr": "tüm değişkenleri hedef değişken ile toplu analiz yap"
                },
                "value": "1",
                "label": "tüm değişkenleri hedef değişken ile toplu analiz yap"
              },
              {
                "wdm16_id": "3562",
                "translation": {
                  "en": "girdi değişkenlerini tek tek analiz yaptıktan sonra p değeri anlamlı olanları toplu analiz olarak da yap",
                  "tr": "girdi değişkenlerini tek tek analiz yaptıktan sonra p değeri anlamlı olanları toplu analiz olarak da yap"
                },
                "value": "2",
                "label": "girdi değişkenlerini tek tek analiz yaptıktan sonra p değeri anlamlı olanları toplu analiz olarak da yap"
              }
            ]
          }
        },
        "show": "yes",
        "object_type": "object",
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "type": "object_list_options",
        "param_order": [
          "oomiodc_variable_selection"
        ],
        "if": {
          "friend_type": "all_friend_match",
          "friend": {
            "usage_of_comparison_tests": {
              "operation": {
                "or_options": [
                  "yes"
                ]
              },
              "parent_param": "yes",
              "name": {
                "wdm16_id": "3538",
                "translation": {
                  "en": "Karşılaştırmalı İstatistiksel Testler Kullanımı",
                  "tr": "Karşılaştırmalı İstatistiksel Testler Kullanımı"
                },
                "value": "usage_of_comparison_tests",
                "label": "Karşılaştırmalı Test Kullanımı"
              },
              "index_list": [
                "general",
                "usage_of_comparison_tests"
              ]
            },
            "analyze_type": {
              "operation": {
                "or_options": [
                  "cross_columns"
                ]
              },
              "parent_param": "yes",
              "index_list": [
                "general",
                "analyze_type"
              ],
              "name": {
                "translation": {
                  "en": "Analiz Tipi",
                  "tr": "Analiz Tipi"
                },
                "value": "analyze_type",
                "label": "Analiz Tipi"
              }
            }
          }
        }
      },
      "total_analyze_operation_count": {
        "info": "İstatistiksel modele ait tüm analizlerin adedi bu parametrede analiz sonrasında görülmektedir.",
        "type": "plain_text",
        "name": {
          "wdm16_id": "3436",
          "translation": {
            "en": "Toplam Analiz İşlem Adedi",
            "tr": "Toplam Analiz İşlem Adedi"
          },
          "value": "total_analyze_operation_count",
          "label": "Analiz İşlemi Adedi"
        },
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "show": "no"
      },
      "categorical_to_categorical": {
        "name": {
          "wdm16_id": "3542",
          "translation": {
            "en": "Kategorik & Kategorik",
            "tr": "Kategorik & Kategorik"
          },
          "value": "categorical_to_categorical",
          "label": "Kategorik & Kategorik İstatistik Testi"
        },
        "show": "yes",
        "object_type": "object",
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [
            "=",
            "<>"
          ],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "object_param": {
          "translation": {
            "en": "Seçilen Kategorik & Kategorik Testler",
            "tr": "Seçilen Kategorik & Kategorik Testler"
          },
          "value": "selected_categorical_to_categorical",
          "label": "Seçilen Kategorik & Kategorik Testler"
        },
        "selectbox_type": "listbox",
        "type": "object_list",
        "options": [
          {
            "wdm16_id": "3543",
            "translation": {
              "en": "Chi square",
              "tr": "Chi square"
            },
            "value": "chisquare",
            "label": "Kikare"
          },
          {
            "wdm16_id": "3544",
            "translation": {
              "en": "Fisher Exact",
              "tr": "Fisher Exact"
            },
            "value": "fisherexact",
            "label": "Fisher Exact"
          }
        ],
        "if": {
          "friend_type": "all_friend_match",
          "friend": {
            "usage_of_comparison_tests": {
              "operation": {
                "or_options": [
                  "yes"
                ]
              },
              "parent_param": "yes",
              "name": {
                "wdm16_id": "3538",
                "translation": {
                  "en": "Karşılaştırmalı İstatistiksel Testler Kullanımı",
                  "tr": "Karşılaştırmalı İstatistiksel Testler Kullanımı"
                },
                "value": "usage_of_comparison_tests",
                "label": "Karşılaştırmalı İstatistiksel Testler Kullanımı"
              },
              "index_list": [
                "general",
                "usage_of_comparison_tests"
              ]
            }
          }
        }
      },
      "model_name": {
        "info": "Modelin bir görüşte anlaşılmasını sağlayacak şekilde yazılacak olan adı",
        "dont_use_label": "1",
        "name": {
          "wdm16_id": "17806",
          "translation": {
            "en": "Model Adı",
            "tr": "Model Adı"
          },
          "value": "model_name",
          "label": "Model Adı"
        },
        "show": "yes",
        "naming": "1",
        "type": "textarea"
      },
      "case_rows_count": {
        "info": "İstatistiksel modelin analizi sırasında kullanılan Wanalyzer projesine ait olan toplam örnek (vaka, satır) sayısını göstermektedir.",
        "type": "plain_text",
        "name": {
          "wdm16_id": "3432",
          "translation": {
            "en": "Toplam Örnek Sayısı",
            "tr": "Toplam Örnek Sayısı"
          },
          "value": "case_rows_count",
          "label": "Toplam Satır Sayısı"
        },
        "rule": {
          "buck": "wisdom",
          "time_of_value": "any_value",
          "data_type": "old_recorded_data",
          "operators": [],
          "val_list": [],
          "record_type": "many",
          "data_types": [
            "requesting_data",
            "old_recorded_data"
          ],
          "significant": "true",
          "requesting_operation": "wisdom_data_save",
          "date": "1",
          "type": {
            "translation": {
              "en": "İstatistik",
              "tr": "İstatistik"
            },
            "value": "wdm72",
            "label": "İstatistik"
          },
          "op": "="
        },
        "show": "no"
      }
    }
  },
  "ending_date_ability": "no",
  "owner": {
    "value": "wisdom",
    "label": "wisdom wisdom"
  },
  "follow_up_location": [],
  "date_ability_user_selection": "no",
  "department_filter_mode": "filter_with_department",
  "style": {
    "caption": {
      "color": "black",
      "after": "",
      "borderRadius": 0,
      "fontSize": 12,
      "backgroundColor": "white",
      "border": {
        "color": "black",
        "type": "solid",
        "size": 0
      }
    },
    "value": {
      "color": "black",
      "after": "",
      "borderRadius": 0,
      "fontSize": 10,
      "backgroundColor": "white",
      "border": {
        "color": "black",
        "type": "solid",
        "size": 0
      }
    }
  },
  "bmi_gfr": "no",
  "ending_date_ability_type": "date",
  "ending_date_ability_limit": "none",
  "checked_on_open": "no",
  "document_upload": "no",
  "version": "2.0",
  "send_to_hims_protocol": "no",
  "print_settings": {
    "alignment": "near",
    "mode": "1",
    "param": {
      "caption": {
        "color": "black",
        "after": "",
        "borderRadius": 0,
        "fontSize": 12,
        "backgroundColor": "white",
        "border": {
          "color": "",
          "type": "",
          "size": 0
        }
      },
      "value": {
        "color": "black",
        "after": "",
        "borderRadius": 0,
        "fontSize": 12,
        "backgroundColor": "white",
        "border": {
          "color": "",
          "type": "",
          "size": 0
        }
      }
    }
  },
  "date_ability_type": "date",
  "calculate_eval": "no",
  "status": "published",
  "ending_date_ability_user_selection": "no",
  "parent": "wdm72",
  "date_ability_limit": "none",
  "permissions_list_for_superuser": [],
  "model": "wisdom_data",
  "show_last_recorded_value": "yes",
  "rule_parameter": "no",
  "about": [
    "Kutup yıldızında oluşturulan istatistiksel modellerin tutulduğu wdm olarak görev yapmaktadır."
  ],
  "name": {
    "wdm16_id": "3426",
    "translation": {
      "en": "İstatistik",
      "tr": "İstatistik"
    },
    "value": "wdm72",
    "label": "İstatistik"
  },
  "language": {
    "value": "tr",
    "label": "Türkçe"
  },
  "settings": {
    "param_order": [
      "will_analyze_after_project_dataset_auto_update",
      "auto_report_transfer",
      "send_mail_after_analyze",
      "attachment_file_type",
      "mail_list",
      "mail_sender_name",
      "mail_sender_userid",
      "mail_subject_prefix",
      "mail_subject_suffix",
      "mail_start_text",
      "mail_note_item",
      "mail_text_color",
      "mail_background_image",
      "mail_table_border_color",
      "mail_table_background_color",
      "mail_sign"
    ],
    "parameters": {
      "auto_report_transfer": {
        "show": "yes",
        "type": "select",
        "options": [
          {
            "value": "yes",
            "label": "evet"
          },
          {
            "value": "no",
            "label": "hayır"
          }
        ],
        "name": {
          "translation": {
            "en": "Güncelleme Sonrası Raporlar Otomatik Güncellensin mi?",
            "tr": "Güncelleme Sonrası Raporlar Otomatik Güncellensin mi?"
          },
          "value": "auto_report_transfer",
          "label": "Güncelleme Sonrası Raporlar Otomatik Güncellensin mi?"
        },
        "if": {
          "friend": {
            "will_analyze_after_project_dataset_auto_update": {
              "operation": {
                "or_options": [
                  "start_analyze"
                ]
              },
              "parent_param": "yes",
              "index_list": [
                "settings",
                "will_analyze_after_project_dataset_auto_update"
              ],
              "name": {
                "translation": {
                  "en": "Otomatik veri güncelleme sonrası analiz edilsin mi?",
                  "tr": "Otomatik veri güncelleme sonrası analiz edilsin mi?"
                },
                "value": "will_analyze_after_project_dataset_auto_update",
                "label": "Otomatik veri güncelleme sonrası analiz işlemi"
              }
            }
          }
        }
      },
      "mail_note_item": {
        "name": {
          "translation": {
            "en": "Mail Not Listesi",
            "tr": "Mail Not Listesi"
          },
          "value": "mail_note_item",
          "label": "Mail Not Listesi"
        },
        "parameters": {
          "mail_caption": {
            "object_type": "object",
            "type": "text",
            "name": {
              "translation": {
                "en": "Mail Başlık",
                "tr": "Mail Başlık"
              },
              "value": "mail_caption",
              "label": "Mail Başlık"
            },
            "show": "yes"
          },
          "mail_note": {
            "object_type": "object",
            "type": "text",
            "name": {
              "translation": {
                "en": "Mail Not",
                "tr": "Mail Not"
              },
              "value": "mail_note",
              "label": "Mail Not"
            },
            "show": "yes"
          }
        },
        "show": "yes",
        "object_type": "object",
        "type": "list_parameter",
        "param_order": [
          "mail_caption",
          "mail_note"
        ],
        "if": {
          "friend": {}
        }
      },
      "send_mail_after_analyze": {
        "type": "select",
        "options": [
          {
            "translation": {
              "en": "evet",
              "tr": "evet"
            },
            "value": "yes",
            "label": "evet"
          },
          {
            "translation": {
              "en": "hayır",
              "tr": "hayır"
            },
            "value": "no",
            "label": "hayır"
          }
        ],
        "name": {
          "translation": {
            "en": "Analiz Sonrası Mail Gönderimi",
            "tr": "Analiz Sonrası Mail Gönderimi"
          },
          "value": "send_mail_after_analyze",
          "label": "Analiz Sonrası Mail Gönderimi"
        },
        "show": "yes"
      },
      "mail_start_text": {
        "if": {
          "friend": {}
        },
        "type": "text",
        "name": {
          "translation": {
            "en": "Mail Üst Bilgi",
            "tr": "Mail Üst Bilgi"
          },
          "value": "mail_start_text",
          "label": "Mail Üst Bilgi"
        },
        "show": "yes"
      },
      "mail_sender_userid": {
        "type": "text",
        "name": {
          "translation": {
            "en": "Mail Gönderici Kullanıcı Id",
            "tr": "Mail Gönderici Kullanıcı Id"
          },
          "value": "mail_sender_userid",
          "label": "Mail Gönderici Kullanıcı Id"
        },
        "show": "yes"
      },
      "mail_sender_name": {
        "if": {
          "friend": {}
        },
        "type": "text",
        "name": {
          "translation": {
            "en": "Mail Gönderici Adı",
            "tr": "Mail Gönderici Adı"
          },
          "value": "mail_sender_name",
          "label": "Mail Gönderici Adı"
        },
        "show": "yes"
      },
      "mail_table_border_color": {
        "object_type": "object",
        "type": "text",
        "name": {
          "translation": {
            "en": "Mail Tablo Sınır Rengi",
            "tr": "Mail Tablo Sınır Rengi"
          },
          "value": "mail_table_border_color",
          "label": "Mail Tablo Sınır Rengi"
        },
        "if": {
          "friend": {}
        },
        "show": "yes"
      },
      "mail_subject_suffix": {
        "if": {
          "friend": {}
        },
        "type": "text",
        "name": {
          "translation": {
            "en": "Konu Son Adı",
            "tr": "Konu Son Adı"
          },
          "value": "mail_subject_suffix",
          "label": "Mail Konu Son Adı"
        },
        "show": "yes"
      },
      "mail_text_color": {
        "info": "Beyaz için \"white\" şeklinde, siyah için \"black\" şeklinde tanımlamalar yapılabilir. Bunun dışında hex modunda \"#efa6dc\" şeklinde ya da rgb(0,0,0) şeklinde rgb tanımlamalar yapılabilir.",
        "name": {
          "translation": {
            "en": "Mail Yazı Rengi (hex or rgb)",
            "tr": "Mail Yazı Rengi (hex or rgb)"
          },
          "value": "mail_text_color",
          "label": "Mail Yazı Rengi (hex or rgb)"
        },
        "show": "yes",
        "object_type": "object",
        "type": "text",
        "if": {
          "friend": {}
        }
      },
      "attachment_file_type": {
        "show": "yes",
        "type": "checkbox",
        "options": [
          {
            "translation": {
              "en": "excel",
              "tr": "excel"
            },
            "value": "excel",
            "label": "excel"
          },
          {
            "translation": {
              "en": "pdf",
              "tr": "pdf"
            },
            "value": "pdf",
            "label": "pdf"
          }
        ],
        "name": {
          "translation": {
            "en": "Mail Dosya Eki Tipleri",
            "tr": "Mail Dosya Eki Tipleri"
          },
          "value": "attachment_file_type",
          "label": "Mail Dosya Eki Tipleri"
        }
      },
      "will_analyze_after_project_dataset_auto_update": {
        "type": "select",
        "options": [
          {
            "translation": {
              "en": "analiz yeniden yapılmasın",
              "tr": "analiz yeniden yapılmasın"
            },
            "value": "dont_analyze",
            "label": "analiz yeniden yapılmasın"
          },
          {
            "translation": {
              "en": "analiz yeniden yapılsın",
              "tr": "analiz yeniden yapılsın"
            },
            "value": "start_analyze",
            "label": "analiz yeniden yapılsın"
          }
        ],
        "name": {
          "translation": {
            "en": "Otomatik veri güncelleme sonrası analiz edilsin mi?",
            "tr": "Otomatik veri güncelleme sonrası analiz edilsin mi?"
          },
          "value": "will_analyze_after_project_dataset_auto_update",
          "label": "Otomatik veri güncelleme sonrası analiz işlemi"
        },
        "show": "yes"
      },
      "mail_subject_prefix": {
        "if": {
          "friend": {}
        },
        "type": "text",
        "name": {
          "translation": {
            "en": "Konu Ön Adı",
            "tr": "Konu Ön Adı"
          },
          "value": "mail_subject_prefix",
          "label": "Mail Konu Ön Adı"
        },
        "show": "yes"
      },
      "mail_list": {
        "if": {
          "friend": {}
        },
        "type": "textarea",
        "name": {
          "translation": {
            "en": "Mail Listesi (virgül ile ayırın)",
            "tr": "Mail Listesi (virgül ile ayırın)"
          },
          "value": "mail_list",
          "label": "Mail Listesi (virgül ile ayırın)"
        },
        "show": "yes"
      },
      "mail_sign": {
        "name": {
          "translation": {
            "en": "Mail imza",
            "tr": "Mail imza"
          },
          "value": "mail_sign",
          "label": "Mail imza"
        },
        "parameters": {
          "mail_sign_picture": {
            "object_type": "object",
            "type": "image",
            "name": {
              "translation": {
                "en": "Mail imza resmi",
                "tr": "Mail imza resmi"
              },
              "value": "mail_sign_picture",
              "label": "Mail imza resmi"
            },
            "show": "yes"
          },
          "mail_sign_image_width_px": {
            "object_type": "object",
            "type": "integer",
            "name": {
              "translation": {
                "en": "Mail imza resmi genişlik piksel",
                "tr": "Mail imza resmi genişlik piksel"
              },
              "value": "mail_sign_image_width_px",
              "label": "Mail imza resmi genişlik piksel"
            },
            "show": "yes"
          },
          "mail_sign_text": {
            "object_type": "object",
            "type": "text",
            "name": {
              "translation": {
                "en": "Mail imza yazısı",
                "tr": "Mail imza yazısı"
              },
              "value": "mail_sign_text",
              "label": "Mail imza yazısı"
            },
            "show": "yes"
          }
        },
        "show": "yes",
        "object_type": "object",
        "type": "list_parameter",
        "param_order": [
          "mail_sign_text",
          "mail_sign_picture",
          "mail_sign_image_width_px"
        ],
        "if": {
          "friend": {}
        }
      },
      "mail_background_image": {
        "object_type": "object",
        "type": "image",
        "name": {
          "translation": {
            "en": "Mail Arkaplan Resmi",
            "tr": "Mail Arkaplan Resmi"
          },
          "value": "mail_background_image",
          "label": "Mail Arkaplan Resmi"
        },
        "if": {
          "friend": {}
        },
        "show": "yes"
      },
      "mail_table_background_color": {
        "object_type": "object",
        "type": "text",
        "name": {
          "translation": {
            "en": "Mail Tablo Arka Plan Rengi",
            "tr": "Mail Tablo Arka Plan Rengi"
          },
          "value": "mail_table_background_color",
          "label": "Mail Tablo Arka Plan Rengi"
        },
        "if": {
          "friend": {}
        },
        "show": "yes"
      }
    }
  },
  "date_ability": "no",
  "value": "wdm72",
  "edit_in_hospital_settings": "no",
  "owner_type": "wisdom",
  "schema": "yes"
};
export { data }
