// import { DateFunctions as DateFunctions } from './date_functions';
// import store from '../../store';

class ClsRule {
  static _f_listParameterGroup = function(query_list) {
    for (let q in query_list) {
      query_list[q].list_parameter_groups = {};
      for (let f in query_list[q].fields) {
        let loc = query_list[q].fields[f].loc;
        for (let i in loc) {
          if (loc[i].type === 'list_parameter') {
            // i_type => index_type
            if (!loc[i].i_type) {
              loc[i].i_type = 'any';
              loc[i].i_group = '';
            }
            if (!query_list[q].list_parameter_groups[loc[i].value]) {
              query_list[q].list_parameter_groups[loc[i].value] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
            }
          }
        }
      }
    }
  };

  static f_queryFromParam = function(query_list, wps_list, option_data, type, compile_str_array) {
    let wps_data = wps_list[0];
    let wdm = option_data[wps_data.selected_wdm.type];
    let new_query = {
      'time_of_value': wps_data.time_of_value, // last_value, any_value, last_days
      'data_type': wps_data.data_type, // requesting_data, old_recorded_data
      'compile_str_array': [],
      'fields': [],
      'compile_str': '',
      'significant': wps_data.significant,
      'date': wdm.date_ability && wdm.date_ability === 'yes' ? '1' : '0',
      'type': {
        'value': wps_data.selected_wdm.type,
        'label': wps_data.selected_wdm.label
      }
    };
    let new_field = {
      'loc': [],
      'using_data_if_not_reaching_record_data': wps_data.using_data_if_not_reaching_record_data,
      'val_list': [],
      'values': this.f_getValueList(wps_data),
      'significant': wps_data.significant,
      'value_type': this.f_getParamType(wps_data),
      'operation': wps_data.operation
    };
    if (wps_data.val_list.length > 0) {
      new_field.val_list = wps_data.val_list;
    } else {
      new_field.val_list = new_field.values;
    }
    if (type === 'polestar_column') {
      new_field.is_column = 1;
    }
    for (let loc_ind in wps_data.loc) {
      if (wps_data.loc[loc_ind]) {
        new_field.loc.push(wps_data.loc[loc_ind]);
      }
    }
    new_query.fields.push(new_field);
    query_list.push(new_query);
    this.f_addThisKeyQueryAndFields('and', query_list, compile_str_array);
  };

  static f_addToThisQueryFromParam = function(query_ind, query_list, wps_list, option_data, type, compile_str_array) {
    let wps_data = wps_list[0];
    let new_field = {
      'loc': [],
      'using_data_if_not_reaching_record_data': wps_data.using_data_if_not_reaching_record_data,
      'val_list': [],
      'values': this.f_getValueList(wps_data),
      'significant': wps_data.significant,
      'value_type': this.f_getParamType(wps_data),
      'operation': wps_data.operation
    };
    if (wps_data.val_list.length > 0) {
      new_field.val_list = wps_data.val_list;
    } else {
      new_field.val_list = new_field.values;
    }
    for (let loc_ind in wps_data.loc) {
      if (wps_data.loc[loc_ind]) {
        new_field.loc.push(wps_data.loc[loc_ind]);
      }
    }
    query_list[query_ind].fields.push(new_field);
    this.f_addThisKeyQueryAndFields('and', query_list, compile_str_array);
  };

  static f_paramToQuery = function(query_list, compile_str_array, wps_list) {
    // we want to put the similar wdm types in the same query. So first we get all of them.
    // console.log('f_paramToQuery');
    let query_type_index = {};
    query_list.splice(0, query_list.length);
    compile_str_array.splice(0, compile_str_array.length);
    for (let i in wps_list) {
      if (wps_list[i].selected_wdm && wps_list[i].loc.length > 0) {
        if (!query_type_index[wps_list[i].selected_wdm.type]) {
          query_type_index[wps_list[i].selected_wdm.type] = [];
        }
        query_type_index[wps_list[i].selected_wdm.type].push(i);
      }
    }
    if (Object.keys(query_type_index).length > 0) {
      for (let t in query_type_index) {
        let new_query = {
          'time_of_value': 'any_value',
          'data_type': 'old_recorded_data',
          'compile_str_array': [],
          'fields': [],
          'compile_str': '',
          'significant': 'true',
          'date': '1',
          'type': {
            'value': wps_list[query_type_index[t][0]].selected_wdm.type,
            'label': wps_list[query_type_index[t][0]].selected_wdm.label
          }
        };
        for (let i in query_type_index[t]) {
          let wps_data = wps_list[query_type_index[t][i]];
          // console.log(JSON.stringify(wps_data));
          let new_field = {
            'loc': [],
            'using_data_if_not_reaching_record_data': 'true',
            'val_list': [],
            'values': this.f_getValueList(wps_data),
            'significant': wps_data.significant,
            'value_type': this.f_getParamType(wps_data),
            'operation': wps_data.operation
          };
          /*
           */
          if (wps_data.val_list.length > 0) {
            new_field.val_list = wps_data.val_list;
          } else {
            new_field.val_list = new_field.values;
          }
          if (wps_data.is_column) {
            new_field.is_column = 1;
          }
          for (let loc_ind in wps_data.loc) {
            if (wps_data.loc[loc_ind]) {
              new_field.loc.push(wps_data.loc[loc_ind]);
            }
          }
          new_query.fields.push(new_field);
        }
        query_list.push(new_query);
      }
      this.f_addThisKeyQueryAndFields('and', query_list, compile_str_array);
    }
  };

  static f_addThisKeyQueryAndFields = function(key, query_list, compile_str_array) {
    let list_count = 0;
    compile_str_array.splice(0, compile_str_array.length);
    list_count = query_list.length;
    compile_str_array.push('(');
    for (let i = 0; i < list_count; i++) {
      compile_str_array.push(i);
      if (list_count - 1 !== i) {
        compile_str_array.push(key);
      }
    }
    compile_str_array.push(')');
    for (let q in query_list) {
      let list_count = 0;
      query_list[q].compile_str_array.splice(0, query_list[q].compile_str_array.length);
      list_count = query_list[q].fields.length;
      query_list[q].compile_str_array.push('(');
      for (let i = 0; i < list_count; i++) {
        query_list[q].compile_str_array.push(i);
        if (list_count - 1 !== i) {
          query_list[q].compile_str_array.push(key);
        }
      }
      query_list[q].compile_str_array.push(')');
    }
  };
  static f_getValueList = function(wps_data) {
    // console.log('wps_data.val_list ', wps_data.val_list);
    let values = [];
    if (wps_data.val_list.length > 0) {
      for (let i in wps_data.val_list) {
        if (typeof wps_data.val_list[i] === 'object') {
          values.push(wps_data.val_list[i].value);
        } else {
          values.push(wps_data.val_list[i]);
        }
      }
    } else {
      if (wps_data.selected_value) {
        values.push(wps_data.selected_value);
      }
    }
    return values;
  };
  static f_getParamType = function(wps_data) {
    for (let i = wps_data.loc.length - 1; i >= 0; i--) {
      if (wps_data.loc[i]) {
        return wps_data.loc[i].type;
      }
    }
  };

  static f_addNewQuery = function(type, wdm, data_type, rule_data, param_data, option_loc, query_list) {
    /*
      type => 'missing', 'polestar_column'
      data_type => wdm_type => genetic, pathology, wdm7, ....
    */
    let query = {};
    query = {
      'fields': [],
      'significant': 'true',
      'date': wdm.date_ability && wdm.date_ability === 'yes' ? '1' : '0',
      'type': { 'label': wdm.name.label, 'value': data_type },
      'data_type': wdm.value,
      'time_of_value': wdm.date_ability && wdm.date_ability === 'yes' ? 'any_value' : '',
      'compile_str_array': [],
      'compile_str': ''
    };
    if (type === 'missing') {
      query.significant = 'false';
    }
    let field_data_list = this._field_data_create(type, rule_data, param_data, option_loc);
    for (let f in field_data_list) {
      query.fields.push(field_data_list[f]);
      this._compile_str_func(query, 'new');
    }
    // console.log(query);
    query_list.push(query);
    this._f_listParameterGroup(query_list);
  };

  static f_addToThisQuery = function(query_ind, type, wdm, data_type, rule_data, param_data, option_loc, query_list) {
    let field_data_list = this._field_data_create(type, rule_data, param_data, option_loc);
    for (let f in field_data_list) {
      let det = false;
      for (let i in query_list[query_ind].fields) {
        if (JSON.stringify(field_data_list[f]) === JSON.stringify(query_list[query_ind].fields[i])) {
          query_list[query_ind].fields[i].values = JSON.parse(JSON.stringify(field_data_list[f].values));
          det = true;
          break;
        }
      }
      if (!det) {
        query_list[query_ind].fields.push(field_data_list[f]);
        this._compile_str_func(query_list[query_ind], 'field');
      }
    }
    this._f_listParameterGroup(query_list);
  };

  static _compile_str_func = function(query, type) {
    if (type === 'new') {
      query.compile_str_array = ['(', 0, ')'];
    } else if (type === 'field') {
      let last_field_index = query.fields.length - 1;
      query.compile_str_array.splice(query.compile_str_array.length - 1, 1);
      query.compile_str_array.push('and');
      query.compile_str_array.push(last_field_index);
      query.compile_str_array.push(')');
    }
  };

  static f_controlParanthesisDesign = function(compile_array) {
    let res = false;
    let start_par = 0;
    let end_par = 0;
    for (let i in compile_array) {
      if (compile_array[i] === '(') {
        start_par += 1;
      } else if (compile_array[i] === ')') {
        end_par += 1;
      }
    }
    if (start_par === end_par) {
      res = true;
    }
    return res;
  };

  static _f_getOptionLocEligibleForRuleField = function(option_loc) {
    let new_option_loc = [];
    for (let i in option_loc) {
      // here we want to get detail location. But we dont need the list no and anatomy like info.
      if (option_loc[i].type !== 'anatomy' && option_loc[i].type !== 'list') {
        new_option_loc.push(option_loc[i]);
      }
    }
    return JSON.parse(JSON.stringify(new_option_loc));
  };

  static _field_data_create = function(type = '', rule_data, param_data, option_loc) {
    let option_loc_data = this._f_getOptionLocEligibleForRuleField(option_loc);
    // console.log(option_loc_data);
    let value_type = param_data.type;
    let field_data_list = [];
    // backendde sorgularken tipinin object_list_options olmasına ve level değerine bakıyoruz.
    if (param_data.type === 'object_list') {
      // her bir value için yani her bir object list options elemanı için yeni bir field oluşturuluyor
      for (let i in rule_data.val_list) {
        let object_list_options_param_location = {
          'label': param_data.object_param.label,
          'value': param_data.object_param.value,
          'type': 'object_list_options'
        };
        option_loc_data[option_loc_data.length - 1] = object_list_options_param_location;
        let detail_param_location = {
          'label': rule_data.val_list[i].label,
          'value': rule_data.val_list[i].value,
          'type': 'detail'
        };
        option_loc_data.push(detail_param_location);
        value_type = 'object_list_options';
        let val_list = [];
        let values = [];
        // val_list.push(rule_data.val_list[i]);
        // values.push(rule_data.val_list[i].value);
        let field_data = {
          'value_type': value_type,
          'significant': 'true',
          'using_data_if_not_reaching_record_data': 'true',
          'val_list': val_list,
          'values': values,
          'operation': rule_data.op,
          'loc': option_loc_data
        };
        if (option_loc_data[0].value === 'anatomy') {
          field_data.anatomy_use = 'any';
        }
        if (type && type === 'polestar_column') {
          field_data.is_column = 1;
        }
        field_data_list.push(field_data);
      }
    } else {
      let values = [];
      let val_list = [];
      if (['missing'].indexOf(type) === -1) {
        val_list = rule_data.val_list;
        for (let i in rule_data.val_list) {
          if (typeof rule_data.val_list[i] === 'object') {
            if (['>', '<', '>=', '<='].indexOf(rule_data.op) !== -1) {
              // Use label for this eligible labels for these operations
              values.push(rule_data.val_list[i].label);
            } else {
              values.push(rule_data.val_list[i].value);
            }
          } else {
            values.push(rule_data.val_list[i]);
          }
        }

        /*
          if (['select', 'radiogroup', 'checkbox'].indexOf(param_data.type) !== -1) {
            if (['>', '<', '>=', '<='].indexOf(rule_data.op) !== -1) {
              // We use label for this eligible labels for these operations
              for (let i in rule_data.val_list) {
                values.push(rule_data.val_list[i].label);
              }
            } else {
              for (let i in rule_data.val_list) {
                values.push(rule_data.val_list[i].value);
              }
            }
          } else if (['integer', 'float', 'text', 'textarea', 'date', 'datetime', 'time', 'plain_text'].indexOf(param_data.type) !== -1) {
            values = rule_data.val_list;
          }
        */
      }
      let field_data = {
        'value_type': value_type,
        'significant': 'true',
        'using_data_if_not_reaching_record_data': 'true',
        'val_list': val_list,
        'values': values,
        'operation': rule_data.op,
        'loc': option_loc_data
      };
      if (option_loc_data[0].value === 'anatomy') {
        field_data.anatomy_use = 'any';
      }
      if (type && type === 'polestar_column') {
        field_data.is_column = 1;
      }
      field_data_list.push(field_data);
    }
    return field_data_list;
  };
}

export { ClsRule };
