var data = [{
    'label': 'ChatGPT Chat Completion API (chatgpt_v1_chat_completion)',
    'translation': {
      'en': 'ChatGPT Chat Completion API (chatgpt_v1_chat_completion)',
      'tr': 'ChatGPT Chat Completion API (chatgpt_v1_chat_completion)',
    },
    'function': 'chatgpt_v1_chat_completion()',
    'info': {
      'en': 'The ChatGPT Chat Completion API message is asked to find data in the expression in the cell in the column.',
      'tr': 'ChatGPT Chat Completion API mesajına kolondaki hücre içindeki ifadede verileri bulması istenir.'
    },
    'example': 'chatgpt_v1_chat_completion(\'{"model": "gpt-3.5-turbo", "messages": [{"role": "user", "content": "If there is a negative statement, mark it as yes. \\n"}], "temperature": 0.7, "apikey": "xxxxxxx" }\', [col1])'
  }, {
    'label': 'NLP Doğal Dil İşleme (nlp)',
    'translation': {
      'en': 'NLP Natural Language Processing (nlp)',
      'tr': 'NLP Doğal Dil İşleme (nlp)',
    },
    'function': 'nlp()',
    'info': {
      'en': 'It performs natural language processing analysis on the text in the column with the specified expression and writes the result to the cell.',
      'tr': 'Belirtilen ifade ile kolondaki yazıda doğal dil işleme analizi yaparak sonucun hücreye yazılmasını sağlar.'
    },
    'example': 'nlp(\'xxx\', [col1])'
  },
  {
    'label': 'başlangıçtan x. sıradaki değeri al, tarih listesi ile gruplayarak (get_value_of_this_order_group_by_date_from_start)',
    'translation': {
      'en': 'get xth order value from start, group by date list (get_value_of_this_order_group_by_date_from_start)',
      'tr': 'başlangıçtan x. sıradaki değeri al, tarih listesi ile gruplayarak (get_value_of_this_order_group_by_date_from_start)',
    },
    'function': 'get_value_of_this_order_group_by_date_from_start()',
    'info': {
      'en': 'Returns the next xth value by grouping the values in multiple cell data with a date list.',
      'tr': 'Çoklu hücre verilerindeki değerlerin tarih listesi ile gruplayarak sıradaki x. değeri verir.'
    },
    'example': 'get_value_of_this_order_group_by_date_from_start([col1], 2)'
  },
  {
    'label': 'başlangıçtan x. sıradaki değeri al, tarih listesinden gruplandırmadan (get_value_of_this_order_not_group_by_date_from_start)',
    'translation': {
      'en': 'get xth order value from start, not group by date list (get_value_of_this_order_not_group_by_date_from_start)',
      'tr': 'başlangıçtan x. sıradaki değeri al, tarih listesinden gruplandırmadan (get_value_of_this_order_not_group_by_date_from_start)',
    },
    'function': 'get_value_of_this_order_not_group_by_date_from_start()',
    'info': {
      'en': 'Returns the next xth value in multiple cell data without taking into account the date.',
      'tr': 'Çoklu hücre verilerindeki değerlerin tarih baz alınmadan sıradaki x. değeri verir.'
    },
    'example': 'get_value_of_this_order_not_group_by_date_from_start([col1], 2)'
  },
  {
    'label': 'başlangıçtan x. tarih değerini al (get_date_of_this_order_from_start)',
    'translation': {
      'en': 'get xth date value from start (get_date_of_this_order_from_start)',
      'tr': 'başlangıçtan x. tarih değerini al (get_date_of_this_order_from_start)',
    },
    'function': 'get_date_of_this_order_from_start()',
    'info': {
      'en': 'Gets the date in the xth row from the oldest date in the column cells containing multiple date information.',
      'tr': 'Çoklu tarih bilgisini içeren kolon hücrelerindeki en eski tarihten x. sıradaki tarihi alır.'
    },
    'example': 'get_date_of_this_order_from_start([col1], 1)'
  },
  {
    'label': 'belirtilen tarihten x gün önce ve sonra aralığında olan değerleri al (get_val_between_date)',
    'translation': {
      'en': 'Get values between x days before and after the specified date (get_val_between_date)',
      'tr': 'belirtilen tarihten x gün önce ve sonra aralığında olan değerleri al (get_val_between_date)',
    },
    'function': 'get_val_between_date()',
    'info': {
      'en': 'It can be used in wdmr columns, which contain multiple values and are historical data, when values within a certain date range need to be determined and transferred to another column.',
      'tr': 'Birden fazla değer barındıran ve tarihsel bir veri olan wdmr kolonlarında belirli bir tarih aralığındaki değerler başka bir kolona belirlenerek aktarılması gerektiğinde kullanılabilir.'
    },
    'example': 'get_val_between_date([col35], get_min_date(col20), -10, 10)'
  },
  {
    'label': 'bugün tarihini al (today)',
    'translation': {
      'en': 'get today date (today)',
      'tr': 'bugün tarihini al (today)',
    },
    'function': 'today()',
    'info': {
      'en': 'Todays historical value is used in the function when necessary.',
      'tr': 'Bugün tarihsel değeri fonksiyonda gerektiğinde kullanılmaktadır.'
    },
    'example': 'today()'
  },
  {
    'label': 'büyük olan ilk en küçük değeri ata (select_first_upper)',
    'translation': {
      'en': 'assign the first smallest value to the largest (select_first_upper)',
      'tr': 'büyük olan ilk en küçük değeri ata (select_first_upper)',
    },
    'function': 'select_first_upper()',
    'info': {
      'en': 'Returns the smallest value of a numeric value in a cell among the specified values that are greater than the numeric value.',
      'tr': 'Bir hücredeki sayısal bir değerin, belirtilen değerler arasında kendisinden büyük olan sayılardan en küçük olan değerini verir.'
    },
    'example': 'select_first_upper(col1, \'60\', \'40\', \'50\', \'80\')'
  },
  {
    'label': 'düzenli ifade ile tekil olarak verileri al (regx_unique)',
    'translation': {
      'en': 'Get data uniquely with regular expression (regx_unique)',
      'tr': 'düzenli ifade ile tekil olarak verileri al (regx_unique)',
    },
    'function': 'regx_unique()',
    'info': {
      'en': 'The output is taken singularly as a list by the regular expression function that is run on the text data in the specified column cell.',
      'tr': 'Belirtilen kolon hücresindeki text veri üzerine çalıştırılan regular expression fonksiyonu ile çıktılar liste halinde tekil olarak alınır.'
    },
    'example': 'regx_unique(\'^e.*v\', [col1])'
  },
  {
    'label': 'düzenli ifade ile verileri al (regx)',
    'translation': {
      'en': 'get data with regular expression (regx)',
      'tr': 'düzenli ifade ile verileri al (regx)',
    },
    'function': 'regx()',
    'info': {
      'en': 'Output is obtained by running the regular expression function on the text data in the specified column cell.',
      'tr': 'Belirtilen kolon hücresindeki text veri üzerine çalıştırılan regular expression fonksiyonu ile çıktılar alınır.'
    },
    'example': 'regx(\'^e.*v\', [col1])'
  },
  {
    'label': 'düzenli ifade ile çoklayarak verileri al (regx_multiplex)',
    'translation': {
      'en': 'Retrieve data by multiplying it with regular expression (regx_multiplex)',
      'tr': 'düzenli ifade ile çoklayarak verileri al (regx_multiplex)',
    },
    'function': 'regx_multiplex()',
    'info': {
      'en': 'The output is obtained by multiplexing the list with the regular expression function that is run on the text data in the specified column cell.',
      'tr': 'Belirtilen kolon hücresindeki text veri üzerine çalıştırılan regular expression fonksiyonu ile çıktılar liste halinde çoklayarak alınır.'
    },
    'example': 'regx_multiplex(\'^e.*v\', [col1])'
  },
  {
    'label': 'en eski tarihi al (get_min_date)',
    'translation': {
      'en': 'get oldest date (get_min_date)',
      'tr': 'en eski tarihi al (get_min_date)',
    },
    'function': 'get_min_date()',
    'info': {
      'en': 'If the value in a column cell is multiple, the historical value of the oldest date among the dates of the values in the cell is used in the functions if necessary.',
      'tr': 'Bir kolona ait hücredeki değer çoklu ise, hücredeki değerlerin tarihlerinden en eski tarihin tarihsel değeri fonksiyonlarda gerekli ise kullanılmaktadır.'
    },
    'example': 'get_min_date(col1)'
  },
  {
    'label': 'en yeni tarihi al (get_max_date)',
    'translation': {
      'en': 'get latest date (get_max_date)',
      'tr': 'en yeni tarihi al (get_max_date)',
    },
    'function': 'get_max_date()',
    'info': {
      'en': 'If the value in a column cell is multiple, the historical value of the most recent date among the dates of the values in the cell is used in the functions if necessary.',
      'tr': 'Bir kolona ait hücredeki değer çoklu ise, hücredeki değerlerin tarihlerinden en yeni tarihin tarihsel değeri fonksiyonlarda gerekli ise kullanılmaktadır.'
    },
    'example': 'get_max_date(col1)'
  },
  {
    'label': 'ifadeye ait başlangıçtan x. sıradaki değerin tarihini al (get_date_of_value_from_start)',
    'translation': {
      'en': 'get_date_of_value_from_start (get_date_of_value_from_start)',
      'tr': 'ifadeye ait başlangıçtan x. sıradaki değerin tarihini al (get_date_of_value_from_start)',
    },
    'function': 'get_date_of_value_from_start()',
    'info': {
      'en': 'When a historical sorting operation is performed among the values belonging to the specified expression in a cell with a Magic Cell structure, it returns the date of the value in a specific order from the beginning. If a value belonging to the value in question cannot be found in the specified order in the cell, the function returns empty.',
      'tr': 'Magic Cell yapısına sahip bir hücredeki, belirtilen ifadeye ait değerler arasında, tarihsel sıralama işlemi yapıldığında başlangıçtan belirli bir sırada değerin tarihini verir. Hücrede söz konusu değere ait ait belirtilen sırada bir değer bulunamazsa fonksiyon boş olarak sonuçlanır.'
    },
    'example': 'get_date_of_value_from_start([col1], 2, "Covid PCR")'
  },
  {
    'label': 'seçilen 1 ya da birden fazla kolon içerisindeki verileri toplar (sum_cell_values)',
    'translation': {
      'en': 'sums the data in 1 or more selected columns (sum_cell_values)',
      'tr': 'seçilen 1 ya da birden fazla kolon içerisindeki verileri toplar (sum_cell_values)',
    },
    'function': 'sum_cell_values()',
    'info': {
      'en': 'When a column is specified, it sums the data in cells that can have more than one data. When more than one column is specified, it sums the data in all specified columns.',
      'tr': 'Bir kolon belirtildiğinde, birden fazla veri olabilen hücredeki verileri toplar. Birden fazla kolon belirtildiğinde, belirtilen tüm kolonlardaki verileri toplar.'
    },
    'example': 'sum_cell_values([col1], [col2])'
  },
  {
    'label': 'ifadeye ait sondan x. sıradaki değerin tarihini al (get_date_of_value_from_end)',
    'translation': {
      'en': 'get_date_of_value_from_end (get_date_of_value_from_end)',
      'tr': 'ifadeye ait sondan x. sıradaki değerin tarihini al (get_date_of_value_from_end)',
    },
    'function': 'get_date_of_value_from_end()',
    'info': {
      'en': 'When a historical sorting operation is performed among the values belonging to the specified expression in a cell with a Magic Cell structure, it returns the date of the value in a specific order from the end. If a value cannot be found in the specified order belonging to the value in question in the cell, the function returns empty.',
      'tr': 'Magic Cell yapısına sahip bir hücredeki, belirtilen ifadeye ait değerler arasında, tarihsel sıralama işlemi yapıldığında sondan belirli bir sırada değerin tarihini verir. Hücrede söz konusu değere ait ait belirtilen sırada bir değer bulunamazsa fonksiyon boş olarak sonuçlanır.'
    },
    'example': 'get_date_of_value_from_end([col1], 2, "Covid PCR")'
  },
  {
    'label': 'maksimum değeri al (max)',
    'translation': {
      'en': 'get maximum value (max)',
      'tr': 'maksimum değeri al (max)',
    },
    'function': 'max()',
    'info': {
      'en': 'If the value in a column cell is multiple, the maximum value of these values can be used when creating a new column.',
      'tr': 'Bir kolona ait hücredeki değer çoklu ise bu değerlerin maksimum değeri yeni bir kolon oluştururken kullanılabilmektedir.'
    },
    'example': 'max(col1)'
  },
  {
    'label': 'medyan değeri al (median)',
    'translation': {
      'en': 'get median value',
      'tr': 'medyan değeri al (median)',
    },
    'function': 'median()',
    'info': {
      'en': 'If the value in a column cell is multiple, the median value of these values can be used when creating a new column.',
      'tr': 'Bir kolona ait hücredeki değer çoklu ise bu değerlerin ortanca değeri yeni bir kolon oluştururken kullanılabilmektedir.'
    },
    'example': 'median(col1)'
  },
  {
    'label': 'minimum değeri al (min)',
    'translation': {
      'en': 'get minimum value (min)',
      'tr': 'minimum değeri al (min)',
    },
    'function': 'min()',
    'info': {
      'en': 'If the value in a column cell is multiple, the minimum value of these values can be used when creating a new column.',
      'tr': 'Bir kolona ait hücredeki değer çoklu ise bu değerlerin minimum değeri yeni bir kolon oluştururken kullanılabilmektedir.'
    },
    'example': 'min(col1)'
  },
  {
    'label': 'ortalama değeri al (mean)',
    'translation': {
      'en': 'take the average value (mean)',
      'tr': 'ortalama değeri al (mean)',
    },
    'function': 'mean()',
    'info': {
      'en': 'If the value in a column cell is multiple, the average value of these values can be used when creating a new column.',
      'tr': 'Bir kolona ait hücredeki değer çoklu ise bu değerlerin ortalama değeri yeni bir kolon oluştururken kullanılabilmektedir.'
    },
    'example': 'mean(col1)'
  },
  {
    'label': 'sondan x. sıradaki değeri al, tarih listesi ile gruplayarak (get_value_of_this_order_group_by_date_from_end)',
    'translation': {
      'en': 'get xth value from the end, grouped by date list (get_value_of_this_order_group_by_date_from_end)',
      'tr': 'sondan x. sıradaki değeri al, tarih listesi ile gruplayarak (get_value_of_this_order_group_by_date_from_end)',
    },
    'function': 'get_value_of_this_order_group_by_date_from_end()',
    'info': {
      'en': 'Returns the next xth value by grouping the values in multiple cell data with a date list.',
      'tr': 'Çoklu hücre verilerindeki değerlerin tarih listesi ile gruplayarak sıradaki x. değeri verir.'
    },
    'example': 'get_value_of_this_order_group_by_date_from_end([col1], 2)'
  },
  {
    'label': 'sondan x. sıradaki değeri al, tarih listesinden gruplandırmadan (get_value_of_this_order_not_group_by_date_from_end)',
    'translation': {
      'en': 'get xth value from the end, without grouping from date list (get_value_of_this_order_not_group_by_date_from_end)',
      'tr': 'sondan x. sıradaki değeri al, tarih listesinden gruplandırmadan (get_value_of_this_order_not_group_by_date_from_end)',
    },
    'function': 'get_value_of_this_order_not_group_by_date_from_end()',
    'info': {
      'en': 'Returns the next xth value in multiple cell data without taking into account the date.',
      'tr': 'Çoklu hücre verilerindeki değerlerin tarih baz alınmadan sıradaki x. değeri verir.'
    },
    'example': 'get_value_of_this_order_not_group_by_date_from_end([col1], 2)'
  },
  {
    'label': 'sondan x. tarih değerini al (get_date_of_this_order_from_end)',
    'translation': {
      'en': 'get xth date value from the end (get_date_of_this_order_from_end)',
      'tr': 'sondan x. tarih değerini al (get_date_of_this_order_from_end)',
    },
    'function': 'get_date_of_this_order_from_end()',
    'info': {
      'en': 'Gets the date in the xth position from the newest date in the column cells containing multiple date information.',
      'tr': 'Çoklu tarih bilgisini içeren kolon hücrelerindeki en yeni tarihten x. sıradaki tarihi alır.'
    },
    'example': 'get_date_of_this_order_from_end([col1], 1)'
  },
  {
    'label': 'tam sayıya yuvarla (round)',
    'translation': {
      'en': 'round to integer',
      'tr': 'tam sayıya yuvarla (round)',
    },
    'function': 'round()',
    'info': {
      'en': 'It is used to round a decimal numeric value to its integer part.',
      'tr': 'Ondalıklı bir sayısal değeri tam sayı kısmına yuvarlamak için kullanılmaktadır.'
    },
    'example': 'round(col1)'
  },
  {
    'label': 'tarih farkını ay olarak al (time_dif_months)',
    'translation': {
      'en': 'get date difference in months (time_dif_months)',
      'tr': 'tarih farkını ay olarak al (time_dif_months)',
    },
    'function': 'time_dif_months()',
    'info': {
      'en': 'If necessary, the difference in months is used by subtracting the second date from the first date.',
      'tr': 'Birinci tarihten, ikinci tarihi çıkartarak farkı ay cinsinden gerekiyorsa kullanılmaktadır.'
    },
    'example': 'time_dif_months(date1, date2)'
  },
  {
    'label': 'tarih farkını dakika olarak al (time_dif_minutes)',
    'translation': {
      'en': 'get date difference in minutes (time_dif_minutes)',
      'tr': 'tarih farkını dakika olarak al (time_dif_minutes)',
    },
    'function': 'time_dif_minutes()',
    'info': {
      'en': 'It is used if necessary by subtracting the second date from the first date and finding the difference in minutes.',
      'tr': 'Birinci tarihten, ikinci tarihi çıkartarak farkı dakika cinsinden gerekiyorsa kullanılmaktadır.'
    },
    'example': 'time_dif_minutes(date1, date2)'
  },
  {
    'label': 'tarih farkını gün olarak al (time_dif_days)',
    'translation': {
      'en': 'get date difference in days (time_dif_days)',
      'tr': 'tarih farkını gün olarak al (time_dif_days)',
    },
    'function': 'time_dif_days()',
    'info': {
      'en': 'If necessary, the difference in hours is used by subtracting the second date from the first date.',
      'tr': 'Birinci tarihten, ikinci tarihi çıkartarak farkı saat cinsinden gerekiyorsa kullanılmaktadır.'
    },
    'example': 'time_dif_days(date1, date2)'
  },
  {
    'label': 'tarih farkını saat olarak al (time_dif_hours)',
    'translation': {
      'en': 'get date difference as hours (time_dif_hours)',
      'tr': 'tarih farkını saat olarak al (time_dif_hours)',
    },
    'function': 'time_dif_hours()',
    'info': {
      'en': 'If necessary, the difference in hours is used by subtracting the second date from the first date.',
      'tr': 'Birinci tarihten, ikinci tarihi çıkartarak farkı saat cinsinden gerekiyorsa kullanılmaktadır.'
    },
    'example': 'time_dif_hours(date1, date2)'
  },
  {
    'label': 'tarih farkını saniye olarak al (time_dif_seconds)',
    'translation': {
      'en': 'get date difference in seconds (time_dif_seconds)',
      'tr': 'tarih farkını saniye olarak al (time_dif_seconds)',
    },
    'function': 'time_dif_seconds()',
    'info': {
      'en': 'It is used if necessary by subtracting the second date from the first date and finding the difference in seconds.',
      'tr': 'Birinci tarihten, ikinci tarihi çıkartarak farkı saniye cinsinden gerekiyorsa kullanılmaktadır.'
    },
    'example': 'time_dif_seconds(date1, date2)'
  },
  {
    'label': 'tarih farkını yıl olarak al (time_dif_years)',
    'translation': {
      'en': 'get date difference as years (time_dif_years)',
      'tr': 'tarih farkını yıl olarak al (time_dif_years)',
    },
    'function': 'time_dif_years()',
    'info': {
      'en': 'If necessary, the difference in years is used by subtracting the second date from the first date.',
      'tr': 'Birinci tarihten, ikinci tarihi çıkartarak farkı yıl cinsinden gerekiyorsa kullanılmaktadır.'
    },
    'example': 'time_dif_years(date1, date2)'
  },
  {
    'label': 'tarihi en eski olan veriyi al (date_min)',
    'translation': {
      'en': 'get the oldest data (date_min)',
      'tr': 'tarihi en eski olan veriyi al (date_min)',
    },
    'function': 'date_min()',
    'info': {
      'en': 'If there are multiple values in a column cell, the value of the oldest value can be used when creating a new column.',
      'tr': 'Bir kolona ait hücredeki değer çoklu ise, değerlerden tarihi en eski olanının değeri, yeni bir kolon oluştururken kullanılabilmektedir.'
    },
    'example': 'date_min(col1)'
  },
  {
    'label': 'tarihi en yeni olan veriyi al (date_max)',
    'translation': {
      'en': 'get the data with the latest date (date_max)',
      'tr': 'tarihi en yeni olan veriyi al (date_max)',
    },
    'function': 'date_max()',
    'info': {
      'en': 'If there are multiple values in a column cell, the value with the most recent date can be used when creating a new column.',
      'tr': 'Bir kolona ait hücredeki değer çoklu ise, değerlerden tarihi en yeni olanının değeri, yeni bir kolon oluştururken kullanılabilmektedir.'
    },
    'example': 'date_max(col1)'
  },
  {
    'label': 'tarihin ay sayısal değerini al (month_of_date_as_numeric)',
    'translation': {
      'en': 'get month numeric value of date (month_of_date_as_numeric)',
      'tr': 'tarihin ay sayısal değerini al (month_of_date_as_numeric)',
    },
    'function': 'month_of_date_as_numeric()',
    'info': {
      'en': 'Returns the month value of a date numerically.',
      'tr': 'Bir tarihin ay değerini sayısal olarak verir.'
    },
    'example': 'month_of_date_as_numeric(col1)'
  },
  {
    'label': 'tarihin ay yazı değerini al (month_of_date_as_text)',
    'translation': {
      'en': 'get month text value of date (month_of_date_as_text)',
      'tr': 'tarihin ay yazı değerini al (month_of_date_as_text)',
    },
    'function': 'month_of_date_as_text()',
    'info': {
      'en': 'Returns the month value of a date as text.',
      'tr': 'Bir tarihin ay değerini yazı olarak verir.'
    },
    'example': 'month_of_date_as_text(col1)'
  },
  {
    'label': 'tarihin gün değerini al (day_of_date)',
    'translation': {
      'en': 'get the day value of date (day_of_date)',
      'tr': 'tarihin gün değerini al (day_of_date)',
    },
    'function': 'day_of_date()',
    'info': {
      'en': 'Returns the day value of a date.',
      'tr': 'Bir tarihin gün değerini verir.'
    },
    'example': 'day_of_date(col1)'
  },
  {
    'label': 'tarihin gün yazı değerini al (day_of_date_as_text)',
    'translation': {
      'en': 'get the day text value of date (day_of_date_as_text)',
      'tr': 'tarihin gün yazı değerini al (day_of_date_as_text)',
    },
    'function': 'day_of_date_as_text()',
    'info': {
      'en': 'Returns the day value of a date as text.',
      'tr': 'Bir tarihin gün değerini yazı olarak verir.'
    },
    'example': 'day_of_date_as_text(col1)'
  },
  {
    'label': 'tarihin saat değerini al (hour_of_date)',
    'translation': {
      'en': 'get hour value of date (hour_of_date)',
      'tr': 'tarihin saat değerini al (hour_of_date)',
    },
    'function': 'hour_of_date()',
    'info': {
      'en': 'Returns the time value of a date.',
      'tr': 'Bir tarihin saat değerini verir.'
    },
    'example': 'hour_of_date(col1)'
  },
  {
    'label': 'tarihin yıl değerini al (year_of_date)',
    'translation': {
      'en': 'get the year value of date (year_of_date)',
      'tr': 'tarihin yıl değerini al (year_of_date)',
    },
    'function': 'year_of_date()',
    'info': {
      'en': 'Returns the year of a date.',
      'tr': 'Bir tarihin yıl değerini verir.'
    },
    'example': 'year_of_date(col1)'
  },
  {
    'label': 'tarihin yıl-ay-gün değerini al (year_month_day)',
    'translation': {
      'en': 'get the year-month-day value of the date (year_month_day)',
      'tr': 'tarihin yıl-ay-gün değerini al (year_month_day)',
    },
    'function': 'year_month_day()',
    'info': {
      'en': 'Returns the year-month-day value of a date.',
      'tr': 'Bir tarihin yıl-ay-gün değerini verir.'
    },
    'example': 'year_month_day(col1)'
  },
  {
    'label': 'x değerine tam bölünen daha büyük değere yuvarla (upper_round)',
    'translation': {
      'en': 'Round to the larger value that is evenly divided by x (upper_round)',
      'tr': 'x değerine tam bölünen daha büyük değere yuvarla (upper_round)',
    },
    'function': 'upper_round()',
    'info': {
      'en': 'Returns the smallest numeric expression that is equal to an integer multiple of a specified number (in the example of upper_round(col1, 10), this value is entered as 10) among integers greater than and equal to the value in a cell with an integer structure.',
      'tr': 'Tam sayı (integer) yapısına sahip bir hücredeki değerin, değerden büyük eşit olan tam sayılar arasından, belirtilen bir sayının (upper_round(col1, 10) örneğinde bu değer 10 olarak girilmiş) tam katına eşit en küçük sayısal ifadeyi verir.'
    },
    'example': 'upper_round(col1, 10)'
  },
  {
    'label': 'x değerine tam bölünen daha küçük ilk değere yuvarla (lower_round)',
    'translation': {
      'en': 'Round to the first smaller value that is evenly divided by x (lower_round)',
      'tr': 'x değerine tam bölünen daha küçük ilk değere yuvarla (lower_round)',
    },
    'function': 'lower_round()',
    'info': {
      'en': 'Returns the largest numeric expression equal to an integer multiple of a specified number (in the example lower_round(col1, 10), this value is entered as 10) among integers that are less than or equal to the value in a cell with an integer structure.',
      'tr': 'Tam sayı (integer) yapısına sahip bir hücredeki değerin, değerden küçük eşit olan tam sayılar arasından, belirtilen bir sayının (lower_round(col1, 10) örneğinde bu değer 10 olarak girilmiş) tam katına eşit en büyük sayısal ifadeyi verir.'
    },
    'example': 'lower_round(col1, 10)'
  },
  {
    'label': 'x değerine tam bölünen daha küçük veya büyük en yakın ilk değere yuvarla (closer_round)',
    'translation': {
      'en': 'Round to the nearest smaller or larger value that is divisible by x (closer_round)',
      'tr': 'x değerine tam bölünen daha küçük veya büyük en yakın ilk değere yuvarla (closer_round)',
    },
    'function': 'closer_round()',
    'info': {
      'en': 'Returns the numerical expression that is equal to an integer multiple of a specified number among the numbers that are equal, greater or less than the value in a cell with an integer structure and that has the least difference between the new value and the current value.',
      'tr': 'Tam sayı (integer) yapısına sahip bir hücredeki değerin, değere eşit, değerden büyük ya da küçük olan sayılar arasından, belirtilen bir sayının tam katına eşit olan ve yeni değer ile mevcut değer arasındaki farkın en az olduğu sayısal ifadeyi verir.'
    },
    'example': 'closer_round(col1, 10)'
  },
  {
    'label': 'çoklu veriyi tekil olarak al (unique_data_list)',
    'translation': {
      'en': 'get multiple data as unique (unique_data_list)',
      'tr': 'çoklu veriyi tekil olarak al (unique_data_list)',
    },
    'function': 'unique_data_list()',
    'info': {
      'en': 'Retrieves the data in multiple selected cells containing the same data individually.',
      'tr': 'Seçilen çoklu aynı veriyi içeren hücredeki verileri tekil olarak alır.'
    },
    'example': 'unique_data_list(col1)'
  },
  {
    'label': 'şimdiye ait tarihi al (now)',
    'translation': {
      'en': 'get the current date (now)',
      'tr': 'şimdiye ait tarihi al (now)',
    },
    'function': 'now()',
    'info': {
      'en': 'The current historical value is used in the function when necessary.',
      'tr': 'Şimdiye ait tarihsel değeri fonksiyonda gerektiğinde kullanılmaktadır.'
    },
    'example': 'now()'
  },
  {
    'label': 'Birinci kolon verisini, ikinci kolon verisi içeriyor mu (include)',
    'translation': {
      'en': 'Does the first column data include the second column data?',
      'tr': 'Birinci kolon verisini, ikinci kolon verisi içeriyor mu (include)',
    },
    'function': 'include()',
    'info': {
      'en': 'The current historical value is used in the function when necessary.',
      'tr': 'Şimdiye ait tarihsel değeri fonksiyonda gerektiğinde kullanılmaktadır.'
    },
    'example': 'include([col1], [col2], "yes", "no")'
  },
  {
    'label': 'Hücre içindeki veri adedi (count_of())',
    'translation': {
      'en': 'Number of data in the cell (count_of())',
      'tr': 'Hücre içindeki veri adedi (count_of())',
    },
    'function': 'count_of()',
    'info': {
      'en': 'Number of data in the cell.',
      'tr': 'Hücre içindeki veri adedi.'
    },
    'example': 'count_of(col1)'
  },
  {
    'label': 'Hücre içindeki veri adedi (cell_value())',
    'translation': {
      'en': 'Number of data in the cell (cell_value())',
      'tr': 'Hücre içindeki veri adedi (cell_value())',
    },
    'function': 'cell_value()',
    'info': {
      'en': 'Number of data in the cell.',
      'tr': 'Hücre içindeki veri adedi.'
    },
    'example': 'cell_value(col1)'
  }
];

export { data };
