var data = [
  {
    'value': 'is_not_null',
    'translation': {
      'tr': 'hücre boş değil',
      'en': 'cell is not null'
    }
  },
  {
    'value': 'is_null',
    'translation': {
      'tr': 'hücre boş',
      'en': 'cell is null'
    }
  },
  {
    'value': '=',
    'translation': {
      'tr': 'eşittir',
      'en': 'equal to'
    }
  },
  {
    'value': '<>',
    'translation': {
      'tr': 'eşit değildir',
      'en': 'not equal to'
    }
  },
  {
    'value': '>',
    'translation': {
      'tr': 'büyüktür',
      'en': 'bigger than'
    }
  },
  {
    'value': '>=',
    'translation': {
      'tr': 'büyük eşittir',
      'en': 'bigger than and equal to'
    }
  },
  {
    'value': '<',
    'translation': {
      'tr': 'küçüktür',
      'en': 'smaller than'
    }
  },
  {
    'value': '<=',
    'translation': {
      'tr': 'küçük eşittir',
      'en': 'smaller than and equal to'
    }
  },
  {
    'value': 'in',
    'translation': {
      'tr': 'i̇çeriyor',
      'en': 'i̇ncludes'
    }
  },
  {
    'value': 'notin',
    'translation': {
      'tr': 'i̇çermiyor',
      'en': 'not includes'
    }
  }
];

export { data };
