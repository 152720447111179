var operator = [{
    'label_wdm16_id': '13276',
    'label': 'Değere bakma',
    'value': '',
    'translation': {
      'tr': 'Değere bakma',
      'en': 'Value not important'
    },
    'info_wdm16_id': '13277',
    'info': 'Değere bakma olarak belirlendiğinde kural motorunda ilgili seçili lokasyona kadar varlığın kontrolü yapılır. Karşılığındaki değere bakılmaz. Belirlenen lokasyon var ise doğru kabul edilir',
    'param_types': ['radiogroup', 'checkbox', 'select', 'integer', 'float', 'textarea', 'text', 'date', 'time', 'datetime', 'plain_text']
  }, {
    'label_wdm16_id': '13278',
    'label': 'Eşittir',
    'value': '=',
    'translation': {
      'tr': 'Eşittir',
      'en': 'Equal to'
    },
    'info_wdm16_id': '13279',
    'info': 'Kayıtlı parametrenin değerinin burada belirlenen değere ya da değerlerden birine eşit olup olmadığına bakar. Eşitse doğru kabul edilir.',
    'param_types': ['radiogroup', 'checkbox', 'select', 'integer', 'float', 'textarea', 'text', 'date', 'time', 'datetime', 'plain_text']
  },
  {
    'label_wdm16_id': '13280',
    'label': 'Eşit Değildir',
    'value': '<>',
    'translation': {
      'tr': 'Eşit Değildir',
      'en': 'Not Equal to'
    },
    'info_wdm16_id': '13281',
    'info': 'Kayıtlı parametrenin değerinin burada belirlenen değere ya da değerlerden birine eşit olup olmadığına bakar. Eşit değilse doğru kabul edilir.',
    'param_types': ['radiogroup', 'checkbox', 'select', 'integer', 'float', 'textarea', 'text', 'date', 'time', 'datetime', 'plain_text']
  },
  {
    'label_wdm16_id': '13282',
    'label': 'Büyüktür',
    'value': '>',
    'translation': {
      'tr': 'Büyüktür',
      'en': 'Bigger than'
    },
    'info_wdm16_id': '13283',
    'info': 'Kayıtlı parametrenin değerinin burada belirlenen değerden büyük olup olmadığına bakar. Büyük ise doğru kabul edilir.',
    'param_types': ['integer', 'float', 'date', 'time', 'datetime', 'select', 'radiogroup']
  },
  {
    'label_wdm16_id': '13284',
    'label': 'Büyük eşittir',
    'value': '>=',
    'translation': {
      'tr': 'Büyük eşittir',
      'en': 'Bigger than and equal to'
    },
    'info_wdm16_id': '13285',
    'info': 'Kayıtlı parametrenin değerinin burada belirlenen değerden büyük veya eşit olup olmadığına bakar. Büyük veya eşit ise doğru kabul edilir.',
    'param_types': ['integer', 'float', 'date', 'time', 'datetime', 'select', 'radiogroup']
  },
  {
    'label_wdm16_id': '13286',
    'label': 'Küçüktür',
    'value': '<',
    'translation': {
      'tr': 'Küçüktür',
      'en': 'Smaller than'
    },
    'info_wdm16_id': '13287',
    'info': 'Kayıtlı parametrenin değerinin burada belirlenen değerden küçük olup olmadığına bakar. Küçük ise doğru kabul edilir.',
    'param_types': ['integer', 'float', 'date', 'time', 'datetime', 'select', 'radiogroup']
  },
  {
    'label_wdm16_id': '13288',
    'label': 'Küçük eşittir',
    'value': '<=',
    'translation': {
      'tr': 'Küçük eşittir',
      'en': 'Smaller than and equal to'
    },
    'info_wdm16_id': '13289',
    'info': 'Kayıtlı parametrenin değerinin burada belirlenen değerden küçük veya eşit olup olmadığına bakar. Küçük veya eşit ise doğru kabul edilir.',
    'param_types': ['integer', 'float', 'date', 'time', 'datetime', 'select', 'radiogroup']
  },
  {
    'label_wdm16_id': '13290',
    'label': 'İçeriyor',
    'value': 'in',
    'translation': {
      'tr': 'İçeriyor',
      'en': 'İncludes'
    },
    'info_wdm16_id': '13291',
    'info': 'Kayıtlı parametrenin değerinin burada belirlenen değeri içerip içermediğine bakar. İçeriyorsa doğru kabul edilir. Kontrolü yazı olarak yapar. İçinde o yazı geçiyorsa anlamında',
    'param_types': ['textarea', 'text', 'plain_text', 'select', 'radiogroup', 'checkbox']
  },
  {
    'label_wdm16_id': '13292',
    'label': 'İçermiyor',
    'value': 'notin',
    'translation': {
      'tr': 'İçermiyor',
      'en': 'Not includes'
    },
    'info_wdm16_id': '13293',
    'info': 'Kayıtlı parametrenin değerinin burada belirlenen değeri içerip içermediğine bakar. İçermiyorsa doğru kabul edilir. Kontrolü yazı olarak yapar. İçinde o yazı geçiyorsa anlamında',
    'param_types': ['textarea', 'text', 'plain_text', 'select', 'radiogroup', 'checkbox']
  }
];

export { operator as options };
