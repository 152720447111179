<script>
import { ClsWdm } from '@/wam/modules/wdm';
import Vue from 'vue';
import { mapGetters } from 'vuex';

var templateRenderFns = [];
export default {
  name: 'WdmParameterSelect',
  render (h) {
    return h('div', [
      (this.template ? this.template() : '')
    ]);
  },
  computed: mapGetters({
    StoreLang: 'StoreLang',
    StoreLangTranslation: 'StoreLangTranslation',
  }),
  staticRenderFns: templateRenderFns,
  components: {},
  props: {
    p_fastAnalyzeMode: {
      type: Boolean,
      required: false
    },
    p_isPolestarColumn: {
      type: Boolean,
      required: false
    },
    mother_children: {
      type: Object,
      required: true
    },
    option_data: {
      type: Object,
      required: true
    },
    p_wdmParameterSelectData: {
      type: Array,
      required: true
    },
    p_wdmList: {
      type: Array,
      required: true
    },
    p_wdmRuleAsettings: {
      type: Object,
      required: true
    }
  },
  created: function () {
    this.render_template();
    if (this.p_wdmRuleAsettings.mode === 'param_query') {
      if (this.p_wdmParameterSelectData.length === 0) {
        this.f_addNewWps();
      }
    }
  },
  mounted () {},
  data () {
    return {
      d_showOptionValues: false,
      d_currentPaginationEligible: true,
      operator: require('@/wam/options/operator').options,
      d_level: 0,
      d_levelCount: 10,
      anatomy_types: { 'any': 'herhangi', 'this': 'bu antomi', 'parent': 'anatomi ailesi' },
      d_selectedWpsIndex: '',
      d_showOptions: {
        'show_pagination': true,
        'timeout': 0,
        'pagination': {
          'start': 0,
          'end': 1,
          'current': 1,
          'perpage': 1,
          'total_count': 1,
        },
        'show': false,
        'list': [],
        'search_list': [],
      },
      d_showManuelOptions: {
        'new_value': '',
        'wps_ind': '',
        'param_data': '',
        'show': false,
      },
      d_showFieldSignificanceSelectionModal: { 'show': false },
      d_searchOption: '',
    };
  },
  methods: {
    render_template: function (clear_loc = false, wps_ind = '') {
      let html = '';
      html += '<template v-for="(wps, wps_ind) in p_wdmParameterSelectData">';
      html += '<div class="wps_row">';
      html += '<b-button v-if="p_wdmRuleAsettings.mode !== \'param_query\'" variant="outline-danger" size="md" @click="f_deleteWps(wps_ind)">-</b-button>';
      html += '<select v-model="wps.selected_wdm" :disabled="f_wpsSelectedWdmControl(wps_ind)" v-on:change="f_selectedWdmChange(wps_ind)" class="data_type_cls"><option v-for="(opt, opt_ind) in p_wdmList" :value="opt" style="width: 200px; height: 30px;" :title="opt.label">{{ f_showWdmOptionLabel(opt.label) }}</option></select>';
      this.d_level = 0;
      this.d_levelCount = 0;
      // We calculate again to get the maximum value of level in all wdm list that we are using.
      for (let w in this.p_wdmParameterSelectData) {
        let selected_wdm = this.p_wdmParameterSelectData[w].selected_wdm;
        // console.log('selected_wdm', JSON.stringify(selected_wdm));
        this.p_wdmParameterSelectData[w].d_level = 0;
        if (selected_wdm && selected_wdm.type && this.option_data[selected_wdm.type]) {
          // Eğer bir adet grup varsa bu durumda zaten başka bir grup olamayacağı için direkt ekleme yapıyoruz.
          let param_group_count = this.option_data[selected_wdm.type].param_group.param_order.length;
          this.p_wdmParameterSelectData[w].param_group_count = param_group_count;
          let group_item = '';
          if (param_group_count === 1) {
            let group_value = this.option_data[selected_wdm.type].param_group.param_order[0];
            group_item = this.option_data[selected_wdm.type].param_group.parameters[group_value];
            group_item.type = 'group';
          }
          // console.log('param_group_count  : ', param_group_count);
          // console.log('group_item         : ', group_item);
          let max_deeper_value = ClsWdm.maxDeeperLevel(this.option_data[selected_wdm.type]);
          max_deeper_value = (max_deeper_value * 2) + 1;
          if (max_deeper_value) {
            this.p_wdmParameterSelectData[w].d_level_count = max_deeper_value;
            if (max_deeper_value > this.d_levelCount) {
              this.d_levelCount = max_deeper_value;
            }
          }
          if (clear_loc) {
            if (wps_ind === '' || (wps_ind !== '' && parseInt(w) === wps_ind)) {
              // HERE WE ARE CLEARING THE PARAMETER LIST IN LOC. SO WE MUST BE CAREFUL WHEN WE CALL render_template function.
              this.p_wdmParameterSelectData[w].loc.splice(0, this.p_wdmParameterSelectData[w].loc.length);
              for (let i = 0; i < this.p_wdmParameterSelectData[w].d_level_count; i++) {
                // WHEN we are creating column algoritms, we know the parameters. So we automatically select them.
                if (this.p_fastAnalyzeMode) {
                  if (i === 0) {
                    let x1 = { 'label': 'Genel', 'value': 'general', 'type': 'group' };
                    this.p_wdmParameterSelectData[w].loc.push(x1);
                  } else if (i === 1) {
                    // param val is equal to wdm type !!!
                    let param_val = this.p_wdmParameterSelectData[w].selected_wdm.value;
                    let x2 = {
                      'label': this.option_data[param_val].general.parameters[param_val].name.label,
                      'value': this.option_data[param_val].general.parameters[param_val].name.value,
                      'type': this.option_data[param_val].general.parameters[param_val].type
                    };
                    this.p_wdmParameterSelectData[w].loc.push(x2);
                  } else {
                    this.p_wdmParameterSelectData[w].loc.push('');
                  }
                  this.p_wdmParameterSelectData[w].operation = '=';
                } else {
                  if (i === 0) {
                    if (group_item !== '') {
                      this.p_wdmParameterSelectData[w].loc.push(group_item);
                    } else {
                      this.p_wdmParameterSelectData[w].loc.push('');
                    }
                  } else {
                    this.p_wdmParameterSelectData[w].loc.push('');
                  }
                }
              }
            }
          }
          // console.log('this.d_levelCount: ', this.d_levelCount);
        }
      }
      html += this.build_form();
      html += '<b-button v-if="p_isPolestarColumn && p_wdmRuleAsettings.mode === \'param\'" :variant="wps.is_column ? \'warning\' : \'white\'" :title="$t(\'wdm16.11239\')" size="md" @click="f_selectPolestarColumn(wps_ind)">&</b-button>';
      html += '</div>';
      html += '</template>';
      html += '<b-button v-if="p_wdmRuleAsettings.mode !== \'param_query\'" @click="f_addNewWps()" variant="white" size="md">+</b-button>';
      html += this.f_createOtherHtml();
      html = '<div>' + html + '</div>';
      // console.log(html);
      let compiled_template = Vue.compile(html);
      this.template = compiled_template.render;
      templateRenderFns.length = 0;
      for (var i in compiled_template.staticRenderFns) {
        templateRenderFns.push(compiled_template.staticRenderFns[i]);
      }
      this.$forceUpdate();
    },
    f_showWdmOptionLabel: function (option_label) {
      if (option_label.indexOf('->') !== -1) {
        let x = option_label.split('->');
        return x[x.length - 1];
      } else {
        return option_label;
      }
    },
    f_wpsSelectedWdmControl: function (wps_ind) {
      if (this.p_wdmRuleAsettings.mode === 'param') {
        if (this.p_isPolestarColumn && this.p_wdmParameterSelectData.length > 1) {
          return true;
        }
      }
      return false;
    },
    f_deleteWps: function (wps_ind) {
      this.p_wdmParameterSelectData.splice(wps_ind, 1);
      this.$forceUpdate();
    },
    f_addNewWps: function () {
      let new_wps = {
        'operation': '',
        'selected_value': '',
        'loc': [],
        'val_list': [],
        'selected_wdm': '',
        'd_level': 0,
        'd_level_count': 0,
        'significant': 'true'
      };
      // WHEN WE ADD A PARAMETER FIRST TIME IN this.p_isPolestarColumn True, we add is_column. Because at least one of wps must be a column parameter.
      if (this.p_isPolestarColumn) {
        if (this.p_wdmParameterSelectData.length === 0) {
          new_wps.is_column = 1;
        } else {
          new_wps.selected_wdm = this.p_wdmParameterSelectData[0].selected_wdm;
        }
      }
      this.p_wdmParameterSelectData.push(new_wps);
      this.render_template(true, this.p_wdmParameterSelectData.length - 1);
      this.$forceUpdate();
    },
    f_deleteValList: function () {
      this.p_wdmParameterSelectData[this.d_selectedWpsIndex].val_list = [];
      this.$forceUpdate();
    },
    f_createOtherHtml: function () {
      let html = '';
      // Manuel Option selection modal
      html += '<b-modal v-if="d_selectedWpsIndex !== \'\' && d_showManuelOptions.show" id="d_showManuelOptions.show" v-model="d_showManuelOptions.show" :title="$t(\'wdm16.11240\')" centered header-bg-variant="info" header-text-variant="dark" size="lg">';
      html += '<b-row>';
      html += '   <b-col cols="12">';
      html += '      <b-row style="margin: 0px;">';
      html += '         <b-col cols="6">';
      html += '            <template v-if="[\'integer\', \'float\'].indexOf(d_showManuelOptions.param_data.type) !== -1">';
      html += '               <input type="number" class="input_cls" style="width: 100%" :placeholder="$t(\'wdm16.11244\')" v-model="d_showManuelOptions.new_value">';
      html += '            </template>';
      html += '            <template v-else-if="[\'text\', \'textarea\', \'plain_text\'].indexOf(d_showManuelOptions.param_data.type) !== -1">';
      html += '               <input type="text" class="input_cls" style="width: 100%" :placeholder="$t(\'wdm16.11244\')" v-model="d_showManuelOptions.new_value">';
      html += '            </template>';
      html += '            <template v-else-if="[\'date\'].indexOf(d_showManuelOptions.param_data.type) !== -1">';
      html += '               <input type="date" class="input_cls" style="width: 100%" :placeholder="$t(\'wdm16.11244\')" v-model="d_showManuelOptions.new_value">';
      html += '            </template>';
      html += '            <template v-else-if="[\'datetime\'].indexOf(d_showManuelOptions.param_data.type) !== -1">';
      html += '               <input type="date" class="input_cls" style="width: 100%" :placeholder="$t(\'wdm16.11244\')" v-model="d_showManuelOptions.new_value">';
      html += '            </template>';
      html += '         </b-col>';
      html += '         <b-col cols="3">';
      html += '            <b-button @click="f_addManuelOptionToValList()" variant="outline-success"><span style="color: black;"> ekle </span></b-button>';
      html += '         </b-col>';
      html += '         <b-col cols="3">';
      html += '            <b-button @click="f_deleteValList()" variant="outline-danger"><span style="color: white;"> tümünü sil </span></b-button>';
      html += '         </b-col>';
      html += '      </b-row>';
      html += '      <div style="overflow-x: hidden; overflow-y: auto; height: 400px;">';
      html += '         <b-row v-for="(sel_val, sel_val_ind) in p_wdmParameterSelectData[d_selectedWpsIndex].val_list" style="border-bottom: solid 1px green; margin-bottom: 3px;" :key="\'sel_val_\' + sel_val_ind">';
      html += '            <b-col cols="10">';
      html += '               {{ sel_val_ind + 1 }} ) ';
      html += '               {{ sel_val }}';
      // html += '               {{ sel_val.label }}';
      // html += '               <small v-if="d_showOptionValues" style="color: green;">[{{ sel_val.value }}]</small>';
      html += '            </b-col>';
      html += '            <b-col cols="2">';
      html += '               <b-button size="sm" @click="f_deleteVal(sel_val_ind)" variant="outline-danger">sil</b-button>';
      html += '            </b-col>';
      html += '         </b-row>';
      html += '      </div>';
      html += '   </b-col>';
      html += '</b-row>';
      html += '<template slot="modal-footer">';
      html += '   <b-button @click="d_showManuelOptions.show = false" variant="outline-primary">{{ $t(\'wdm16.4053\') }}</b-button>';
      html += '</template>';
      html += '</b-modal>';

      // option selection modal
      html += '<b-modal v-if="d_selectedWpsIndex !== \'\' && d_showOptions.show" id="d_showOptions.show" v-model="d_showOptions.show" :title="$t(\'wdm16.11240\')" centered header-bg-variant="info" header-text-variant="dark" size="xl">';
      html += '<b-row>';
      html += '   <b-col cols="6">';
      html += '      <b-row>';
      html += '         <b-col cols="6" title="search">';
      html += '            <b-form-input v-model="d_searchOption" placeholder="search in labels and values">';
      html += '            </b-form-input>';
      html += '         </b-col>';
      html += '         <b-col cols="3">';
      // html += '            {{ d_searchOption ? d_showOptions.search_list.length : d_showOptions.list.length }}';
      html += '         </b-col>';
      html += '         <b-col cols="3">';
      html += '            {{ d_showOptions.pagination.current }}';
      html += '            /';
      html += '            {{ d_showOptions.pagination.total_count }}';
      html += '         </b-col>';
      html += '      </b-row>';
      html += '      <b-row>';
      html += '         <b-col cols="12">';
      html += '            <b-button v-if="d_showOptions.search_list.length > 0" @click="f_addAllOptions()" variant="outline-success"> <i class="fa fa-plus"></i> {{ StoreLangTranslation.data["add_all_filtered_options"][StoreLang] }} </b-button>';
      html += '         </b-col>';
      html += '      </b-row>';
      html += '      <b-row>';
      html += '         <b-col cols="4">';
      html += '            <b-form-checkbox v-model="d_showOptionValues"> value göster </b-form-checkbox>';
      html += '         </b-col>';
      html += '         <b-col cols="8">';
      html += '            <b-pagination v-if="d_showOptions.show_pagination" size="sm" class="pull-right" style="margin-bottom: 0px;" aria-controls="column_pagination" v-model="d_showOptions.pagination.current" :total-rows="d_showOptions.pagination.total_count" :per-page="d_showOptions.pagination.perpage"></b-pagination>';
      html += '         </b-col>';
      html += '      </b-row>';
      html += '      <div v-if="d_searchOption && d_showOptions.search_list.length > 0" @click="$forceUpdate()" style="overflow-x: hidden; overflow-y: auto; height: 400px;">';
      html += '         <b-row>';
      html += '            <b-col cols="12">';
      html += '               <b-form-checkbox-group v-model="p_wdmParameterSelectData[d_selectedWpsIndex].val_list" stacked>';
      html += '                  <template v-for="(opt, opt_ind) in d_showOptions.search_list">';
      // html += '                     <b-form-checkbox v-if="f_controlSearchOptionText(opt.label) && opt_ind >= d_showOptions.pagination.start && opt_ind <= d_showOptions.pagination.end" :key="\'check_\' + opt_ind.toString()" :value="opt"> {{ opt.label }} </b-form-checkbox>';
      html += '                     <b-form-checkbox v-if="(opt_ind >= d_showOptions.pagination.start && opt_ind <= d_showOptions.pagination.end)" :key="\'check_\' + opt_ind.toString()" :value="opt"> {{ f_getOptLabel(opt) }} <small v-if="d_showOptionValues" style="color: green;">[{{ opt.value }}]</small> </b-form-checkbox>';
      html += '                  </template>';
      html += '               </b-form-checkbox-group>';
      html += '            </b-col>';
      html += '         </b-row>';
      html += '      </div>';
      html += '      <div v-if="!d_searchOption" style="overflow-x: hidden; overflow-y: auto; height: 400px;">';
      html += '         <b-row @click="$forceUpdate()">';
      html += '            <b-col cols="12">';
      html += '               <b-form-checkbox-group v-model="p_wdmParameterSelectData[d_selectedWpsIndex].val_list" stacked>';
      html += '                  <template v-for="(opt, opt_ind) in d_showOptions.list">';
      // html += '                     <b-form-checkbox v-if="opt_ind >= d_showOptions.pagination.start && opt_ind <= d_showOptions.pagination.end" :key="\'check_\' + opt_ind.toString()" :value="opt"> {{ opt.label }} </b-form-checkbox>';
      html += '                     <b-form-checkbox v-if="d_showOptions.options_list_id_count || (opt_ind >= d_showOptions.pagination.start && opt_ind <= d_showOptions.pagination.end)" :key="\'check_\' + opt_ind.toString()" :value="opt"> {{ f_getOptLabel(opt) }} <small v-if="d_showOptionValues" style="color: green;">[{{ opt.value }}]</small> </b-form-checkbox>';
      html += '                  </template>';
      html += '               </b-form-checkbox-group>';
      html += '            </b-col>';
      html += '         </b-row>';
      html += '      </div>';
      html += '   </b-col>';
      html += '   <b-col cols="6">';
      html += '      <b-row style="margin: 0px;">';
      html += '         <b-col cols="6">';
      html += '            <strong> Seçili Seçenekler </strong>';
      html += '            {{ p_wdmParameterSelectData[d_selectedWpsIndex].val_list.length }}';
      html += '         </b-col>';
      html += '         <b-col cols="6" style="text-align: right;">';
      html += '            <b-button @click="f_deleteValList()" variant="outline-danger"><span style="color: red;"> tümünü sil </span></b-button>';
      html += '         </b-col>';
      html += '      </b-row>';
      html += '      <div style="overflow-x: hidden; overflow-y: auto; height: 400px;">';
      html += '         <b-row v-for="(sel_val, sel_val_ind) in p_wdmParameterSelectData[d_selectedWpsIndex].val_list" style="border-bottom: solid 1px green; margin-bottom: 3px;" :key="\'sel_val_\' + sel_val_ind">';
      html += '            <b-col cols="10">';
      html += '               {{ sel_val_ind + 1 }} ) ';
      html += '               {{ f_getOptLabel(sel_val) }}';
      html += '               <small v-if="d_showOptionValues" style="color: green;">[{{ sel_val.value }}]</small>';
      html += '            </b-col>';
      html += '            <b-col cols="2">';
      html += '               <b-button size="sm" @click="f_deleteVal(sel_val_ind)" variant="outline-danger"><i class="fa fa-trash"></i></b-button>';
      html += '            </b-col>';
      html += '         </b-row>';
      html += '      </div>';
      html += '   </b-col>';
      html += '</b-row>';
      html += '<template slot="modal-footer">';
      html += '   <b-button @click="d_showOptions.show = false" variant="outline-primary">{{ $t(\'wdm16.4053\') }}</b-button>';
      html += '</template>';
      html += '</b-modal>';

      // Significance selection modal
      html += '<b-modal v-if="d_selectedWpsIndex !== \'\' && d_showFieldSignificanceSelectionModal.show" id="d_showFieldSignificanceSelectionModal.show" v-model="d_showFieldSignificanceSelectionModal.show" :title="$t(\'wdm16.11240\')" centered header-bg-variant="info" header-text-variant="dark">';
      html += '<b-row>';
      html += '   <b-col cols="12">';
      html += '      <b-form-select v-model="p_wdmParameterSelectData[d_selectedWpsIndex].significant">';
      html += '         <option value="true">true</option>';
      html += '         <option value="false">false</option>';
      // html += '         <option value="false_reached">{{ $t(\'wdm16.13109\') }}</option>';
      html += '      </b-form-select>';
      html += '   </b-col>';
      html += '</b-row>';
      html += '<template slot="modal-footer">';
      html += '   <b-button @click="d_showFieldSignificanceSelectionModal.show = false" variant="outline-primary">{{ $t(\'wdm16.4053\') }}</b-button>';
      html += '</template>';
      html += '</b-modal>';
      return html;
    },
    f_addAllOptions: function () {
      if (this.d_showOptions.search_list.length > 0) {
        for (let i in this.d_showOptions.search_list) {
          this.p_wdmParameterSelectData[this.d_selectedWpsIndex].val_list.push(this.d_showOptions.search_list[i]);
        }
      }
      this.$forceUpdate();
    },
    f_deleteVal: function (sel_val_ind) {
      this.p_wdmParameterSelectData[this.d_selectedWpsIndex].val_list.splice(sel_val_ind, 1);
      this.$forceUpdate();
    },
    f_controlSearchOptionText: function (option_label) {
      if (this.d_searchOption) {
        if (option_label.toLocaleLowerCase().indexOf(this.d_searchOption.toLocaleLowerCase()) !== -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    f_getOptLabel: function (opt) {
      // console.log('opt : ', opt);
      if (opt.translation && opt.translation[this.StoreLang]) {
        return opt.translation[this.StoreLang];
      } else {
        return opt.label;
      }
    },
    build_form: function () {
      // console.log('build_form');
      let html = '';
      html += '<template v-if="wps.selected_wdm && option_data && option_data[wps.selected_wdm.type]">';
      html += this.f_turnOverParameter();
      html += '</template>';
      html += '<template v-if="f_controlLastSelectedParamType(wps_ind)">';
      html += '   <select v-model="wps.operation" v-on:change="$forceUpdate()" class="operation_cls"><option v-for="(opt, opt_ind) in operator" :value="opt.value" :title="$t(\'wdm16.\' + opt.info_wdm16_id)">{{ $t(\'wdm16.\' + opt.label_wdm16_id) }} </option></select>';
      html += '   <b-button :variant="wps.significant === \'true\' ? \'outline-success\' : \'danger\'" size="md" @click="f_changeFieldSignificance(wps_ind)" :title="f_calculateSignificanceTitle(wps.significant)">+/-</b-button>';
      html += '   <template v-if="wps.operation === \'in\' || wps.operation === \'notin\'">';
      // html += '      <input type="text" class="input_cls" :placeholder="$t(\'wdm16.11241\')" v-model="wps.selected_value">';
      html += '      <b-button variant="outline-success" size="md" @click="f_showManuelOptionSelect(wps_ind)" title="options">{{ wps.val_list.length }}</b-button>';
      html += '      <span> {{ f_showSelectedValList(wps_ind) }} </span>';
      html += '   </template>';
      html += '   <template v-else-if="[\'>\', \'>=\', \'<\', \'<=\'].indexOf(wps.operation) !== -1">';
      html += '      <template v-if="[\'date\', \'datetime\'].indexOf(f_controlLastSelectedParamType(wps_ind)) !== -1">';
      html += '         <input type="date" class="input_cls" :placeholder="$t(\'wdm16.11242\')" v-model="wps.selected_value">';
      html += '      </template>';
      html += '      <template v-else>';
      html += '         <input type="number" class="input_cls" :placeholder="$t(\'wdm16.11243\')" v-model="wps.selected_value">';
      html += '      </template>';
      html += '   </template>';
      html += '   <template v-else-if="[\'<>\', \'=\'].indexOf(wps.operation) !== -1">';
      html += '      <template v-if="[\'select\', \'radiogroup\', \'checkbox\'].indexOf(f_controlLastSelectedParamType(wps_ind)) !== -1">';
      html += '         <b-button variant="outline-success" size="md" @click="f_showOptionSelect(wps_ind)" title="options">{{ wps.val_list.length }}</b-button>';
      html += '         <span> {{ f_showSelectedValList(wps_ind) }} </span>';
      html += '      </template>';
      html += '      <template v-else-if="[\'integer\', \'float\', \'text\', \'textarea\', \'plain_text\', \'date\', \'datetime\'].indexOf(f_controlLastSelectedParamType(wps_ind)) !== -1">';
      html += '         <b-button variant="outline-success" size="md" @click="f_showManuelOptionSelect(wps_ind)" title="options">{{ wps.val_list.length }}</b-button>';
      html += '         <span> {{ f_showSelectedValList(wps_ind) }} </span>';
      html += '      </template>';
      /*
      html += '      <template v-else-if="[\'integer\', \'float\'].indexOf(f_controlLastSelectedParamType(wps_ind)) !== -1">';
      html += '         <input type="number" class="input_cls" :placeholder="$t(\'wdm16.11244\')" v-model="wps.selected_value">';
      html += '      </template>';
      html += '      <template v-else-if="[\'text\', \'textarea\', \'plain_text\'].indexOf(f_controlLastSelectedParamType(wps_ind)) !== -1">';
      html += '         <input type="text" class="input_cls" :placeholder="$t(\'wdm16.11244\')" v-model="wps.selected_value">';
      html += '      </template>';
      html += '      <template v-else-if="[\'date\'].indexOf(f_controlLastSelectedParamType(wps_ind)) !== -1">';
      html += '         <input type="date" class="input_cls" :placeholder="$t(\'wdm16.11244\')" v-model="wps.selected_value">';
      html += '      </template>';
      html += '      <template v-else-if="[\'datetime\'].indexOf(f_controlLastSelectedParamType(wps_ind)) !== -1">';
      html += '         <input type="date" class="input_cls" :placeholder="$t(\'wdm16.11244\')" v-model="wps.selected_value">';
      html += '      </template>';
      */
      html += '   </template>';
      html += '</template>';
      return html;
    },
    f_turnOverParameter: function () {
      this.d_level += 1;
      // let label_value = this.f_labelValue();
      // let v_if_func = this.f_vIfFunction();
      let html = '';
      html += '<template v-if="f_controlSelectAvailibility(wps_ind, ' + this.d_level.toString() + ')">';
      html += '   <template v-if="f_isSelectDisabled(' + (this.d_level - 1).toString() + ', wps_ind)">';
      html += '      <select :key="\'select_\' + ' + this.d_level.toString() + ' + \'_\' + ' + 'wps_ind' + '" v-model="wps.loc[' + (this.d_level - 1).toString() + ']" v-on:change="f_selectLoc(wps_ind)" class="loc_cls" :title="wps.loc[' + (this.d_level - 1).toString() + '] ? wps.loc[' + (this.d_level - 1).toString() + '].label : \'\'">';
      html += '         <option value=""></option>';
      html += '         <option v-for="(opt, opt_ind) in f_calculateLocOptions(wps_ind, ' + this.d_level.toString() + ', \'get_options\')" :value="opt"> {{ opt.label }} </option>';
      html += '      </select>';
      html += '      <select v-if="' + this.d_level.toString() + '=== 1 && wps.loc[0] && wps.loc[0].value === \'anatomy\'" :key="\'select_anatomy\' + ' + this.d_level.toString() + ' + \'_\' + ' + 'wps_ind' + '" v-model="wps.anatomy_use" class="loc_cls">';
      html += '         <option value="any">{{ $t(\'wdm16.11245\') }}</option>';
      html += '         <option value="this">{{ $t(\'wdm16.3762\') }}</option>';
      html += '         <option value="parent">{{ $t(\'wdm16.11246\') }}</option>';
      html += '      </select>';
      html += '   </template>';
      html += '   <template v-else>';
      html += '      <span class="loc_cls_str" :title="wps.loc[' + (this.d_level - 1).toString() + '] ? wps.loc[' + (this.d_level - 1).toString() + '].label : \'\'"> {{ wps.loc[' + (this.d_level - 1).toString() + '].label.length > 10 ? wps.loc[' + (this.d_level - 1).toString() + '].label.substring(0, 10) + \'..\' : wps.loc[' + (this.d_level - 1).toString() + '].label }}.</span>';
      html += '      <span v-if="' + this.d_level.toString() + '=== 1 && wps.loc[0] && wps.loc[0].value === \'anatomy\'" class="loc_cls_anatomy"> {{ anatomy_types[wps.anatomy_use] }}.</span>';
      html += '   </template>';
      html += '</template>';
      if (this.d_level <= this.d_levelCount) {
        html += this.f_turnOverParameter();
      }
      return html;
    },
    f_calculateSignificanceTitle: function (significant_title) {
      if (significant_title === 'true') {
        return this.$t('wdm16.13087');
      } else if (significant_title === 'false') {
        return this.$t('wdm16.13108');
      } else if (significant_title === 'false_reached') {
        return this.$t('wdm16.13109');
      }
      this.d_selectedWpsIndex = wps_ind;
      this.d_showOptions.show = true;
    },
    f_showOptionSelect: function (wps_ind) {
      this.d_searchOption = '';
      this.d_showOptions.pagination = {
        'start': 0,
        'end': 1,
        'current': 1,
        'perpage': 1,
        'total_count': 1,
      };
      this.d_showOptions.options_list_id_count = '';
      this.d_showOptions.search_list = [];
      this.d_selectedWpsIndex = wps_ind;
      this.d_showOptions.param_data = this.f_getParameterData(this.d_selectedWpsIndex);
      // this.d_showOptions.list = this.f_getParameterOptions(this.d_selectedWpsIndex);
      if (this.d_showOptions.param_data && this.d_showOptions.param_data.options) {
        this.d_showOptions.pagination.perpage = 50;
        this.d_showOptions.pagination.end = this.d_showOptions.pagination.perpage;
        this.d_showOptions.pagination.total_count = this.d_showOptions.param_data.options.length;
        this.d_showOptions.list = this.d_showOptions.param_data.options;
      }
      if (this.d_showOptions.param_data && this.d_showOptions.param_data.options_list_id) {
        this.d_showOptions.options_list_id_count = this.d_showOptions.param_data.options_list_id_count;
        this.d_showOptions.pagination.total_count = this.d_showOptions.param_data.options_list_id_count;
        if (this.d_showOptions.param_data._wdm_value) {
          this.f_getWdmParameterOption();
        }
      }
      // console.log('this.d_showOptions : ', this.d_showOptions);
      this.d_showOptions.show = true;
    },
    f_showManuelOptionSelect: function (wps_ind) {
      // this.d_showManuelOptions.options_list_id_count = '';
      // this.d_showManuelOptions.search_list = [];
      this.d_selectedWpsIndex = wps_ind;
      this.d_showManuelOptions.param_data = this.f_getParameterData(this.d_selectedWpsIndex);
      this.d_showManuelOptions.wps_ind = wps_ind;
      this.d_showManuelOptions.new_value = '';
      /*
        if (this.d_showManuelOptions.param_data && this.d_showManuelOptions.param_data.options) {
          this.d_showManuelOptions.list = this.d_showManuelOptions.param_data.options;
        }
        if (this.d_showManuelOptions.param_data && this.d_showManuelOptions.param_data.options_list_id) {
          this.d_showManuelOptions.options_list_id_count = this.d_showManuelOptions.param_data.options_list_id_count;
          this.d_showManuelOptions.pagination.total_count = this.d_showManuelOptions.param_data.options_list_id_count;
          if (this.d_showManuelOptions.param_data._wdm_value) {
            this.f_getWdmParameterOption();
          }
        }
      */
      // console.log('this.d_showManuelOptions : ', this.d_showManuelOptions);
      this.d_showManuelOptions.show = true;
    },
    f_addManuelOptionToValListOld: function () {
      let new_manuel_opt = {
        'value': this.d_showManuelOptions.new_value,
        'label': this.d_showManuelOptions.new_value
      };
      let det = false;
      for (let i in this.p_wdmParameterSelectData[this.d_showManuelOptions.wps_ind].val_list) {
        if (this.p_wdmParameterSelectData[this.d_showManuelOptions.wps_ind].val_list[i].value === new_manuel_opt.value) {
          det = true;
          break
        }
      }
      if (!det) {
        this.p_wdmParameterSelectData[this.d_showManuelOptions.wps_ind].val_list.push(new_manuel_opt);
        this.$forceUpdate();
      } else {
        alert('Already added');
      }
    },
    f_addManuelOptionToValList: function () {
      if (this.p_wdmParameterSelectData[this.d_showManuelOptions.wps_ind].val_list.indexOf(this.d_showManuelOptions.new_value) === -1) {
        this.p_wdmParameterSelectData[this.d_showManuelOptions.wps_ind].val_list.push(this.d_showManuelOptions.new_value);
        this.$forceUpdate();
      } else {
        alert('Already added');
      }
    },
    f_getWdmParameterOption: function () {
      this.d_showOptions.list = [];
      this.d_showOptions.show_pagination = false;
      setTimeout(function () {
        this.d_showOptions.show_pagination = true;
      }.bind(this), 50);
      let query = '';
      if (this.d_showOptions.param_data._wdm_value) {
        query = 'wdm_value=' + this.d_showOptions.param_data._wdm_value + '&param=' + this.d_showOptions.param_data._param_value;
      } else {
        let selected_wdm = this.p_wdmParameterSelectData[this.d_selectedWpsIndex].selected_wdm;
        query = 'wdm_value=' + selected_wdm.value + '&param=' + this.d_showOptions.param_data.name.value;
      }
      let data = {
        'search_text': this.d_searchOption,
      };
      if (this.d_searchOption) {
        data.next_list_id = this.d_showOptions.pagination.current;
      } else {
        data.next_list_id = this.d_showOptions.pagination.current;
      }
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': this.$t('wdm16.12705') } });
      ServiceFunctions.get_wdm_parameter_option(query, data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status_code === '2000') {
            this.d_currentPaginationEligible = false;
            // this.d_showOptions.show_pagination = false;
            if (this.d_searchOption) {
              this.d_showOptions.search_list = resp.data.list;
              // when search mode, current pagination goes to the next_list_id, then it is changed by service response.
              this.d_showOptions.pagination.current = resp.data.next_list_id;
            } else {
              this.d_showOptions.list = resp.data.list;
            }
            this.d_showOptions.pagination.total_count = resp.data.options_list_id_count;
            setTimeout(function () {
              // this.d_showOptions.show_pagination = true;
              this.d_currentPaginationEligible = true;
            }.bind(this), 250);
            this.d_showOptions.options_list_id_count = resp.data.options_list_id_count;
          } else {
            alert('error ', resp.data.message);
          }
        });
    },
    f_showFieldSignificanceSelectionModal: function (wps_ind) {
      this.d_selectedWpsIndex = wps_ind;
      this.d_showFieldSignificanceSelectionModal.show = true;
    },
    f_changeFieldSignificance: function (wps_ind) {
      if (this.p_wdmParameterSelectData[wps_ind].significant === 'true') {
        this.p_wdmParameterSelectData[wps_ind].significant = 'false';
      } else {
        this.p_wdmParameterSelectData[wps_ind].significant = 'true';
      }
    },
    f_selectPolestarColumn: function (wps_ind) {
      if (this.p_wdmParameterSelectData[wps_ind].is_column) {
        alert(this.$t('wdm16.12704'));
      } else {
        // FIRST DELETE THE SELECTED_COLUMN
        for (let i in this.p_wdmParameterSelectData) {
          if (this.p_wdmParameterSelectData[i].is_column) {
            this.$delete(this.p_wdmParameterSelectData[i], 'is_column');
          }
        }
        this.p_wdmParameterSelectData[wps_ind].is_column = 1;
      }
      this.$forceUpdate();
    },
    f_selectLoc: function (wps_ind) {
      if (this.p_wdmParameterSelectData[wps_ind].loc.length > 0) {
        if (this.p_wdmParameterSelectData[wps_ind].loc[0].value === 'anatomy') {
          if (!this.p_wdmParameterSelectData[wps_ind].anatomy_use) {
            this.p_wdmParameterSelectData[wps_ind].anatomy_use = 'any';
          }
        } else {
          this.$delete(this.p_wdmParameterSelectData[wps_ind], 'anatomy_use');
          this.$delete(this.p_wdmParameterSelectData[wps_ind], 'this_anatomy');
          this.$delete(this.p_wdmParameterSelectData[wps_ind], 'this_parent');
        }
      }
      this.p_wdmParameterSelectData[wps_ind].val_list = [];
      this.p_wdmParameterSelectData[wps_ind].selected_value = '';
      this.$forceUpdate();
    },
    f_showSelectedValList: function (wps_ind) {
      let str = '';
      for (let i in this.p_wdmParameterSelectData[wps_ind].val_list) {
        if (typeof this.p_wdmParameterSelectData[wps_ind].val_list[i] === 'object') {
          let option_label = this.f_getOptLabel(this.p_wdmParameterSelectData[wps_ind].val_list[i]);
          if (option_label) {
            str += option_label;
          }
        } else {
          str += this.p_wdmParameterSelectData[wps_ind].val_list[i];
        }
        if (parseInt(i) !== this.p_wdmParameterSelectData[wps_ind].val_list.length - 1) {
          str += ', ';
        }
      }
      return str;
    },
    f_controlSelectAvailibility: function (wps_ind, d_level) {
      if (this.p_fastAnalyzeMode) {
        //
      } else {
        if (this.p_wdmParameterSelectData[wps_ind].selected_wdm) {
          if (d_level === 1) {
            if (this.p_wdmParameterSelectData[wps_ind].param_group_count > 1) {
              return true;
            }
          } else {
            if (this.p_wdmParameterSelectData[wps_ind].loc[d_level - 2]) {
              if (this.f_calculateLocOptions(wps_ind, d_level, 'true_false')) {
                return true;
              }
            }
          }
        }
      }
      return false;
    },
    f_calculateLocOptions: function (wps_ind, d_level, type) {
      // console.log('type ', type);
      // console.log('d_level:::', d_level);
      // console.log('*********************** wps_ind ', wps_ind);
      let wps_data = this.p_wdmParameterSelectData[wps_ind];
      // console.log('wps_data::::', JSON.parse(JSON.stringify(wps_data)));
      // console.log('wps_data.loc::::', JSON.stringify(wps_data.loc));
      let param_loc = '';
      let options = [];
      let this_var_list = {
        "created_at": { "label": "Oluşturma Tarihi", "type": "datetime" },
        "created_by": { "label": "Oluşturan", "type": "text" },
        "updated_at": { "label": "Güncelleme Tarihi", "type": "datetime" },
        "updated_by": { "label": "Güncelleyen Kullanıcı Id", "type": "text" },
        "update_total": { "label": "Güncelleme Adedi", "type": "integer" },
        "sentence_template": { "label": "Cümle Kalıbı Html", "type": "text" },
        "sentence_template_as_text": { "label": "Cümle Kalıbı Text", "type": "text" },
        "client_id": { "label": "Client Id", "type": "text" },
        "api_process_date": { "label": "Api İşlemi Tarihi", "type": "datetime" },
        "api_process_status_code": { "label": "Api İşlemi Durum Kodu", "type": "text" },
        "api_process_user": { "label": "Api İşlemi Kullanıcı Id", "type": "text" },
        "wdmr_list_id": { "label": "Wdmr Liste Id", "type": "text" },
        "wdmr_list_index": { "label": "Wdmr Liste Satır No", "type": "integer" },
        "id": { "label": "Id", "type": "text" },
        "date": { "label": "Tarih", "type": "datetime" },
        "label": { "label": "Başlık", "type": "text" },
        "type": { "label": "Wdmr Tipi", "type": "text" },
        "status": { "label": "Durum", "type": "text" },
        "parent_layer_id": { "label": "Üst Katman Id", "type": "text" },
        "parent_client_id": { "label": "Üst Katman Client Id", "type": "text" },
        "accuracy": { "label": "Doğruluk", "type": "float" },
        "id_tree_w": { "label": "Id Ağacı Wisdom", "type": "text" },
        "id_tree_c": { "label": "Id Ağacı Client", "type": "text" },
      };
      let wdm_type = wps_data.selected_wdm.type;
      if (d_level === 1) {
        for (let i in this.option_data[wdm_type].param_group.param_order) {
          let param_ind = this.option_data[wdm_type].param_group.param_order[i];
          let opt = {
            'label': this.option_data[wdm_type].param_group.parameters[param_ind].label,
            'value': this.option_data[wdm_type].param_group.parameters[param_ind].value,
            'type': 'group'
          };
          options.push(opt);
        }
        // for (let this_var in this_var_list) {
        //   let opt = {
        //     'label': this_var_list[this_var]['label'],
        //     'value': this_var,
        //     'type': this_var_list[this_var]['type'],
        //     'this_var': '1'
        //   };
        //   options.push(opt);
        // }
      } else {
        if (wps_data.loc[0].type === 'group') {
          let olo_detail_options = false;
          // when d_level comes as 3, it means that we filled first 2 of loc. So we will control the last type of loc_data
          if (d_level > 2 && wps_data.loc[d_level - 2].type === 'object_list_options') {
            olo_detail_options = true;
          }
          // console.log('olo_detail_options ', olo_detail_options);
          for (let i = 0; i < d_level; i++) {
            let loc_data = wps_data.loc[i];
            /*
              loc_data = {
                'label': 'param label',
                'value': 'param value',
                'type': 'group' // group, select, integer, float, textarea, text, object_list_options, detail. (But CANNOT BE => object_list)
              }
            */
            // console.log(loc_data);
            if (loc_data && loc_data.type) {
              if (loc_data.type === 'group') {
                param_loc += 'this.option_data[\'' + wdm_type + '\'][\'' + loc_data.value + '\']';
              } else {
                let to_last = d_level - 1;
                if (olo_detail_options) {
                  to_last = to_last - 1;
                }
                if (i !== to_last) {
                  if (['detail'].indexOf(loc_data.type) === -1) {
                    param_loc += '.parameters[\'' + loc_data.value + '\']';
                  }
                }
              }
            }
          }
          // console.log('param_loc ', param_loc);
          if (olo_detail_options) {
            // If we have a level 1 object_list_options, we will get this.option_data[this.data_type].general => {'param_order': [], 'parameters': {}}
            let last_param_value = wps_data.loc[d_level - 2].value;
            // console.log('last_param_value ', last_param_value);
            let data = eval(param_loc);
            // console.log('olo data ', data);
            if (data.parameters && data.parameters[last_param_value]) {
              let olo_param_data = data.parameters[last_param_value];
              let mother_param_value = olo_param_data.mother_param
                // console.log('mother_param_value ***??? ', mother_param_value);
              if (data.parameters[mother_param_value] && data.parameters[mother_param_value].options) {
                for (let opt_ind in data.parameters[mother_param_value].options) {
                  let opt = {
                    'label': data.parameters[mother_param_value].options[opt_ind].label,
                    'value': data.parameters[mother_param_value].options[opt_ind].value,
                    'type': 'detail'
                  };
                  if (data.parameters[mother_param_value].options[opt_ind].translation && data.parameters[mother_param_value].options[opt_ind].translation[this.StoreLang]) {
                    opt.label = data.parameters[mother_param_value].options[opt_ind].translation[this.StoreLang];
                  }
                  options.push(opt);
                }
              }
            }
          } else {
            let data = eval(param_loc);
            // console.log('NOT olo data ', data);
            if (data.param_order) {
              for (let p_ind in data.param_order) {
                let param_ind = data.param_order[p_ind];
                // console.log(param_ind);
                // parametre adlarının listesi oluşturuluyor. 
                if (['object_list'].indexOf(data.parameters[param_ind].type) === -1) {
                  let opt = {
                    'label': data.parameters[param_ind].name.label,
                    'value': param_ind,
                    'type': data.parameters[param_ind].type
                  };
                  // console.log('data.parameters[param_ind] : ', data.parameters[param_ind])
                  if (data.parameters[param_ind].name && data.parameters[param_ind].name.translation && data.parameters[param_ind].name.translation[this.StoreLang]) {
                    opt.label = data.parameters[param_ind].name.translation[this.StoreLang];
                  }
                  // console.log(opt);
                  options.push(opt);
                }
              }
            }
          }

        }

      }
      if (type === 'get_options') {
        // console.log('options => ', JSON.stringify(options));
        return JSON.parse(JSON.stringify(options));
      } else if (type === 'true_false') {
        if (options.length > 0) {
          // console.log('true');
          return true;
        } else {
          // console.log('false');
          return false;
        }
      }
    },
    f_selectedWdmChange: function (wps_ind) {
      let is_rendered = false;
      let selected_wdm = this.p_wdmParameterSelectData[wps_ind].selected_wdm;
      if (selected_wdm && !this.option_data[selected_wdm.type]) {
          let local_storage_wdm_list = JSON.parse(localStorage.getItem('wdm_list'));
          this.option_data[selected_wdm.value] = local_storage_wdm_list[selected_wdm.value];
          this.render_template(true, wps_ind);
      }
      if (!is_rendered) {
        this.render_template(true, wps_ind);
      }
    },
    f_getParameterOptions: function (wps_ind) {
      let param_loc = '';
      for (let i in this.p_wdmParameterSelectData[wps_ind].loc) {
        if (this.p_wdmParameterSelectData[wps_ind].loc[i] && this.p_wdmParameterSelectData[wps_ind].loc[i].type) {
          if (this.p_wdmParameterSelectData[wps_ind].loc[i].type === 'group') {
            param_loc += 'this.option_data[\'' + this.p_wdmParameterSelectData[wps_ind].selected_wdm.type + '\'][\'' + this.p_wdmParameterSelectData[wps_ind].loc[i].value + '\']';
          } else {
            if (['detail'].indexOf(this.p_wdmParameterSelectData[wps_ind].loc[i].type) === -1) {
              param_loc += '.parameters[\'' + this.p_wdmParameterSelectData[wps_ind].loc[i].value + '\']';
            }
          }
        }
      }
      param_loc += '.options';
      // console.log(param_loc);
      let options = [];
      try {
        let data = eval(param_loc);
        options = data;
      } catch (err) {}
      return options;
    },
    f_getParameterData: function (wps_ind) {
      let param_loc = '';
      for (let i in this.p_wdmParameterSelectData[wps_ind].loc) {
        if (this.p_wdmParameterSelectData[wps_ind].loc[i] && this.p_wdmParameterSelectData[wps_ind].loc[i].type) {
          if (this.p_wdmParameterSelectData[wps_ind].loc[i].type === 'group') {
            param_loc += 'this.option_data[\'' + this.p_wdmParameterSelectData[wps_ind].selected_wdm.type + '\'][\'' + this.p_wdmParameterSelectData[wps_ind].loc[i].value + '\']';
          } else {
            if (['detail'].indexOf(this.p_wdmParameterSelectData[wps_ind].loc[i].type) === -1) {
              param_loc += '.parameters[\'' + this.p_wdmParameterSelectData[wps_ind].loc[i].value + '\']';
            }
          }
        }
      }
      // console.log(param_loc);
      let param_data = '';
      try {
        param_data = eval(param_loc);
      } catch (err) {}
      return param_data;
    },
    f_controlLastSelectedParamType: function (wps_ind) {
      for (let i = this.p_wdmParameterSelectData[wps_ind].loc.length - 1; i >= 0; i--) {
        if (this.p_wdmParameterSelectData[wps_ind].loc[i]) {
          if (this.p_wdmParameterSelectData[wps_ind].loc[i].type && ['list_parameter', 'object_list', 'group'].indexOf(this.p_wdmParameterSelectData[wps_ind].loc[i].type) === -1) {
            return this.p_wdmParameterSelectData[wps_ind].loc[i].type;
          }
        }
      }
      return false;
    },
    f_isSelectDisabled: function (level_index, wps_ind) {
      if (!this.p_wdmParameterSelectData[wps_ind].loc[level_index + 1]) {
        return true;
      } else {
        return false;
      }
    },
    f_vIfFunction: function () {
      if (this.d_level === 1) {
        return 'true';
      } else if (this.d_level == 2) {
        return 'wps.loc[0] && option_data[wps.selected_wdm.type][wps.loc[0].value].parameters';
      } else {
        let str = 'option_data[wps.selected_wdm.type][wps.loc[0].value]';
        for (let i = 2; i < this.d_level; i++) {
          let param_ind = 'wps.loc[' + (i - 1).toString() + '].value';
          str += '.parameters[' + param_ind + ']';
        }
        return 'wps.loc[' + (this.d_level - 2).toString() + ']' + ' && ' + str + '.parameters';
      }
    },
    f_paramorderString: function () {
      if (this.d_level === 1) {
        return 'option_data[wps.selected_wdm.type].param_group.param_order';
      } else if (this.d_level == 2) {
        return 'option_data[wps.selected_wdm.type][wps.loc[0].value].param_order';
      } else {
        let str = 'option_data[wps.selected_wdm.type][wps.loc[0].value]';
        for (let i = 2; i < this.d_level; i++) {
          let param_ind = 'wps.loc[' + (i - 1).toString() + '].value';
          str += '.parameters[' + param_ind + ']';
        }
        return str + '.param_order';
      }
    },
    f_labelValue: function () {
      if (this.d_level === 1) {
        let x = 'option_data[wps.selected_wdm.type].param_group.parameters[param' + this.d_level.toString() + ']';
        return '{' + '\'value\': ' + x + '.value, \'label\': ' + x + '.label, ' + '\'type\': \'group\'' + '}';
      } else if (this.d_level == 2) {
        let x = 'option_data[wps.selected_wdm.type][wps.loc[0].value].parameters[param' + this.d_level.toString() + ']';
        return '{' + '\'value\': ' + x + '.name.value, \'label\': ' + x + '.name.label, ' + '\'type\': ' + x + '.type' + '}';
      } else {
        let str = 'option_data[wps.selected_wdm.type][wps.loc[0].value]';
        for (let i = 2; i < this.d_level; i++) {
          let param_ind = 'wps.loc[' + (i - 1).toString() + '].value';
          str += '.parameters[' + param_ind + ']';
        }
        let x = str + '.parameters[param' + this.d_level.toString() + ']';
        return '{' + '\'value\': ' + x + '.name.value, \'label\': ' + x + '.name.label, ' + '\'type\': ' + x + '.type' + '}';
      }
    }
  },
  watch: {
    'd_searchOption': function () {
      // console.log('this.d_searchOption : ', this.d_searchOption);
      clearTimeout(this.d_showOptions.timeout);
      this.d_showOptions.timeout = 0;
      this.d_showOptions.timeout = setTimeout(function () {
        this.d_showOptions.search_list = [];
        if (this.d_searchOption) {
          if (this.d_showOptions.param_data && this.d_showOptions.param_data.options_list_id && this.d_showOptions.param_data.options_list_id <= this.d_showOptions.param_data.options_list_id_count) {
            // when d_searchOption changes
            this.d_showOptions.pagination.perpage = 1;
            this.d_showOptions.pagination.start = 0;
            this.d_showOptions.pagination.current = 1;
            this.d_showOptions.pagination.end = 1;
            this.d_showOptions.pagination.total_count = this.d_showOptions.param_data.options_list_id_count;
            this.f_getWdmParameterOption();
          } else {
            for (let opt of this.d_showOptions.list) {
              let opt_label_includes = false;
              let opt_value_includes = opt.value && opt.value.toLocaleLowerCase().indexOf(this.d_searchOption.toLocaleLowerCase()) !== -1;
              let opt_translation_includes = false;
              if (opt.translation && opt.translation[this.StoreLang]) {
                if (opt.translation[this.StoreLang] && opt.translation[this.StoreLang].toLocaleLowerCase().indexOf(this.d_searchOption.toLocaleLowerCase()) !== -1) {
                  opt_translation_includes = true;
                }
              } else {
                opt_label_includes = opt.label && opt.label.toLocaleLowerCase().indexOf(this.d_searchOption.toLocaleLowerCase()) !== -1;
              }
              if (opt_label_includes || opt_value_includes || opt_translation_includes) {
                // console.log('opt_label_includes       : ', opt_label_includes);
                // console.log('opt_value_includes       : ', opt_value_includes);
                // console.log('opt_translation_includes : ', opt_translation_includes);
                // console.log('opt.translation.en       : ', opt.translation.en);
                this.d_showOptions.search_list.push(opt);
              }
            }
            this.d_showOptions.pagination.perpage = 50;
            this.d_showOptions.pagination.start = 0;
            this.d_showOptions.pagination.current = 1;
            this.d_showOptions.pagination.end = this.d_showOptions.pagination.perpage;
            this.d_showOptions.pagination.total_count = this.d_showOptions.search_list.length;
          }
        } else {
          if (this.d_showOptions.options_list_id_count) {
            this.f_getWdmParameterOption();
          } else {
            this.d_showOptions.pagination.current = 1;
            this.d_showOptions.pagination.perpage = 50;
            this.d_showOptions.pagination.start = 0;
            this.d_showOptions.pagination.end = this.d_showOptions.pagination.perpage;
            this.d_showOptions.pagination.total_count = this.d_showOptions.param_data.options.length;
          }
        }
      }.bind(this), 1000);
    },
    'd_showOptions.pagination.current': function () {
      // console.log('this.d_currentPaginationEligible : ', this.d_currentPaginationEligible);
      // console.log('this.d_showOptions.pagination.current : ', this.d_showOptions.pagination.current);
      if (this.d_currentPaginationEligible) {
        this.d_showOptions.pagination.start = (this.d_showOptions.pagination.current - 1) * this.d_showOptions.pagination.perpage;
        this.d_showOptions.pagination.end = (this.d_showOptions.pagination.perpage * this.d_showOptions.pagination.current);
        if (this.d_showOptions.options_list_id_count) {
          this.f_getWdmParameterOption();
        }
      }
    }
  }
};

</script>

<style>
.loc_cls {
  background: transparent;
  padding: 3px;
  margin: 3px;
  width: 150px;
  color: #13915b;
  border: 1px solid rgba(158, 193, 179, 0.3);
}

.input_cls {
  background: transparent;
  padding: 3px;
  margin: 3px;
  width: 150px;
  color: #13915b;
  border: 1px solid rgba(158, 193, 179, 0.3);
}

.wps_row {
  border: 1px solid rgba(158, 193, 179, 0.3);
  border-radius: 5px 5px;
  margin: 3px;
  /*background-color: #cbd2d3;*/
  background-color: white;
}

.loc_cls_str {
  display: inline-block;
  padding: 3px;
  margin: 3px;
  width: 150px;
  color: #13915b;
  border: 1px solid rgba(158, 193, 179, 0.3);
}

.loc_cls_anatomy {
  display: inline-block;
  padding: 3px;
  margin: 3px;
  width: 150px;
  color: #13915b;
  border: 1px solid rgba(158, 193, 179, 0.3);
}

.data_type_cls {
  width: 250px;
  padding: 3px;
  margin: 3px;
  background-color: #24fffb52;
  border-radius: 5px 5px;
}

.operation_cls {
  width: 150px;
  padding: 3px;
  margin: 3px;
  background-color: #ebf5bc;
}

</style>

