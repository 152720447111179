<template>
  <div>
    <b-row>
      <b-col sm="12" md="12" lg="12">
        <b-card-header header-bg-variant="white" header-text-variant="dark" style="margin-bottom: 10px;">
          <b-row>
            <b-col sm="12" lg="8">
              <h5>
                {{ StoreLangTranslation.data['column_list'][StoreLang] }}
              </h5>
            </b-col>
            <b-col sm="12" lg="4" style="text-align: right;">
              <template v-if="d_columnSaveLoading">
                <b-spinner variant="primary" label="Spinning"></b-spinner>
              </template>
              <template v-else>
                <b-button variant="outline-primary" size="sm" @click="f_wanalyzerColumnSave()">
                  <i class="fa fa-send-o"></i> {{ StoreLangTranslation.data['save'][StoreLang] }}
                </b-button>
              </template>
            </b-col>
          </b-row>
        </b-card-header>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12" md="12" lg="4">
        <b-card-header header-bg-variant="white" class="p-1" header-text-variant="dark">
          <b-row>
            <b-col sm="12" lg="8"></b-col>
            <b-col sm="12" lg="4" style="text-align: right;">
              <b-dropdown variant="outline-primary" size="sm">
                <template v-slot:button-content> </template>
                  <b-dropdown-item @click="f_addNewManuelColumn()">
                    {{ StoreLangTranslation.data['new_manuel_column'][StoreLang] }}
                  </b-dropdown-item>
                  <b-dropdown-item v-if="p_projectPermission.main_layer" @click="f_showLayerColumnRuleModal('new')">
                    {{ StoreLangTranslation.data['new_layer_column'][StoreLang] }}
                  </b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row>
        </b-card-header>
        <template v-for="(col, col_ind) in p_columnList">
          <b-row :style="d_selectedColumnInd === col_ind ? 'background-color: #c3e7f5;' : ''" @click="f_selectColumn(col_ind)">
            <b-col sm="12" lg="8">
              <strong> {{ col_ind + 1 }} ) </strong>
              <template v-if="col.deleted">
                <span style="color: red;">{{ col.label }}</span>
              </template>
              <template v-else>
                <span :style="col.rule_id === undefined ? '' : 'color: blue;'">{{ col.label }}</span>
              </template>
            </b-col>
            <b-col sm="12" lg="4" style="text-align: right;">
              <b-badge v-if="col.param_type === 'select'" variant="success" style="margin-right: 3px;"> {{ col.param_type }} </b-badge>
              <b-badge v-else-if="['text', 'textarea'].indexOf(col.param_type) !== -1" variant="warning" style="margin-right: 3px;"> {{ col.param_type }} </b-badge>
              <b-badge v-else-if="['date', 'datetime'].indexOf(col.param_type) !== -1" variant="secondary" style="margin-right: 3px;"> {{ col.param_type }} </b-badge>
              <b-badge v-else-if="['integer', 'float'].indexOf(col.param_type) !== -1" variant="primary" style="margin-right: 3px;"> {{ col.param_type }} </b-badge>
              <b-badge v-else variant="danger" style="margin-right: 3px;"> {{ col.param_type }} </b-badge>
            </b-col>
          </b-row>
        </template>
      </b-col>
      <b-col sm="12" md="12" lg="8">
        <template v-if="d_selectedColumnInd !== ''">
          <b-tabs>


            <b-tab :active="d_navColumnDetails === 'column_general_info'" @click="d_navColumnDetails = 'column_general_info'">
              <template slot="title">
                {{ StoreLangTranslation.data['column_general_info'][StoreLang] }}
              </template>
              <template v-if="d_navColumnDetails === 'column_general_info'">
                <b-card no-body class="mb-1" header-tag="header">
                  <b-card-header header-bg-variant="white" class="p-1" header-text-variant="dark">
                    <b-row>
                      <b-col cols="6">
                        {{ p_columnList[d_selectedColumnInd].id }}
                      </b-col>
                      <b-col cols="6" style="text-align: right;">
                        <b-button :variant="p_columnList[d_selectedColumnInd].deleted ? 'danger' : 'outline-danger'" @click="f_deleteColumn(d_selectedColumnInd)">
                          <i class="fa fa-trash"></i>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-card-header>
                  <b-row>
                    <b-col cols="6">
                      {{ StoreLangTranslation.data['caption'][StoreLang] }}
                    </b-col>
                    <b-col cols="6">
                      <template v-if="p_columnList[d_selectedColumnInd].rule_id === undefined">
                        <b-form-input v-model="p_columnList[d_selectedColumnInd].label"></b-form-input>
                      </template>
                      <template v-else>
                        {{ p_columnList[d_selectedColumnInd].label }}
                      </template>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="6">
                      {{ StoreLangTranslation.data['info'][StoreLang] }}
                    </b-col>
                    <b-col cols="6">
                      <b-textarea v-model="p_columnList[d_selectedColumnInd].info"></b-textarea>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="6">
                      {{ StoreLangTranslation.data['type'][StoreLang] }}
                    </b-col>
                    <b-col cols="6">
                      {{ p_columnList[d_selectedColumnInd].type }}
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="6">
                      {{ StoreLangTranslation.data['data_type'][StoreLang] }}
                    </b-col>
                    <b-col cols="6">
                      <b-form-select id="param_type" :plain="true" v-model="p_columnList[d_selectedColumnInd].param_type" :disabled="p_columnList[d_selectedColumnInd].type === 'wdm' || p_columnList[d_selectedColumnInd].rule_id !== undefined">
                        <option v-if="p_columnList[d_selectedColumnInd].type === 'manuel'" v-for="(ty, ty_ind) in d_manuelParamTypeList" :value="ty"> {{ ty }} </option>
                        <option v-if="p_columnList[d_selectedColumnInd].type === 'wdm'" v-for="(ty, ty_ind) in d_wdmParamTypeList" :value="ty"> {{ ty }} </option>
                      </b-form-select>
                    </b-col>
                  </b-row>
                  <template v-if="p_columnList[d_selectedColumnInd].type === 'wdm' && ((['select', 'checkbox', 'object_list_options', 'radiogroup'].indexOf(p_columnList[d_selectedColumnInd].param_type) !== -1 && !p_columnList[d_selectedColumnInd].param_type_real) || (p_columnList[d_selectedColumnInd].param_type_real && ['select', 'checkbox', 'object_list_options', 'radiogroup'].indexOf(p_columnList[d_selectedColumnInd].param_type_real) !== -1))">
                    <b-row>
                      <b-col cols="12">
                        {{ StoreLangTranslation.data['value_label_usage'][StoreLang] }}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="1"> </b-col>
                      <b-col cols="11">
                        <b-form-select v-model="p_columnList[d_selectedColumnInd].value_label">
                          <option value="value">{{ StoreLangTranslation.data['value_usage'][StoreLang] }}</option>
                          <option value="label">{{ StoreLangTranslation.data['label_usage'][StoreLang] }}</option>
                        </b-form-select>
                      </b-col>
                    </b-row>
                  </template>
                  <template v-if="p_columnList[d_selectedColumnInd].type === 'wdm' && p_columnList[d_selectedColumnInd].record_count === 'many_times'">
                    <b-row>
                      <b-col cols="12">
                        {{ StoreLangTranslation.data['numeric_categorical'][StoreLang] }}
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="1"> </b-col>
                      <b-col cols="11">
                        <b-form-select v-model="p_columnList[d_selectedColumnInd].numeric_string" @change="f_controlColumnFeatures(p_columnList, d_selectedColumnInd, 'this')">
                          <option value="numeric">{{ StoreLangTranslation.data['numeric'][StoreLang] }}</option>
                          <option value="string">{{ StoreLangTranslation.data['categorical'][StoreLang] }}</option>
                        </b-form-select>
                      </b-col>
                    </b-row>
                    <template v-if="p_columnList[d_selectedColumnInd].numeric_string && p_columnList[d_selectedColumnInd].numeric_string === 'numeric'">
                      <b-row>
                        <b-col cols="12">
                          {{ StoreLangTranslation.data['numeric_usage_type'][StoreLang] }}
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="1"> </b-col>
                        <b-col cols="11">
                          <b-form-select v-model="p_columnList[d_selectedColumnInd].numeric_usage">
                            <option value="mean">{{ StoreLangTranslation.data['mean'][StoreLang] }}</option>
                            <option value="median">{{ StoreLangTranslation.data['median'][StoreLang] }}</option>
                            <option value="max">{{ StoreLangTranslation.data['max'][StoreLang] }}</option>
                            <option value="min">{{ StoreLangTranslation.data['min'][StoreLang] }}</option>
                            <option value="sum">{{ StoreLangTranslation.data['sum'][StoreLang] }}</option>
                          </b-form-select>
                        </b-col>
                      </b-row>
                    </template>
                    <template v-if="p_columnList[d_selectedColumnInd].numeric_string && p_columnList[d_selectedColumnInd].numeric_string === 'string'">
                      <b-row>
                        <b-col cols="12">
                          {{ StoreLangTranslation.data['categorical_data_selection'][StoreLang] }}
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="1"> </b-col>
                        <b-col cols="11">
                          <b-form-select v-model="p_columnList[d_selectedColumnInd].string_usage" @change="f_controlColumnFeatures(p_columnList, d_selectedColumnInd, 'this')">
                            <option value="0">{{ StoreLangTranslation.data['first_value_date_is_older'][StoreLang] }}</option>
                            <option value="1">{{ StoreLangTranslation.data['last_value_date_is_older'][StoreLang] }}</option>
                            <option value="2">{{ StoreLangTranslation.data['all_recorded_values'][StoreLang] }}</option>
                          </b-form-select>
                        </b-col>
                      </b-row>
                    </template>
                    <template v-if="p_columnList[d_selectedColumnInd].string_usage && p_columnList[d_selectedColumnInd].string_usage === '2'">
                      <b-row>
                        <b-col cols="12">
                          <span id="multi_wdmr_approach">{{ StoreLangTranslation.data['value_multiplex_approach'][StoreLang] }}</span>
<!--                           <b-popover :target="'multi_wdmr_approach'" triggers="hover focus">
                            <template slot="title">Wanalyzer</template>
                            (örn: birden fazla laboratuar ve kreatinin değeri var. Tarih ilişkisi kontrolü var ise önce ona bakılır.)
                          </b-popover> -->
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="1"> </b-col>
                        <b-col cols="11">
                          <b-form-select v-model="p_columnList[d_selectedColumnInd].multi_wdmr_approach">
                            <option value="0">{{ StoreLangTranslation.data['merge_all_records'][StoreLang] }}</option>
                            <option value="1">{{ StoreLangTranslation.data['data_separately_divided_by_square_brackets'][StoreLang] }}</option>
                          </b-form-select>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="12">
                          <span id="list_creation_approach">{{ StoreLangTranslation.data['same_values_approach'][StoreLang] }}</span>
                          <!-- <b-popover :target="'list_creation_approach'" triggers="hover focus">
                            <template slot="title">Wanalyzer</template>
                            Bir kayıt içerisinde (wdmr) birden fazla aynı alan mevcut ise liste oluşturma yaklaşımı (ör: patolojide materyal tipi biyopsi, mastektomi,...)
                          </b-popover> -->
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="1"> </b-col>
                        <b-col cols="11">
                          <b-form-select v-model="p_columnList[d_selectedColumnInd].list_creation_approach">
                            <option value="0"> {{ StoreLangTranslation.data['same_values_use'][StoreLang] }} </option>
                            <option value="1"> {{ StoreLangTranslation.data['same_values_not_use'][StoreLang] }} </option>
                          </b-form-select>
                        </b-col>
                      </b-row>
                    </template>
                  </template>
                  <template v-if="p_columnList[d_selectedColumnInd].type === 'wdm'">
                    <b-row>
                      <b-col cols="12">
                        <span id="column_parent_relation"> {{ StoreLangTranslation.data['column_root_relation_type'][StoreLang] }} </span>
                        <!-- <b-popover :target="'column_parent_relation'" triggers="hover focus">
                          <template slot="title">Wanalyzer</template>
                          Bu kolondaki verinin, başka bir kolondaki verinin ilişkili altındaki verileri olması istendiğinde evet olarak seçilir.
                        </b-popover> -->
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="1"> </b-col>
                      <b-col cols="11">
                        <b-form-select v-model="p_columnList[d_selectedColumnInd].parent_column_relation_type">
                          <option value="yes">{{ StoreLangTranslation.data['yes'][StoreLang] }}</option>
                          <option value="no">{{ StoreLangTranslation.data['no'][StoreLang] }}</option>
                        </b-form-select>
                      </b-col>
                    </b-row>
                    <template v-if="p_columnList[d_selectedColumnInd].parent_column_relation_type === 'yes'">
                      <b-row>
                        <b-col cols="12">
                          <span id="column_parent_relation_column"> {{ StoreLangTranslation.data['root_column'][StoreLang] }} </span>
                          <!-- <b-popover :target="'column_parent_relation_column'" triggers="hover focus">
                            <template slot="title">Wanalyzer</template>
                            Bu kolondaki verinin, başka bir kolondaki verinin ilişkili altındaki verileri olması istendiğinde evet olarak seçilir.
                          </b-popover> -->
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="1"></b-col>
                        <b-col cols="11">
                          <b-form-select v-model="p_columnList[d_selectedColumnInd].parent_column_relation_column">
                            <option v-for="(col, col_ind) in p_columnList" :value="col_ind">{{ col.label }}</option>
                          </b-form-select>
                        </b-col>
                      </b-row>
                    </template>
                  </template>
                  <template v-if="p_columnList[d_selectedColumnInd].type === 'manuel' && p_columnList[d_selectedColumnInd].rule_id === undefined">
                    <b-row>
                      <b-col cols="12">
                        <span id="data_creation_type">
                          {{ StoreLangTranslation.data['manuel_column_design_type'][StoreLang] }}
                        </span>
                        <!-- <b-popover :target="'data_creation_type'" triggers="hover focus">
                          <template slot="title">Wanalyzer</template>
                          (örn: Select yapısında bir kolon hazırladıysanız ve rastgele seçeneğini seçtiyseniz,
                          <br> ilgili oluşturduğunuz seçeneklerden rastgele atamalar yapar. Göz rengi => Yeşil, Mavi, Kahverengi seçeneklerinden herhangi bir tanesini atar)
                        </b-popover> -->
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="1"> </b-col>
                      <b-col cols="11">
                        <b-form-select v-model="p_columnList[d_selectedColumnInd].data_creation_type">
                          <option value="manuel">
                            {{ StoreLangTranslation.data['manuel'][StoreLang] }}
                          </option>
                          <option value="rule">
                            {{ StoreLangTranslation.data['column_rule_and_functions'][StoreLang] }}
                          </option>
<!--                           <option v-if="['integer', 'float', 'select'].indexOf(p_columnList[d_selectedColumnInd].param_type) !== -1" value="random">
                            {{ StoreLangTranslation.data['random_data_create'][StoreLang] }}
                          </option>
                          <option value="machine_learning">
                            {{ StoreLangTranslation.data['machine_learning_model_assign'][StoreLang] }}
                          </option> -->
                        </b-form-select>
                      </b-col>
                    </b-row>
                  </template>
                </b-card>
              </template>
            </b-tab>


            <b-tab v-if="p_columnList[d_selectedColumnInd].type === 'wdm'" :active="d_navColumnDetails === 'wdm_param_column_rule'" @click="d_navColumnDetails = 'wdm_param_column_rule'">
              <template slot="title">
                {{ StoreLangTranslation.data['wdm_param_column_rule'][StoreLang] }}
              </template>
              <template v-if="d_navColumnDetails === 'wdm_param_column_rule'">
                  <b-card no-body class="mb-1" header-tag="header">
                  <b-card-header header-bg-variant="white" class="p-1" header-text-variant="dark">
                    Kolon Kuralı
                    <b-button v-if="p_projectPermission.main_layer" class="pull-right" size="sm" variant="primary" @click="f_showLayerColumnRuleModal('edit', d_selectedColumnInd)">
                      {{ StoreLangTranslation.data['edit'][StoreLang] }}
                    </b-button>
                  </b-card-header>
                  <template v-if="d_showWdmParamColumnRule">
                    <template v-if="p_columnList[d_selectedColumnInd].layer_rules">
                      <template v-for="(layer_rule, layer_key) in p_columnList[d_selectedColumnInd].layer_rules">
                        <b-card-header header-bg-variant="info" class="p-1" header-text-variant="dark">
                          <strong>{{ layer_key }}</strong>
                        </b-card-header>
                        <b-row style="margin-top: 10px;">
                          <b-col sm="12" lg="12">
                            <template v-if="layer_rule.query_list && layer_rule.query_list.length > 0">
                              <rule-query :query_list="layer_rule.query_list" :compile_str="layer_rule.compile_str_array" :compile_str_date="layer_rule.compile_str_date" :mode="'view'" :compile_str_text="layer_rule.compile_str_text"></rule-query>
                            </template>
                          </b-col>
                        </b-row>
                      </template>
                    </template>
                  </template>
                </b-card>
              </template>
            </b-tab>


            <b-tab v-if="['radiogroup', 'select'].indexOf(p_columnList[d_selectedColumnInd].param_type) !== -1 && (['manuel'].indexOf(p_columnList[d_selectedColumnInd].data_creation_type) !== -1 || p_columnList[d_selectedColumnInd].type === 'wdm')" :active="d_navColumnDetails === 'column_options'" @click="d_navColumnDetails = 'column_options'">
              <template slot="title">
                {{ StoreLangTranslation.data['column_options'][StoreLang] }}
              </template>
              <template v-if="d_navColumnDetails === 'column_options'">
<!--                 <b-card-header header-bg-variant="white" class="p-1" header-text-variant="dark">
                  <b-row>
                    <b-col cols="2">seçenek yönetimi</b-col>
                    <b-col cols="8"></b-col>
                    <b-col cols="2">
                      <b-button class="pull-right" size="sm" :variant="d_showOptionInfo ?'warning' :'secondary'" :style="d_showOptionInfo ?'margin-right: 5px; padding: 0px; border: solid 2px green;' :'margin-right: 5px; padding: 0px;'" @click="d_showOptionInfo ? d_showOptionInfo = false : d_showOptionInfo = true">
                        <img src="@/icon/487464.png" title="Seçenek bilgilerini göster" style="width: 2em;" />
                      </b-button>
                    </b-col>
                  </b-row>
                </b-card-header> -->

                <b-row v-if="p_columnList[d_selectedColumnInd].data_creation_type === 'manuel' || p_columnList[d_selectedColumnInd].data_creation_type === 'random'">
                  <b-col cols="3">
                    {{ StoreLangTranslation.data['new_option'][StoreLang] }}
                  </b-col>
                  <b-col cols="9">
                    <b-input-group>
                      <b-form-input v-model="d_newOption"> </b-form-input>
                      <b-input-group-append>
                        <b-button @click="f_addNewOption()" variant="outline-success"><i class="fa fa-plus"></i></b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
                </b-row>


                <b-row>
                  <b-col sm="12" lg="12">
                    <template v-if="p_columnList[d_selectedColumnInd].options && p_columnList[d_selectedColumnInd].options.length > 0">
                      <div style="overflow-y: auto; height: 500px; overflow-x: hidden;">
                        <template v-for="(op, op_ind) in p_columnList[d_selectedColumnInd].options">
                          <b-row style="margin: 0px; padding: 3px; background-color: rgba(245, 245, 245, 1);">
                            <b-col cols="5" style="align-items: center; display: flex;">
                              <strong> {{ op_ind + 1 }}) </strong> {{ op.label }}
                            </b-col>
                            <b-col cols="7" style="text-align: right;">
                              <template v-if="p_columnList[d_selectedColumnInd].data_creation_type === 'manuel' || p_columnList[d_selectedColumnInd].data_creation_type === 'random'">
                                <b-button @click="f_columnOptionRuleShow(op_ind)" size="md" variant="outline-success" style="margin-right: 5px;" class="pull-right">
                                  <i class="fa fa-star"></i>
                                </b-button>
                                <b-button @click="f_deleteOption(d_selectedColumnInd, op_ind)" size="md" variant="outline-danger" style="margin-right: 5px;" class="pull-right">
                                  <i class="fa fa-trash"></i>
                                </b-button>
                                <b-button @click="f_copyThisOption(op_ind)" size="md" variant="outline-primary" style="margin-right: 5px;" class="pull-right">
                                  <i class="fa fa-copy"></i>
                                </b-button>
                              </template>
                            </b-col>
                              <!-- <b-col cols="2" style="padding: 3px;">
                                <b-dropdown v-if="p_columnList[d_selectedColumnInd].data_creation_type === 'manuel' || p_columnList[d_selectedColumnInd].data_creation_type === 'random'" class="pull-right" variant="secondary" size="sm">
                                  <template v-slot:button-content>
                                    <i class="fa fa-star"></i>
                                  </template>
                                  <b-dropdown-item v-if="p_columnList[d_selectedColumnInd].rule_id === undefined" @click="f_copyThisOption(op_ind)">
                                    <i class="fa fa-copy"></i>
                                  </b-dropdown-item>
                                  <!-- <b-dropdown-item @click="f_addThisOptionToAllCells(op_ind)" title="seçeneği hücrelere ata"><i class="fa fa-edit"></i> seçeneği hücrelere ata </b-dropdown-item>
                                </b-dropdown>
                              </b-col>
                                -->
                          </b-row>

                          <template v-if="op.rule && op.rule.query_list && op.rule.query_list.length > 0">
                            <rule-query :query_list="op.rule.query_list" :compile_str="op.rule.compile_str_array" :compile_str_date="op.rule.compile_str_date" :mode="'view'" :compile_str_text="op.rule.compile_str_text"></rule-query>
                          </template>

                        </template>
                      </div>
                    </template>
                  </b-col>
                </b-row>


              </template>
            </b-tab>

            <b-tab v-if="p_columnList[d_selectedColumnInd].data_creation_type === 'rule'" :active="d_navColumnDetails === 'data_creation_type_rule'" @click="d_navColumnDetails = 'data_creation_type_rule'">
              <template slot="title">
                {{ StoreLangTranslation.data['column_rule_and_functions'][StoreLang] }}
              </template>
              <template v-if="d_navColumnDetails === 'data_creation_type_rule'">

                <b-card no-body class="mb-1" header-tag="header" key="data_creation_rule_info">
                  <b-card-header header-bg-variant="white" class="p-1" header-text-variant="dark">
                    <b-row style="margin: 0px;">
                      <b-col cols="10"> </b-col>
                      <b-col cols="2">
                        <b-button @click="f_addNewDataCreationRule()" variant="outline-success" size="sm" class="pull-right">
                          <i class="fa fa-plus"></i>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-card-header>
                  <b-row>
                    <b-col cols="12">
                      <template v-if="p_columnList[d_selectedColumnInd].data_creation_rule">
                        <div :style="f_calculateStyleRule()">
                          <template v-for="(rule, rule_ind) in p_columnList[d_selectedColumnInd].data_creation_rule">
                            <b-card no-body class="mb-1" header-tag="header" :key="'data_creation_rule_' + rule_ind">
                              <b-card-header header-bg-variant="white" class="p-1" header-text-variant="dark">
                                <b-row style="margin: 0px;">
                                  <b-col cols="6">
                                    {{ rule_ind + 1 }} ) {{ StoreLangTranslation.data['function'][StoreLang] }}
                                  </b-col>
                                  <b-col cols="6" style="text-align: center;">
                                    <b-button @click="f_dataCreationRuleFunctionModal(rule_ind)" size="md" variant="white" style="margin-right: 5px;">
                                      <i>Fx</i>
                                    </b-button>
                                    <b-button @click="f_dataCreationRuleShow(rule_ind)" size="md" variant="white" style="margin-right: 5px;">
                                      <i class="fa fa-star"></i>
                                    </b-button>
                                    <b-button @click="f_deleteDataCreationRule(rule_ind)" size="md" variant="white" style="margin-right: 5px;">
                                      <i class="fa fa-trash"></i>
                                    </b-button>
                                    <b-button @click="f_copyColumnCreationRule(rule_ind)" size="md" variant="white" style="margin-right: 5px;">
                                      <i class="fa fa-copy"></i>
                                    </b-button>
                                  </b-col>
                                </b-row>
                              </b-card-header>
                              <b-row>
                                <b-col cols="12">
                                  <b-form-textarea v-model="rule.value" rows="3"></b-form-textarea>
                                </b-col>
                              </b-row>
                              
                              <template v-if="rule.query_list.length > 0">
                                <rule-query :query_list="rule.query_list" :compile_str="rule.compile_str_array" :compile_str_date="rule.compile_str_date" :compile_str_text="rule.compile_str_text" :mode="'view'"></rule-query>
                              </template>
                            </b-card>
                          </template>
                        </div>
                      </template>
                    </b-col>
                  </b-row>
                </b-card>
              </template>
            </b-tab>






          </b-tabs>


        </template>


      </b-col>
    </b-row>

    <b-modal v-if="d_showColumnRuleModal" id="d_showColumnRuleModal" v-model="d_showColumnRuleModal" scrollable hide-footer title="Ana Kural" centered header-bg-variant="info" header-text-variant="dark" size="xl">
      <div style="min-height: 500px;">
        <wdm-rule-a :data_type="d_columnRule.data_type" :option_data="d_columnRule.option_data" :prepare_data="d_columnRule.prepare_data" :query_list="d_columnRule.query_list" :compile_str_array="d_columnRule.compile_str_array" :compile_str_date="d_columnRule.compile_str_date" :wdmr_change="d_columnRule.wdmr_change" :p_wdmList="d_columnRule.wdm_list" :mother_children="mother_children" :p_wdmParameterSelectData="d_columnRule.wps_data" :p_wdmRuleAsettings="d_columnRule.wra_settings" :compile_str_text="d_columnRule.compile_str_text" :p_isPolestarColumn="true"></wdm-rule-a>
        <b-row>
          <b-col sm="12" lg="6"></b-col>
          <b-col sm="12" lg="3">
            <b-button variant="outline-primary" size="sm" @click="d_showColumnRuleModal = false">
              <i class="fa fa-send-o"></i> {{ StoreLangTranslation.data['cancel'][StoreLang] }}
            </b-button>
          </b-col>
          <b-col sm="12" lg="3">
            <b-button variant="outline-primary" size="sm" @click="f_acceptColumnRule()">
              <i class="fa fa-send-o"></i> {{ StoreLangTranslation.data['save'][StoreLang] }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <b-modal v-if="d_columnOptionRuleModal" id="d_columnOptionRuleModal" v-model="d_columnOptionRuleModal" scrollable hide-footer title="Ana Kural" centered header-bg-variant="info" header-text-variant="dark" size="xl">
      <div style="min-height: 500px;">
        <wdm-rule-a :data_type="d_columnOptionRule.data_type" :option_data="d_columnOptionRule.option_data" :prepare_data="d_columnOptionRule.prepare_data" :query_list="d_columnOptionRule.query_list" :compile_str_date="d_columnOptionRule.compile_str_date" :compile_str_array="d_columnOptionRule.compile_str_array" :wdmr_change="d_columnOptionRule.wdmr_change" :p_wdmList="d_columnOptionRule.wdm_list" :mother_children="mother_children" :p_wdmParameterSelectData="d_columnOptionRule.wps_data" :p_wdmRuleAsettings="d_columnOptionRule.wra_settings" :compile_str_text="d_columnOptionRule.compile_str_text"></wdm-rule-a>

        <b-row>
          <b-col sm="12" lg="6"></b-col>
          <b-col sm="12" lg="3">
            <b-button variant="outline-primary" size="sm" @click="d_columnOptionRuleModal = false">
              <i class="fa fa-send-o"></i> {{ StoreLangTranslation.data['cancel'][StoreLang] }}
            </b-button>
          </b-col>
          <b-col sm="12" lg="3">
            <b-button variant="outline-primary" size="sm" @click="f_addEditColumnOptionRule()">
              <i class="fa fa-send-o"></i> {{ StoreLangTranslation.data['save'][StoreLang] }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <b-modal v-if="d_dataCreationRuleModal" id="d_dataCreationRuleModal" v-model="d_dataCreationRuleModal" scrollable hide-footer title="Ana Kural" centered header-bg-variant="info" header-text-variant="dark" size="xl">
      <div style="min-height: 500px;">
        <wdm-rule-a :data_type="d_dataCreationRule.data_type" :option_data="d_dataCreationRule.option_data" :prepare_data="d_dataCreationRule.prepare_data" :query_list="d_dataCreationRule.query_list" :compile_str_date="d_dataCreationRule.compile_str_date" :compile_str_array="d_dataCreationRule.compile_str_array" :wdmr_change="d_dataCreationRule.wdmr_change" :p_wdmList="d_dataCreationRule.wdm_list" :mother_children="mother_children" :p_wdmParameterSelectData="d_dataCreationRule.wps_data" :p_wdmRuleAsettings="d_dataCreationRule.wra_settings" :compile_str_text="d_dataCreationRule.compile_str_text"></wdm-rule-a>

        <b-row>
          <b-col sm="12" lg="6"></b-col>
          <b-col sm="12" lg="3">
            <b-button variant="outline-primary" size="sm" @click="d_dataCreationRuleModal = false">
              <i class="fa fa-send-o"></i> {{ StoreLangTranslation.data['cancel'][StoreLang] }}
            </b-button>
          </b-col>
          <b-col sm="12" lg="3">
            <b-button variant="outline-primary" size="sm" @click="f_addEditDataCreationRule()">
              <i class="fa fa-send-o"></i> {{ StoreLangTranslation.data['save'][StoreLang] }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <b-modal v-if="d_newOptionData.show" :title="'Yeni seçenek'" centered class="modal-success" v-model="d_newOptionData.show" @ok="d_newOptionData.show = false" ok-variant="success" hide-footer>
      <b-row>
        <b-col cols="12">
          <b-card style="margin-bottom: 5px;" no-body>
            <b-row>
              <b-col cols="12" style="text-align: left;">
                <b-form-input v-model="d_newOption"> </b-form-input>
              </b-col>
            </b-row>
          </b-card>
          <b-card style="margin-bottom: 5px;" no-body>
            <b-row>
              <b-col cols="12" style="text-align: left;">
                <b-button style="width: 100%;" variant="outline-success" @click="f_addNewOptionCopy()">
                  <i class="fa fa-plus"></i>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>


    <b-modal v-if="d_dataCreationRuleFunction.show" :title="'Yeni seçenek'" centered class="modal-success" v-model="d_dataCreationRuleFunction.show" @ok="d_dataCreationRuleFunction.show = false" ok-variant="success" hide-footer size="lg">

      <b-card no-body class="mb-1" header-tag="header" key="data_creation_rule_info" >
        <b-card-header header-bg-variant="white" class="p-1" header-text-variant="dark">
          <b-row style="margin: 0px;">
            <b-col cols="12">
              {{ StoreLangTranslation.data['function'][StoreLang] }}
            </b-col>
          </b-row>
        </b-card-header>

          <b-row>
            <b-col cols="12">
              <b-form-input type="text" v-model="d_functionSearchText" title="fonksiyon filtrelemek için yazınız..."></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <template v-for="(func, func_index) in d_functionList">
                <b-row v-if="!d_functionSearchText || func.translation[StoreLang].indexOf(d_functionSearchText) !== -1" :title="func.info" v-on:dblclick="f_addToRuleValue(d_selectedColumnInd, d_dataCreationRuleFunction.rule_ind, func.function)" style="cursor: pointer; border-bottom: solid 1px lightblue; margin: 5px;">
                  <b-col cols="1">
                    {{ func_index + 1 }} )
                  </b-col>
                  <b-col cols="7"> {{ func.translation[StoreLang] }} </b-col>
                  <b-col cols="4" style="text-align: right;">
                    <b-button variant="outline-success" @click="f_addToRuleValue(d_selectedColumnInd, d_dataCreationRuleFunction.rule_ind, func.example)" style="margin: 3px; width: 50px;">
                      <i class="fa fa-plus"></i>
                    </b-button>
                    <!-- <b-dropdown class="pull-right" variant="secondary" size="sm">
                      <template v-slot:button-content>
                        <img src="@/icon/1115721.png" title="işlemler" style="width: 2em;" />
                      </template>
                     <b-dropdown-item @click="f_addToRuleValue(d_selectedColumnInd, this.d_dataCreationRuleFunction.rule_ind, func.function)">
                        <img src="@/icon/2087726.png" style="width: 2em;"> fonksiyonu ekle
                      </b-dropdown-item>
                      <b-dropdown-item @click="f_addToRuleValue(d_selectedColumnInd, this.d_dataCreationRuleFunction.rule_ind, func.example)">
                        <i class="fa fa-plus"></i>
                      </b-dropdown-item>
                    </b-dropdown> -->
                  </b-col>
                </b-row>
              </template>
            </b-col>
          </b-row>
          <!-- <b-row>
            <b-col cols="12">
              <template v-for="(math_value, math_value_ind) in [1,2,3,4,5,6,7,8,9,0,'+', '*', '-', '/', '(', ')', '**']">
                <b-button variant="primary" @click="f_addToRuleValue(d_selectedColumnInd, rule_ind, math_value)" style="margin: 3px; width: 50px;">{{ math_value }}</b-button>
              </template>
            </b-col>
          </b-row> -->

      </b-card>
      
    </b-modal>


  </div>
</template>

<script>
import { ClsWdm } from '@/wam/modules/wdm';
import { ClsRule } from '@/wam/modules/rule';
import { ServiceFunctions } from '@/wam/modules/service';
import { ClsWanalyzer } from '@/wam/modules/wanalyzer';
import { mapGetters } from 'vuex';
import {
  default as WdmRuleA
} from '@/wam/components/rule/WdmRuleA';
import {
  default as RuleQuery
} from '@/wam/components/rule/RuleQuery';
export default {
  name: 'WanalyzerProjectColumn',
  components: {
    WdmRuleA,
    RuleQuery,
  },
  computed: mapGetters({
    StoreLang: 'StoreLang',
    StoreLangList: 'StoreLangList',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreFrontendSettings: 'StoreFrontendSettings',
    StoreDevice: 'StoreDevice',
    StoreCurrencyType: 'StoreCurrencyType',
  }),
  props: {
    f_wanalyzerProjectColumnDataList: {
      type: Function,
      required: true
    },
    p_columnList: {
      type: Array,
      required: true
    },
    p_projectPermission: {
      type: Object,
      required: false
    },
    p_fullScreen: {
      type: Boolean,
      required: false
    }
  },
  created: function () {
    this.d_layerKey = this.p_projectPermission.main_layer;
    this.d_user = JSON.parse(localStorage.getItem('user'));
  },
  mounted: function () {
    //
  },
  data: () => {
    return {
      d_functionList: require('@/wam/options/wanalyzer_function_list').data,
      d_functionSearchText: '',
      d_dataCreationRuleFunction: { 'show': false, 'rule_ind': '' },
      d_newOptionData: { 'show': '', 'op_ind': '' },
      d_newOption: '',
      mother_children: {
        'change_status': 0,
        'forceUpdate': 0
      },
      d_columnRule: {},
      d_columnOptionRule: {},
      d_dataCreationRule: {},
      d_showColumnRuleModal: false,
      d_columnOptionRuleModal: false,
      d_dataCreationRuleModal: false,
      d_prepareRuleNew: {
        'date_ability': '',
        'wdmr_change': { 'rule': 0 },
        'data_type': '',
        'option_data': {},
        'prepare_data': {},
        'query_list': [],
        'compile_str_date': [],
        'compile_str_text': [],
        'compile_str_array': [],
        'wra_settings': {
          'mode': 'param',
          'options': [
            { 'label': 'PARAM', 'value': 'param' },
            { 'label': 'PARAM & QUERY', 'value': 'param_query' }
          ]
        },
        'wps_data': [{
          'operation': '',
          'selected_value': '',
          'loc': [],
          'val_list': [],
          'selected_wdm': '',
          'd_level': 0,
          'd_level_count': 0,
          'significant': 'true'
        }],
        'wdm_list': []
      },
      d_layerKey: '',
      d_columnSaveLoading: false,
      d_showWdmParamColumnRule: true,
      d_manuelParamTypeList: ['select', 'integer', 'float', 'text', 'date', 'time', 'datetime'],
      d_wdmParamTypeList: ['select', 'integer', 'float', 'text', 'date', 'time', 'datetime', 'textarea', 'object_list_options', 'checkbox', 'radiogroup', 'plain_text'],
      d_navColumnDetails: 'column_general_info',
      d_selectedColumnInd: '',
      column_rule_operation_type: '',
    }
  },
  methods: {
    f_dataCreationRuleFunctionModal: function (rule_ind) {
      this.d_dataCreationRuleFunction.rule_ind = rule_ind;
      this.d_dataCreationRuleFunction.show = true;
    },
    f_copyColumnCreationRule: function (rule_ind) {
      let x = JSON.parse(JSON.stringify(this.p_columnList[this.d_selectedColumnInd].data_creation_rule[rule_ind]));
      this.p_columnList[this.d_selectedColumnInd].data_creation_rule.push(x);
      this.$forceUpdate();
    },
    f_deleteDataCreationRule: function (rule_ind) {
      this.p_columnList[this.d_selectedColumnInd].data_creation_rule.splice(rule_ind, 1);
      if (this.p_columnList[this.d_selectedColumnInd].data_creation_rule.length === 0) {
        delete this.p_columnList[this.d_selectedColumnInd].data_creation_rule;
      }
      this.$forceUpdate();
    },
    f_addToRuleValue: function (column_index, rule_index, val) {
      this.p_columnList[column_index].data_creation_rule[rule_index].value += val;
      this.d_dataCreationRuleFunction.show = false;
    },
    f_calculateStyleRule: function () {
      let style = '';
      if (this.p_fullScreen) {
        let height = window.innerHeight - 300;
        style = 'overflow-y: auto; height: ' + height.toString() + 'px; overflow-x: hidden; margin-bottom: 0px;'
      } else {
        style = 'margin-bottom: 0px;'
      }
      return style;
    },
    f_addNewDataCreationRule: function () {
      if (!this.p_columnList[this.d_selectedColumnInd].data_creation_rule) {
        this.p_columnList[this.d_selectedColumnInd].data_creation_rule = [];
      }
      this.p_columnList[this.d_selectedColumnInd].data_creation_rule.push({ 'value': '', 'query_list': [], 'compile_str_array': [], 'compile_str_date': [], 'compile_str_text': [] });
      this.$forceUpdate();
    },
    f_addNewOptionCopy: function () {
      if (this.d_newOption) {
        let det = false;
        if (!this.p_columnList[this.d_selectedColumnInd].options) {
          this.p_columnList[this.d_selectedColumnInd].options = [];
        } else {
          for (let i in this.p_columnList[this.d_selectedColumnInd].options) {
            if (this.p_columnList[this.d_selectedColumnInd].options[i].label === this.d_newOption) {
              det = true;
              break;
            }
          }
        }
        if (!det) {
          let new_opt = JSON.parse(JSON.stringify(this.p_columnList[this.d_selectedColumnInd].options[this.d_newOptionData.op_ind]));
          new_opt.label = this.d_newOption;
          for (let k = 0; k < 10000; k++) {
            let new_val = 'new_' + k;
            let val_det = false;
            for (let i in this.p_columnList[this.d_selectedColumnInd].options) {
              if (this.p_columnList[this.d_selectedColumnInd].options[i].value === new_val) {
                val_det = true;
                break;
              }
            }
            if (!val_det) {
              new_opt.value = new_val;
              break;
            }
          }
          this.p_columnList[this.d_selectedColumnInd].options.push(new_opt);
        } else {
          alert(this.StoreLangTranslation.data['already_added'][this.StoreLang]);
        }
      }
      this.$forceUpdate();
    },
    f_copyThisOption: function (op_ind) {
      this.d_newOptionData.op_ind = op_ind;
      this.d_newOptionData.show = true;
    },
    f_deleteOption: function (col_ind, op_ind) {
      this.p_columnList[col_ind].options.splice(op_ind, 1);
      this.$forceUpdate();
    },
    f_columnOptionRuleShow: function (option_ind) {
      this.d_columnOptionRule = JSON.parse(JSON.stringify(this.d_prepareRuleNew));
      this.d_columnOptionRule.option_ind = option_ind;
      let option_rule = false;
      try {
        option_rule = this.p_columnList[this.d_selectedColumnInd].options[option_ind].rule;
      } catch (err) {}
      if (option_rule && option_rule.query_list.length > 0) {
        this.d_columnOptionRule.query_list = option_rule.query_list;
        this.d_columnOptionRule.compile_str_array = option_rule.compile_str_array;
        if (option_rule.compile_str_date) {
          this.d_columnOptionRule.compile_str_date = option_rule.compile_str_date;
        }
        if (option_rule.compile_str_text) {
          this.d_columnOptionRule.compile_str_text = option_rule.compile_str_text;
        }
      }
      let options_list_id_use = 'no';
      ClsWanalyzer.f_prepareColumnRuleOptionData(this.d_columnOptionRule, this.p_columnList, options_list_id_use);
      this.d_columnOptionRuleModal = true;
    },
    f_dataCreationRuleShow: function (rule_ind) {
      this.d_dataCreationRule = JSON.parse(JSON.stringify(this.d_prepareRuleNew));
      this.d_dataCreationRule.rule_ind = rule_ind;
      if (this.p_columnList[this.d_selectedColumnInd].data_creation_rule[this.d_dataCreationRule.rule_ind].query_list.length > 0) {
        this.d_dataCreationRule.query_list = this.p_columnList[this.d_selectedColumnInd].data_creation_rule[this.d_dataCreationRule.rule_ind].query_list;
        this.d_dataCreationRule.compile_str_array = this.p_columnList[this.d_selectedColumnInd].data_creation_rule[this.d_dataCreationRule.rule_ind].compile_str_array;
        if (this.p_columnList[this.d_selectedColumnInd].data_creation_rule[this.d_dataCreationRule.rule_ind].compile_str_date) {
          this.d_dataCreationRule.compile_str_date = this.p_columnList[this.d_selectedColumnInd].data_creation_rule[this.d_dataCreationRule.rule_ind].compile_str_date;
        }
        if (this.p_columnList[this.d_selectedColumnInd].data_creation_rule[this.d_dataCreationRule.rule_ind].compile_str_text) {
          this.d_dataCreationRule.compile_str_text = this.p_columnList[this.d_selectedColumnInd].data_creation_rule[this.d_dataCreationRule.rule_ind].compile_str_text;
        }
      }
      let options_list_id_use = 'no';
      ClsWanalyzer.f_prepareColumnRuleOptionData(this.d_dataCreationRule, this.p_columnList, options_list_id_use);
      this.d_dataCreationRuleModal = true;
    },
    f_addEditDataCreationRule: function () {
      let parenthesis_control = ClsRule.f_controlParanthesisDesign(this.d_dataCreationRule.compile_str_array);
      // console.log('parenthesis_control : ', parenthesis_control);
      if (parenthesis_control) {
        // When we write our code with mode param, we have to convert it to query_list format to make a filter.
        if (this.d_dataCreationRule.wra_settings.mode === 'param') {
          ClsRule.f_paramToQuery(this.d_dataCreationRule.query_list, this.d_dataCreationRule.compile_str_array, this.d_dataCreationRule.wps_data);
        }
        // console.log(JSON.stringify(this.d_dataCreationRule.query_list));
        this.p_columnList[this.d_selectedColumnInd].data_creation_rule[this.d_dataCreationRule.rule_ind].query_list = JSON.parse(JSON.stringify(this.d_dataCreationRule.query_list));
        this.p_columnList[this.d_selectedColumnInd].data_creation_rule[this.d_dataCreationRule.rule_ind].compile_str_array = JSON.parse(JSON.stringify(this.d_dataCreationRule.compile_str_array));
        this.p_columnList[this.d_selectedColumnInd].data_creation_rule[this.d_dataCreationRule.rule_ind].compile_str_date = JSON.parse(JSON.stringify(this.d_dataCreationRule.compile_str_date));
        if (this.p_columnList[this.d_selectedColumnInd].data_creation_rule[this.d_dataCreationRule.rule_ind].compile_str_text) {
          this.p_columnList[this.d_selectedColumnInd].data_creation_rule[this.d_dataCreationRule.rule_ind].compile_str_text = JSON.parse(JSON.stringify(this.d_dataCreationRule.compile_str_text));
        }
        this.d_dataCreationRuleModal = false;
        this.$forceUpdate();
      } else {
        alert(this.StoreLangTranslation.data['please_check_parenthesis'][this.StoreLang]);
      }
    },
    f_addEditColumnOptionRule: function () {
      let parenthesis_control = ClsRule.f_controlParanthesisDesign(this.d_columnOptionRule.compile_str_array);
      // console.log('parenthesis_control : ', parenthesis_control);
      if (parenthesis_control) {
        // When we write our code with mode param, we have to convert it to query_list format to make a filter.
        if (this.d_columnOptionRule.wra_settings.mode === 'param') {
          ClsRule.f_paramToQuery(this.d_columnOptionRule.query_list, this.d_columnOptionRule.compile_str_array, this.d_columnOptionRule.wps_data);
        }
        // console.log(JSON.stringify(this.d_columnOptionRule.query_list));
        this.p_columnList[this.d_selectedColumnInd].options[this.d_columnOptionRule.option_ind].rule = {};
        this.p_columnList[this.d_selectedColumnInd].options[this.d_columnOptionRule.option_ind].rule.query_list = JSON.parse(JSON.stringify(this.d_columnOptionRule.query_list));
        this.p_columnList[this.d_selectedColumnInd].options[this.d_columnOptionRule.option_ind].rule.compile_str_array = JSON.parse(JSON.stringify(this.d_columnOptionRule.compile_str_array));
        this.p_columnList[this.d_selectedColumnInd].options[this.d_columnOptionRule.option_ind].rule.compile_str_date = JSON.parse(JSON.stringify(this.d_columnOptionRule.compile_str_date));
        if (this.p_columnList[this.d_selectedColumnInd].options[this.d_columnOptionRule.option_ind].rule.compile_str_text) {
          this.p_columnList[this.d_selectedColumnInd].options[this.d_columnOptionRule.option_ind].rule.compile_str_text = JSON.parse(JSON.stringify(this.d_columnOptionRule.compile_str_text));
        }
        this.d_columnOptionRuleModal = false;
        this.$forceUpdate();
      } else {
        alert(this.StoreLangTranslation.data['please_check_parenthesis'][this.StoreLang]);
      }
    },
    f_addNewOption: function () {
      if (this.d_newOption) {
        let det = false;
        if (!this.p_columnList[this.d_selectedColumnInd].options) {
          this.p_columnList[this.d_selectedColumnInd].options = [];
        } else {
          for (let i in this.p_columnList[this.d_selectedColumnInd].options) {
            if (this.p_columnList[this.d_selectedColumnInd].options[i].label === this.d_newOption) {
              det = true;
              break;
            }
          }
        }
        if (!det) {
          let new_opt = { 'label': this.d_newOption, 'value': '' };
          for (let k = 0; k < 10000; k++) {
            let new_val = 'new_' + k;
            let val_det = false;
            for (let i in this.p_columnList[this.d_selectedColumnInd].options) {
              if (this.p_columnList[this.d_selectedColumnInd].options[i].value === new_val) {
                val_det = true;
                break;
              }
            }
            if (!val_det) {
              new_opt.value = new_val;
              break;
            }
          }
          this.p_columnList[this.d_selectedColumnInd].options.push(new_opt);
        } else {
          alert(this.StoreLangTranslation.data['already_added'][this.StoreLang]);
        }
      }
      this.$forceUpdate();
    },
    f_acceptColumnRule: function () {
      let parenthesis_control = ClsRule.f_controlParanthesisDesign(this.d_columnRule.compile_str_array);
      // console.log('parenthesis_control : ', parenthesis_control);
      if (parenthesis_control) {
        if (this.d_columnRule.wra_settings.mode === 'param') {
          ClsRule.f_paramToQuery(this.d_columnRule.query_list, this.d_columnRule.compile_str_array, this.d_columnRule.wps_data);
        }
        let col_rule_data = {
          'compile_str_array': JSON.parse(JSON.stringify(this.d_columnRule.compile_str_array)),
          'compile_str_text': JSON.parse(JSON.stringify(this.d_columnRule.compile_str_text)),
          'compile_str_date': JSON.parse(JSON.stringify(this.d_columnRule.compile_str_date)),
          'query_list': JSON.parse(JSON.stringify(this.d_columnRule.query_list))
        };
        let det = false;
        let column_field_data = '';
        let wdm_name = '';
        let wdm_type = '';
        let field_operation = '=';
        let column_val_analyze_type = '';
        let parameter_val_list = [];
        let parameter_values = [];
        for (let i in this.d_columnRule.query_list) {
          for (let f in this.d_columnRule.query_list[i].fields) {
            if (this.d_columnRule.query_list[i].fields[f].is_column) {
              det = true;
              if (this.d_columnRule.query_list[i].fields[f].val_list.length > 0) {
                field_operation = this.d_columnRule.query_list[i].fields[f].operation;
                parameter_val_list = this.d_columnRule.query_list[i].fields[f].val_list;
                parameter_values = this.d_columnRule.query_list[i].fields[f].values;
                column_val_analyze_type = this.d_columnRule.query_list[i].fields[f].column_val_analyze_type;
              }
              column_field_data = this.d_columnRule.query_list[i].fields[f];
              wdm_name = this.d_columnRule.query_list[i].type;
              wdm_type = this.d_columnRule.query_list[i].type.value;
              break;
            }
          }
          if (det) {
            break;
          }
        }
        // console.log('column_field_data : ', column_field_data);
        let options = [];
        let param_type = '';
        if (column_val_analyze_type === 'yesno') {
          param_type = 'select';
          options = [{ 'label': 'evet', 'value': 'yes' }, { 'label': 'hayir', 'value': 'no' }];
        } else if (column_val_analyze_type === 'count') {
          param_type = 'integer';
        } else if (column_val_analyze_type === 'sum') {
          param_type = 'float';
        } else {
          param_type = column_field_data.value_type;
        }
        let col_index_list = [];
        for (let lo_index in column_field_data.loc) {
          col_index_list.push(column_field_data.loc[lo_index]['value']);
        }
        // console.log('col_index_list ', col_index_list);
        let wdm_data = this.d_columnRule.option_data[wdm_type];
        let param_data = ClsWdm.getParamDataWithIndexList(wdm_data, col_index_list);
        /*
          // options may be very much for wdm columns, then it is cancelled. While selecting an option in wdmrulea, system gets it from db.
          if (param_data) {
            if (param_data.options && param_data.options.length > 0) {
              options = [];
              for (let op_index in param_data.options) {
                options.push({ 'label': param_data.options[op_index]['label'], 'value': param_data.options[op_index]['value'] });
              }
            }
          }
        */
        let column_label = this.f_createLabelFromLoc(column_field_data.loc);
        // console.log('column_label : ', column_label);

        let layer_key = this.d_layerKey;

        if (this.column_rule_operation_type === 'edit') {
          this.p_columnList[this.d_selectedColumnInd].parameter_val_list = parameter_val_list;
          this.p_columnList[this.d_selectedColumnInd].parameter_values = parameter_values;
          this.p_columnList[this.d_selectedColumnInd].column_val_analyze_type = column_val_analyze_type;
          this.p_columnList[this.d_selectedColumnInd].field_operation = field_operation;
          this.p_columnList[this.d_selectedColumnInd].loc = column_field_data.loc;
          this.p_columnList[this.d_selectedColumnInd].wdm_type = wdm_type;
          this.p_columnList[this.d_selectedColumnInd].wdm_name = wdm_name;
          this.p_columnList[this.d_selectedColumnInd].param_type = param_type;
          this.p_columnList[this.d_selectedColumnInd].date_ability = this.d_columnRule.option_data[wdm_type].date_ability ? this.d_columnRule.option_data[wdm_type].date_ability : '';
          this.p_columnList[this.d_selectedColumnInd].date_ability_limit = this.d_columnRule.option_data[wdm_type].date_ability_limit ? this.d_columnRule.option_data[wdm_type].date_ability_limit : '';
          this.p_columnList[this.d_selectedColumnInd].date_ability_type = this.d_columnRule.option_data[wdm_type].date_ability_type ? this.d_columnRule.option_data[wdm_type].date_ability_type : '';
          this.p_columnList[this.d_selectedColumnInd].record_count = this.d_columnRule.option_data[wdm_type].record_count ? this.d_columnRule.option_data[wdm_type].record_count : '';
          this.p_columnList[this.d_selectedColumnInd].layer_rules[layer_key] = col_rule_data;
          if (column_field_data.anatomy_use) {
            this.p_columnList[this.d_selectedColumnInd].anatomy_use = column_field_data.anatomy_use;
          }
          if (options.length > 0) {
            this.p_columnList[this.d_selectedColumnInd].options = options;
          }
          if (param_type !== column_field_data.value_type) {
            this.p_columnList[this.d_selectedColumnInd].param_type_real = column_field_data.value_type;
          }
        } else {
          let new_col_data = {
            'parent_column_relation_type': 'no',
            'new_column': 1,
            'anonym': 0,
            'string_usage': '1',
            'label': column_label,
            'parameter_val_list': parameter_val_list,
            'parameter_values': parameter_values,
            'column_val_analyze_type': column_val_analyze_type,
            'loc': column_field_data.loc,
            'operation': field_operation,
            'type': 'wdm',
            'layer_rules': {},
            'wdm_type': wdm_type,
            'wdm_name': wdm_name,
            'param_type': param_type,
            'date_ability': this.d_columnRule.option_data[wdm_type].date_ability ? this.d_columnRule.option_data[wdm_type].date_ability : '',
            'date_ability_limit': this.d_columnRule.option_data[wdm_type].date_ability_limit ? this.d_columnRule.option_data[wdm_type].date_ability_limit : '',
            'date_ability_type': this.d_columnRule.option_data[wdm_type].date_ability_type ? this.d_columnRule.option_data[wdm_type].date_ability_type : '',
            'record_count': this.d_columnRule.option_data[wdm_type].record_count ? this.d_columnRule.option_data[wdm_type].record_count : ''
          };

          if (['select', 'checkbox', 'object_list_options', 'radiogroup'].indexOf(param_type) !== -1) {
            new_col_data.value_label = 'label';
          }
          if (new_col_data.record_count === 'many_times') {
            new_col_data.numeric_string = 'string';
            if (wdm_type && layer_key && wdm_type === layer_key) {
              new_col_data.string_usage = '0';
            } else {
              new_col_data.string_usage = '2';
            }
            new_col_data.multi_wdmr_approach = '1';
            new_col_data.list_creation_approach = '0';
            // if (['integer', 'float'].indexOf(param_type) !== -1) {
            //   new_col_data.numeric_string = 'numeric';
            //   new_col_data.numeric_usage = 'mean';
            // } else {
            //   new_col_data.numeric_string = 'string';
            //   new_col_data.string_usage = '2';
            //   new_col_data.multi_wdmr_approach = '1';
            //   new_col_data.list_creation_approach = '1';
            // }
          }
          if (options.length > 0) {
            new_col_data.options = options;
          }
          if (param_type !== column_field_data.value_type) {
            new_col_data.param_type_real = column_field_data.value_type;
          }
          if (column_field_data.anatomy_use) {
            new_col_data.anatomy_use = column_field_data.anatomy_use;
          }
          this.p_columnList.push(new_col_data);
          this.d_selectedColumnInd = this.p_columnList.length - 1;
          this.p_columnList[this.d_selectedColumnInd].layer_rules[layer_key] = col_rule_data;
        }
        this.d_showColumnRuleModal = false;
      } else {
        alert(this.StoreLangTranslation.data['please_check_parenthesis'][this.StoreLang]);
      }
    },
    f_showTrueFalseColumnRule: function () {
      this.d_showWdmParamColumnRule = false;
      setTimeout(() => {
        this.d_showWdmParamColumnRule = true;
      }, 50)
    },
    f_createLabelFromLoc: function (loc) {
      let x = '';
      for (let i in loc) {
        if (parseInt(i) > 0 || (parseInt(i) === 0 && loc[i]['type'] !== 'group')) {
          x += loc[i]['label'];
          if (parseInt(i) !== loc.length - 1) {
            x += ' ';
          }
        }
      }
      return x;
    },
    f_showLayerColumnRuleModal: function (column_type, col_ind='') {
      this.d_columnRule = JSON.parse(JSON.stringify(this.d_prepareRuleNew));
      for (let i in this.p_projectPermission.layer_list) {
        let wdm_name = this.p_projectPermission.layer_list[i]['layer']['name'][this.StoreLang];
        let wdm_key = this.p_projectPermission.layer_list[i]['layer']['value'];
        let new_wdm_item = {'type': wdm_key, 'value': wdm_key, 'label': wdm_name};
        this.d_columnRule.wdm_list.push(new_wdm_item);
      }
      if (column_type === 'edit') {
        this.column_rule_operation_type = 'edit';
        this.d_columnRule.compile_str_array = JSON.parse(JSON.stringify(this.p_columnList[col_ind].layer_rules[this.d_layerKey].compile_str_array));
        this.d_columnRule.compile_str_date = JSON.parse(JSON.stringify(this.p_columnList[col_ind].layer_rules[this.d_layerKey].compile_str_date));
        this.d_columnRule.compile_str_text = JSON.parse(JSON.stringify(this.p_columnList[col_ind].layer_rules[this.d_layerKey].compile_str_text));
        this.d_columnRule.query_list = JSON.parse(JSON.stringify(this.p_columnList[col_ind].layer_rules[this.d_layerKey].query_list));
      } else if (column_type === 'new') {
        this.column_rule_operation_type = 'new';
      }
      this.d_showColumnRuleModal = true;
    },
    f_addNewManuelColumn: function () {
      let new_column = {
        "data_creation_type": 'manuel',
        "new_column": 1,
        "label": "New Column",
        "param_type": "select",
        "type": "manuel" // ['by_rule_from_other_columns', 'manuel'] If we create a new column from the other columns' by rules, this will be, 'by_rule_from_other_columns'
      };
      this.p_columnList.push(new_column);
      this.d_selectedColumnInd = this.p_columnList.length - 1;
    },
    f_wanalyzerColumnSave: function () {
      this.d_columnSaveLoading = true;
      let query = 'project_id=' + this.p_projectPermission.wanalyzer_project_id;
      let data = {
        'column_list': this.p_columnList,
      };
      ServiceFunctions.wanalyzer_project_column_save(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            this.d_columnSaveLoading = false;
            this.f_wanalyzerProjectColumnDataList();
          } else {
            let txt = 'status_code = ' + resp.data.status_code + '\n';
            txt += 'status_message = ' + resp.data.status_message;
            alert(txt);
          }
        });
    },
    f_controlColumnFeatures: function (column_list, col_index, type = 'this') {
      ClsWanalyzer.f_controlColumnFeatures(column_list, col_index, type)
    },
    f_selectColumn: function (col_ind) {
      this.d_selectedColumnInd = col_ind;
      if (this.d_navColumnDetails === 'data_creation_type_rule') {
        if (this.p_columnList[col_ind].type !== 'manuel') {
          this.d_navColumnDetails = 'column_general_info';
        }
      } else if (this.d_navColumnDetails === 'wdm_param_column_rule') {
        if (this.p_columnList[col_ind].type === 'wdm') {
          this.f_showTrueFalseColumnRule();
        } else {
          this.d_navColumnDetails = 'column_general_info';
        }
      }
    },
    f_deleteColumn: function (col_ind) {
      if (this.p_columnList[col_ind].deleted) {
        this.$delete(this.p_columnList[col_ind], 'deleted');
      } else {
        if (this.p_columnList[col_ind].id) {
          this.p_columnList[col_ind].deleted = 1;
        } else {
          this.d_lastSelectedColumnIndex = parseInt(this.d_selectedColumnInd);
          this.d_selectedColumnInd = '';
          this.p_columnList.splice(col_ind, 1);
          // console.log('this.d_lastSelectedColumnIndex : ', this.d_lastSelectedColumnIndex);
          // console.log('this.p_columnList.length       : ', this.p_columnList.length);
          if (this.d_lastSelectedColumnIndex < this.p_columnList.length) {
            this.f_selectColumn(this.d_lastSelectedColumnIndex)
          } else if (this.p_columnList.length > 0) {
            this.f_selectColumn(this.p_columnList.length - 1);
          }
        }
      }
      this.$forceUpdate();
    },
  }
}
</script>
